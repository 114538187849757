import { useEffect, useState } from 'react'
import parse from 'html-react-parser'

import { toCamelCase } from '@cutover/api'
import { Box, Button, Modal, useNotify } from '@cutover/react-ui'
import { useLanguage, useWebsockets } from 'main/services/hooks'
import { IntegrationEvent, RunbookIntegration } from 'main/services/queries/types'
import {
  useRunbookIntegrationsCancelEndpoint,
  useRunbookIntegrationsFireEndpoint
} from 'main/services/queries/use-runbook-integrations'

type IntegrationActionButtonProps = {
  runbookId: number
  runbookIntegration: RunbookIntegration
  onUpdateIntegrationEvents: (event: IntegrationEvent) => void
}

const INTEGRATION_STATUS = {
  connect: 'connect',
  queued: 'queued',
  running: 'running',
  failed: 'failed',
  success: 'success',
  cancelled: 'cancelled',
  rehearsal: 'rehearsal'
}

export const IntegrationActionButton = ({
  runbookId,
  runbookIntegration,
  onUpdateIntegrationEvents
}: IntegrationActionButtonProps) => {
  const { t } = useLanguage()
  const notify = useNotify()
  const websockets = useWebsockets()
  const [inProgress, setInProgress] = useState<boolean>(false)
  const [disabled, setDisabled] = useState<boolean>(false)
  const [isAbortModalOpen, setIsAbortModalOpen] = useState<boolean>(false)
  const [latestIntegrationEvent, setLatestIntegrationEvent] = useState<IntegrationEvent | undefined>(undefined)
  const [labelAndAction, setLabelAndAction] = useState<[string, () => Promise<void>] | []>([])
  const [integrationStatus, setIntegrationStatus] = useState<string | undefined>(undefined)
  const integrationFireEndpointMutation = useRunbookIntegrationsFireEndpoint()
  const integrationCancelEndpointMutation = useRunbookIntegrationsCancelEndpoint()

  const triggerIntegration = async () => {
    setDisabled(true)
    setInProgress(true)
    const action = !integrationStatus || integrationStatus === INTEGRATION_STATUS.connect ? 'fire' : 'refire'
    notify.success(
      parse(
        t('runbook:integrationsPanel:runTriggered', {
          integrationName: runbookIntegration.integrationActionItem.name
        })
      ) as string
    )
    integrationFireEndpointMutation.mutate(
      { id: runbookIntegration.id as number, action: action },
      {
        onSuccess: () => {
          runbookIntegration.inProgress = true
        },
        onError: () => {
          setDisabled(false)
          setInProgress(false)
          notify.error(t('runbook:integrationsPanel:error'))
        }
      }
    )
  }

  const abortIntegration = async () => {
    onAbortModalClose()
    setDisabled(true)
    setInProgress(true)
    notify.success(
      parse(
        t('runbook:integrationsPanel:cancelTriggered', {
          integrationName: runbookIntegration.integrationActionItem.name
        })
      ) as string
    )
    integrationCancelEndpointMutation.mutate(runbookIntegration.id, {
      onSuccess: () => {
        runbookIntegration.inProgress = false
        setDisabled(false)
        setInProgress(false)
        setIntegrationStatus('cancelled')
      },
      onError: () => {
        setDisabled(false)
        setInProgress(false)
        notify.error(t('runbook:integrationsPanel:error'))
      }
    })
  }

  const onAbortModalClose = () => {
    setIsAbortModalOpen(false)
  }

  const onAbortModalOpen = async () => {
    setIsAbortModalOpen(true)
  }

  const loadIntegrationEvent = (event: IntegrationEvent) => {
    if (!event.status || runbookIntegration.integrationActionItemId !== event.integrationActionItemId) {
      return
    }

    setInProgress(false)
    onUpdateIntegrationEvents(event)
    setLatestIntegrationEvent(event)
    notify.info(
      parse(
        t(`integrationStatuses:notification:${event.status.toLowerCase()}`, {
          integrationName: runbookIntegration.integrationActionItem.name
        })
      ) as string,
      { title: event.status }
    )
  }

  useEffect(() => {
    setInProgress(false)
    setDisabled(false)
  }, [runbookIntegration.id])

  useEffect(() => {
    setLatestIntegrationEvent(runbookIntegration.integrationEvents.at(-1))
    websockets.subscribe('RunbookChannel', runbookId, {
      received: (data: IntegrationEvent) => loadIntegrationEvent(toCamelCase(data))
    })

    return () => {
      websockets.unsubscribe('RunbookChannel', runbookId)
    }
  }, [runbookIntegration.id])

  useEffect(() => {
    if (latestIntegrationEvent && latestIntegrationEvent.status) {
      setIntegrationStatus(latestIntegrationEvent.status.toLowerCase())
    } else if (inProgress === true) {
      setIntegrationStatus('connect')
    }

    setDisabled(false)
  }, [runbookIntegration.id, inProgress, latestIntegrationEvent])

  useEffect(() => {
    if (!latestIntegrationEvent) {
      setLabelAndAction([t('runbook:integrationsPanel:runTriggerLabel'), triggerIntegration])
    } else {
      if (
        integrationStatus === INTEGRATION_STATUS.failed ||
        integrationStatus === INTEGRATION_STATUS.success ||
        (runbookIntegration.integrationOptions.cancellable &&
          integrationStatus === INTEGRATION_STATUS.cancelled &&
          !inProgress)
      ) {
        setLabelAndAction([t('runbook:integrationsPanel:refireTriggerLabel'), triggerIntegration])
      } else if (runbookIntegration.integrationOptions.cancellable && !inProgress) {
        setLabelAndAction([t('runbook:integrationsPanel:abortTriggerLabel'), onAbortModalOpen])
      }
    }
  }, [runbookIntegration.id, integrationStatus, latestIntegrationEvent, inProgress])

  return (
    <>
      <Box direction="column" margin={{ vertical: 'small' }}>
        <Button
          label={labelAndAction[0]}
          value={runbookIntegration.id || undefined}
          secondary
          full
          gap="small"
          css={'padding: 8px'}
          onClick={() => labelAndAction[1] && labelAndAction[1]()}
          disabled={disabled || Boolean(!runbookIntegration.id)}
        />
      </Box>
      <Modal
        title={t('runbook:integrationsPanel:abortModal:title')}
        confirmText={t('runbook:integrationsPanel:abortModal:confirmText')}
        open={isAbortModalOpen}
        onClickConfirm={abortIntegration}
        onClose={onAbortModalClose}
        confirmIcon="arrow-forward"
      >
        <Box>
          {parse(
            t('runbook:integrationsPanel:abortModal:message', {
              integrationName: runbookIntegration.integrationActionItem.name
            })
          )}
        </Box>
      </Modal>
    </>
  )
}
