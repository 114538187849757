import { useEffect, useState } from 'react'
import styled from 'styled-components'

import { TaskTypeIcon } from '@cutover/react-ui'
import { SelectField } from 'main/components/shared/form'
import { TasksBulkEditFormType } from './tasks-bulk-edit-panel'
import { TaskType } from 'main/services/queries/types'
import { useLanguage } from 'main/services/hooks'
import { useTaskEditTaskTypesLoadable } from 'main/recoil/data-access'

type TaskTypeOption = {
  value: number
  label: string
  icon: JSX.Element
}

export const TaskTypeSelectField = () => {
  const { t } = useLanguage('tasks', { keyPrefix: 'editPanel' })
  const [taskTypesOptions, setTaskTypesOptions] = useState<TaskTypeOption[]>([])
  const [taskTypesIntegrationsOptions, setTaskTypesIntegrationsOptions] = useState<TaskTypeOption[]>([])

  const taskTypesLoadable = useTaskEditTaskTypesLoadable()

  useEffect(() => {
    if (taskTypesLoadable.state === 'hasValue') {
      const { taskTypes, taskTypeIntegrations } = taskTypesLoadable.contents
      if (taskTypes) {
        const options = taskTypes.map((t: TaskType) => ({
          value: t.id,
          label: t.name,
          icon: <TaskTypeIcon icon={t.icon} />,
          disabled: t.linkable
        }))
        setTaskTypesOptions(options)
      }

      if (taskTypeIntegrations) {
        const integrationOptions = taskTypeIntegrations.map((t: TaskType) => ({
          value: t.id,
          label: t.integration_action_items[0].name,
          icon: (
            <IntegrationIcon
              alt={`${t.integration_action_items[0].name}-icon`}
              src={
                t.integration_action_items[0].image_url || t.integration_action_items[0].integration_setting.image_url
              }
            />
          )
        }))
        setTaskTypesIntegrationsOptions(integrationOptions)
      }
    }
  }, [taskTypesLoadable])

  return (
    <SelectField<TasksBulkEditFormType>
      name="task_type_id"
      options={[
        { header: t('fields.taskType.taskTypeSelectHeader') },
        ...taskTypesOptions,
        ...[
          ...(taskTypesIntegrationsOptions.length
            ? [{ header: t('fields.taskType.integrationTaskTypeSelectHeader') }]
            : [])
        ],
        ...taskTypesIntegrationsOptions
      ]}
      label={t('fields.taskType.label')}
    />
  )
}

const IntegrationIcon = styled.img`
  height: 22px;
  width: 22px;
`
