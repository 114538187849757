import { useEffect, useState } from 'react'
import { debounce } from 'lodash'

import { IconName, ListItem, MultiSelect } from '@cutover/react-ui'
import { usePostAppEvents } from '../apps-api'
import { useComponentPropsAndState } from '../apps-state'
import { AppComponentNodeProps, ContentNodeOption } from '../apps-types'

const DEBOUNCE_TIME_MILIS = 200
const MIN_CHARS = 1

type MultiSelectNodeProps = AppComponentNodeProps & {
  label: string
  name: string
  options: ContentNodeOption[]
  autocomplete: boolean
  selected: string[]
  icon?: IconName
  error?: boolean
}

export const MultiSelectFieldNode = ({ appId, resourceId, id, ...props }: MultiSelectNodeProps) => {
  const { componentProps, setState } = useComponentPropsAndState(appId, resourceId, id, props)
  const {
    label,
    name,
    options,
    autocomplete,
    icon,
    meta,
    selected: initialSelected,
    error
  } = componentProps as MultiSelectNodeProps

  const postAppEvents = usePostAppEvents()

  const [inputValue, setInputValue] = useState('')
  const [loading, setLoading] = useState(false)
  const [selectedOptions, setSelectedOptions] = useState<ContentNodeOption[]>(
    options.filter(option => initialSelected.includes(option.value))
  )

  const handleValueChange = (value: ContentNodeOption[]) => {
    setSelectedOptions(value)
    const fieldValue = value.map(opt => opt.value)
    setState({ name, value: fieldValue })
  }

  const handleInputValueChange = debounce((value: string) => {
    setInputValue(value)
    if (!autocomplete || value.trim().length < MIN_CHARS) {
      return
    }
    setLoading(true)
    const payload = {
      app_id: appId,
      runbook_id: resourceId,
      events: [
        {
          type: 'trigger',
          on: 'autocomplete',
          id,
          meta,
          value
        }
      ]
    }
    postAppEvents(payload)
  }, DEBOUNCE_TIME_MILIS)

  useEffect(() => {
    setLoading(false)
  }, [options])

  return (
    <MultiSelect
      options={inputValue.trim().length === 0 ? [] : options}
      filterKeys={['value', 'label']}
      label={label}
      icon={icon}
      clearable
      optionToString={opt => opt.label}
      onChange={value => handleValueChange(value ?? [])}
      onInputChange={value => handleInputValueChange(value)}
      value={selectedOptions}
      loading={loading}
      debounceEmptyMessageTime={DEBOUNCE_TIME_MILIS * 2}
      hideSelectedCount
      hasError={error}
      loadOptions={
        !autocomplete
          ? async (query: string) => {
              return new Promise<ContentNodeOption[]>(res => {
                return res(options.filter(opt => opt.label.toLowerCase().includes(query.toLowerCase())))
              })
            }
          : undefined
      }
      renderOption={(opt: ContentNodeOption, { selected, highlighted, onDeselect }) => {
        return (
          <ListItem
            onClickRemove={onDeselect ? () => onDeselect(opt) : undefined}
            active={highlighted || selected}
            size="medium"
            prominence="high"
            css={`
              margin-bottom: ${selected ? '1px' : undefined};
            `}
            title={opt.label}
          />
        )
      }}
    />
  )
}
