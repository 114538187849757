import { Box, Icon, ListItem, Text } from '@cutover/react-ui'
import { CustomFieldModel, TaskModel } from 'main/data-access'
import { useLanguage } from 'main/services/hooks'

type TaskEditDeleteCfDataProps = {
  constraints: Record<number, number[]>
  bulk?: boolean
}

export const TaskEditDeleteCfData = ({ constraints, bulk = false }: TaskEditDeleteCfDataProps) => {
  const customFieldsLookup = CustomFieldModel.useGetLookup()
  const taskLookup = TaskModel.useGetLookup()
  const { t } = useLanguage('tasks', { keyPrefix: 'editPanel.customFieldDeleteData' })

  return (
    <Box>
      <Text>{t('message')}</Text>
      <Text css="padding: 16px 0">{t('deleteHeader')}</Text>
      {Object.entries(constraints).map(([taskId, cfIds]) => {
        const key = Number(taskId)
        const task = taskLookup[key]
        return (
          <Box key={key}>
            {bulk && (
              <Box direction="row">
                <Text color="text-light" css="padding-right: 8px;">
                  #{task.internal_id}
                </Text>
                <Text>{task.name}</Text>
              </Box>
            )}
            {cfIds.map(id => (
              <ListItem
                key={id}
                title={customFieldsLookup[id]?.display_name || customFieldsLookup[id]?.name}
                size="medium"
                startComponent={<Icon icon="custom-field" color="primary" />}
              />
            ))}
          </Box>
        )
      })}
    </Box>
  )
}
