import { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'

import { Box, CheckboxesField, Message } from '@cutover/react-ui'
import { useLanguage } from 'main/services/hooks'
import { ApprovalFlowType } from 'main/services/queries/types'
import { useAccountsQuery } from 'main/services/queries/use-accounts'
import {
  CheckboxFieldControlled,
  ColorPickerField,
  IconSelectField,
  RadioboxGroupField,
  SelectField,
  TextAreaField,
  TextInputField
} from 'main/components/shared/form'
import { RunbookTypesSchema } from './schemas/runbook-types-schema'
import { ConfigModel, CurrentUserModel } from 'main/data-access'

export const RunbookTypeFormDetails = ({
  approvalFlows,
  mode
}: {
  approvalFlows: ApprovalFlowType[]
  mode: 'create' | 'edit'
}) => {
  const { t } = useLanguage('runbookTypes')
  const { watch, setValue } = useFormContext()
  const { runbookTypeIcons } = ConfigModel.useGet()
  const { data: accountsData } = useAccountsQuery()

  const { cutover_employee } = CurrentUserModel.useGet()

  const accounts = accountsData?.accounts || []
  const accountOptions = accounts?.map(account => {
    return { label: account.name, value: account.id.toString() }
  })
  const approvalFlowOptions = approvalFlows?.map((approvalFlow: ApprovalFlowType) => {
    return { label: approvalFlow.name, value: approvalFlow.id }
  })

  const isRtoRtaEnabled = ConfigModel.useIsFeatureEnabled('rto_rta')
  const isAiFeaturesEnabled = ConfigModel.useIsFeatureEnabled('ai_features')

  const isIncident = watch('incident')
  const isGlobal = watch('global') === 'true'
  const isDefaultType = watch('default')
  const currentIconColor = watch('icon_color')
  const isDefaultEdit = mode === 'edit' && isDefaultType

  useEffect(() => {
    if (isGlobal) {
      setValue('account_id', null)
    }
  }, [isGlobal])

  useEffect(() => {
    if (isIncident) {
      setValue('dynamic', true)
      setValue('restrict_create_to_templates', true)
    }
  }, [isIncident])

  return (
    <Box>
      {isDefaultType && (
        <>
          <Message type="info" message={t('editSection.defaultTypeInfoMessage')} />
          <Box style={{ paddingBottom: '16px' }} />
        </>
      )}

      <TextInputField<RunbookTypesSchema>
        name="name"
        required
        label={t('fields.name.label')}
        disabled={isDefaultEdit}
        data-testid="runbook-type-name"
      />
      <TextAreaField<RunbookTypesSchema>
        name="description"
        required
        label={t('fields.description.label')}
        data-testid="runbook-type-description"
      />
      <RadioboxGroupField<RunbookTypesSchema>
        name="global"
        label={t('fields.visibility.label')}
        direction="column"
        options={[
          { label: t('fields.visibility.options.global'), value: 'true' },
          { label: t('fields.visibility.options.selectWorkspace'), value: 'false' }
        ]}
        disabled={isDefaultEdit}
      />
      {!isGlobal && (
        <RadioboxGroupField<RunbookTypesSchema>
          name="account_id"
          label={t('fields.visibility.options.workspace')}
          direction="column"
          options={accountOptions}
        />
      )}
      {mode === 'create' && (
        <>
          <IconSelectField<RunbookTypesSchema>
            name="icon_name"
            required
            options={runbookTypeIcons}
            selectedColor={currentIconColor}
            label={t('fields.icon.label')}
          />
          <ColorPickerField<RunbookTypesSchema> name="icon_color" label={t('fields.iconColor.label')} />
        </>
      )}
      <SelectField<RunbookTypesSchema>
        name="approval_flow_id"
        options={approvalFlowOptions}
        label={t('fields.approvalFlow.label')}
      />

      {cutover_employee && (
        <CheckboxesField
          data-testid="Additional Settings"
          direction="column"
          label={t('fields.additionalSettings.label')}
          readOnly={true}
        >
          {isRtoRtaEnabled && (
            <CheckboxFieldControlled<RunbookTypesSchema>
              name="enable_rto"
              label={t('fields.additionalSettings.checkboxLabels.enableRto.label')}
              helpText={t('fields.additionalSettings.checkboxLabels.enableRto.helpText')}
            />
          )}
          <CheckboxFieldControlled<RunbookTypesSchema>
            name="incident"
            label={t('fields.additionalSettings.checkboxLabels.incident.label')}
            helpText={t('fields.additionalSettings.checkboxLabels.incident.helpText')}
            disabled={mode === 'edit'}
          />
          <CheckboxFieldControlled<RunbookTypesSchema>
            name="dynamic"
            label={t('fields.additionalSettings.checkboxLabels.dynamic.label')}
            helpText={t('fields.additionalSettings.checkboxLabels.dynamic.helpText')}
            disabled={isIncident}
          />
          <CheckboxFieldControlled<RunbookTypesSchema>
            name="restrict_create_to_templates"
            label={t('fields.additionalSettings.checkboxLabels.restrictCreateToTemplates.label')}
            helpText={t('fields.additionalSettings.checkboxLabels.restrictCreateToTemplates.helpText')}
            disabled={isIncident}
          />
          {isAiFeaturesEnabled && (
            <CheckboxFieldControlled<RunbookTypesSchema>
              name="ai_create_enabled"
              label={t('fields.additionalSettings.checkboxLabels.aiCreateEnabled.label')}
              helpText={t('fields.additionalSettings.checkboxLabels.aiCreateEnabled.helpText')}
              disabled={isIncident}
            />
          )}
          <CheckboxFieldControlled<RunbookTypesSchema>
            name="disabled"
            label={t('fields.additionalSettings.checkboxLabels.disabled.label')}
          />
        </CheckboxesField>
      )}
    </Box>
  )
}
