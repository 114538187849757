import { useState } from 'react'

import { IntegrationsPanel } from 'main/components/runbook/runbook-edit/integrations/integrations-panel'
import { ReactAngularConnector } from 'main/connectors/react-angular-connector'
import { RunbookShowRunbook } from 'main/services/queries/types'

type RunbookIntegrationsState = {
  runbook: RunbookShowRunbook | null
  runbookVersionId: number | null
}

export const RunbookIntegrationsPanelConnector = () => {
  const [state, setState] = useState<RunbookIntegrationsState>({
    runbook: null,
    runbookVersionId: null
  })

  const handleOpenPanel = ({ runbook, runbookVersionId }: RunbookIntegrationsState) => {
    setState({
      runbook,
      runbookVersionId
    })
  }

  const handleClosePanel = () => {
    setState({ runbook: null, runbookVersionId: null })
  }

  return (
    <ReactAngularConnector
      mountEvent="open-runbook-integrations-panel"
      unmountEvent="close-runbook-integrations-panel"
      onMount={handleOpenPanel}
      onUnmount={handleClosePanel}
    >
      {state.runbook && state.runbookVersionId && (
        <IntegrationsPanel runbook={state.runbook} runbookVersionId={state.runbookVersionId} />
      )}
    </ReactAngularConnector>
  )
}
