import { useMemo } from 'react'
import { format as formatDate, fromUnixTime } from 'date-fns'

import { ColumnConfig, duration as durationFormatter, Text } from '@cutover/react-ui'
import { useLanguage } from 'main/services/hooks'
import { TaskListTask } from 'main/services/queries/types'
import {
  ActiveRunbookModel,
  RunbookComponentModel,
  RunbookTeamModel,
  StreamModel,
  TaskTypeModel
} from 'main/data-access'
import { RunbookUserModel } from 'main/data-access/models/runbook-user-model'
import { completeDurationDiff, inProgDurationDiff, isLate } from '../task-list/task-item/task-list-item-props'

export const useTaskColumns = (): ColumnConfig<TaskListTask>[] => {
  const { t } = useLanguage('tasks', { keyPrefix: 'table' })
  const { stage, is_template: isTemplate } = ActiveRunbookModel.useGet()
  const streamLookup = StreamModel.useGetLookup()
  const taskTypeLookup = TaskTypeModel.useGetLookup()
  const teamsLookup = RunbookTeamModel.useGetLookup()
  const runbookComponentLookup = RunbookComponentModel.useGetLookup()
  const usersLookup = RunbookUserModel.useGetLookup()
  const hideProgressColumns = isTemplate || stage === 'planning'

  return useMemo(
    () => [
      {
        property: 'internal_id',
        header: t('taskId'),
        size: '30px',
        pin: true
      },
      {
        property: 'name',
        header: t('title'),
        pin: true,
        size: '200px'
      },

      {
        property: 'stream',
        sortable: false,
        header: t('stream'),
        render: (datum: TaskListTask) => {
          // Todo: this func will be used in a lot of places - belongs in the StreamsModel
          const stream = streamLookup[datum.stream_id]
          const streamParentName = stream.parent_id ? streamLookup[stream.parent_id]?.name + ' / ' : ''
          return `${streamParentName}${stream.name}`
        },
        size: '150px'
      },
      {
        property: 'task_type_id',
        sortable: false,
        header: t('taskType'),
        render: (datum: TaskListTask) => taskTypeLookup[datum.task_type_id]?.name,
        size: '150px'
      },
      {
        property: 'stage',
        header: t('stage'),
        sortable: false,
        hidden: hideProgressColumns,
        render: (datum: TaskListTask) =>
          datum.stage === 'in-progress' ? t('stages.inProgress') : t('stages.' + datum.stage),
        size: '100px'
      },
      {
        property: 'runbook_team_ids',
        header: t('assignedTeams'),
        render: (datum: TaskListTask) => datum.runbook_team_ids?.map(id => teamsLookup[id].name).join(', '),
        size: '150px'
      },
      {
        property: 'user_ids',
        header: t('assignedUsers'),
        render: (datum: TaskListTask) => datum.user_ids?.map(id => usersLookup[id].name).join(', '),
        size: '150px'
      },

      // Links
      {
        property: 'linked_template_id',
        header: t('linkedTemplateId'),
        render: (datum: TaskListTask) =>
          datum.linked_resource ? datum.linked_resource.linked_runbook_parent_template_id : null,
        size: '50px'
      },
      {
        property: 'linked_runbook_id',
        header: t('linkedRunbookId'),
        render: (datum: TaskListTask) =>
          datum.linked_resource && !datum.linked_resource.is_template ? datum.linked_resource.id : null,
        size: '50px'
      },
      {
        property: 'runbook_component_name',
        header: t('sourceRunbook'),
        sortable: false,
        render: (datum: TaskListTask) =>
          datum.runbook_component_id ? runbookComponentLookup[datum.runbook_component_id]?.name : null,
        size: '150px'
      },
      {
        property: 'runbook_component_id',
        header: t('sourceRunbookId'),
        render: (datum: TaskListTask) =>
          datum.runbook_component_id ? runbookComponentLookup[datum.runbook_component_id]?.source_runbook_id : null,
        size: '50px'
      },

      // Timing fields - planned

      {
        property: 'late',
        header: t('late'),
        hidden: hideProgressColumns,
        sortable: false,
        render: (datum: TaskListTask) => {
          return isLate(datum, stage) ? <Text>{t('yes')}</Text> : <Text>{t('no')}</Text>
        },
        size: '50px'
      },
      {
        property: 'start_planned',
        header: t('plannedStart'),
        sortable: false,
        render: (datum: TaskListTask) =>
          datum.start_latest_planned ? (
            <Text>{formatDate(fromUnixTime(datum.start_latest_planned), 'dd MMM yyyy HH:mm')}</Text>
          ) : null,
        size: '160px'
      },
      {
        property: 'start_fixed',
        header: t('plannedFixedStart'),
        sortable: false,
        render: (datum: TaskListTask) =>
          datum.start_fixed ? <Text>{formatDate(fromUnixTime(datum.start_fixed), 'dd MMM yyyy HH:mm')}</Text> : null,
        size: '160px'
      },
      {
        property: 'duration',
        header: t('plannedDuration'),
        sortable: false,
        render: (datum: TaskListTask) => {
          const display = datum.duration === 0 ? null : durationFormatter(datum.duration, 2)
          return <Text>{display}</Text>
        },
        size: '120px'
      },
      {
        property: 'end_fixed',
        header: t('plannedDueDate'),
        sortable: false,
        render: (datum: TaskListTask) =>
          datum.end_fixed ? <Text>{formatDate(fromUnixTime(datum.end_fixed), 'dd MMM yyyy HH:mm')}</Text> : null,
        size: '160px'
      },
      {
        property: 'end_planned',
        header: t('plannedFinish'),
        sortable: false,
        render: (datum: TaskListTask) =>
          datum.start_latest_planned ? (
            <Text>{formatDate(fromUnixTime(datum.start_latest_planned + datum.duration), 'dd MMM yyyy HH:mm')}</Text>
          ) : null,
        size: '160px'
      },

      // Timing fields - actual
      {
        property: 'start_actual',
        header: t('actualStart'),
        sortable: false,
        hidden: hideProgressColumns,
        render: (datum: TaskListTask) =>
          datum.start_actual ? <Text>{formatDate(fromUnixTime(datum.start_actual), 'dd MMM yyyy HH:mm')}</Text> : null,
        size: '160px'
      },
      {
        property: 'actualDuration',
        header: t('actualDuration'),
        sortable: false,
        hidden: hideProgressColumns,
        render: (datum: TaskListTask) =>
          datum.completion_type === 'complete_normal' && datum.stage === 'complete'
            ? durationFormatter(datum.end_display - datum.start_display, 2)
            : '--',
        size: '120px'
      },
      {
        property: 'end_actual',
        header: t('actualFinish'),
        sortable: false,
        hidden: hideProgressColumns,
        render: (datum: TaskListTask) =>
          datum.end_actual ? <Text>{formatDate(fromUnixTime(datum.end_actual), 'dd MMM yyyy HH:mm')}</Text> : null,
        size: '160px'
      },
      {
        property: 'durationDiff',
        header: t('durationDiff'),
        sortable: false,
        hidden: hideProgressColumns,
        render: (datum: TaskListTask) => {
          // Note: tries to reuse logic in task-item-props
          let diff
          if (datum.stage === 'in-progress') {
            diff = inProgDurationDiff(datum.start_display, datum.end_display, datum.duration).text
          } else if (datum.stage === 'complete' && datum.completion_type === 'complete_normal') {
            diff = completeDurationDiff(datum.start_display, datum.end_display, datum.duration)?.text
          }
          return <Text>{diff ?? '--'}</Text>
        },
        size: '120px'
      }
    ],
    []
  )
}
