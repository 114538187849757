import { useCallback, useMemo, useState } from 'react'
import { format } from 'date-fns'

import { IconButton, IconName, Menu, MenuListItem, MenuListItemProps, useNotify } from '@cutover/react-ui'
import { useAppliedFilters } from 'main/recoil/data-access'
import { ActiveAccountModel, ActiveDashboardModel, ActiveRunbookModel, RunbookViewModel } from 'main/data-access'
import { useLanguage } from 'main/services/hooks'
import { useToggleRightPanel } from 'main/components/layout/right-panel'
import { useDashboardPdf } from 'main/services/queries/use-dashboard-query'

export const DashboardSubHeaderRightMenu = ({
  showShareRunbookDashboardButton
}: {
  showShareRunbookDashboardButton: boolean
}) => {
  const { t } = useLanguage('runbook', { keyPrefix: 'subHeader' })
  const notify = useNotify()
  const [menuIcon, setMenuIcon] = useState<IconName>('more-vertical')
  const dashboard = ActiveDashboardModel.useGet()
  const dashboardId = dashboard?.id
  const { slug: accountSlug } = ActiveAccountModel.useGet()
  const { name: runbookName, id: runbookId } = ActiveRunbookModel.useGet()
  const dashboardPdfMutation = useDashboardPdf()
  const toggleSchedulePanel = useToggleRightPanel('runbook-dashboard-schedule')
  const openModal = RunbookViewModel.useAction('modal:open')
  const filters = useAppliedFilters()

  const downloadPDF = useCallback(() => {
    const date = format(new Date(), "dd'-'MM'-'yyyy'_at_'HH'-'mm")
    const fileName = dashboard
      ? `${runbookName.replace(/ /g, '_')}_${dashboard?.name.replace(/ /g, '_')}_${date}.pdf`
      : null

    setMenuIcon('spinner')

    if (fileName && dashboardId && accountSlug) {
      const { account_id: _, ...restFilters } = filters
      dashboardPdfMutation.mutate(
        {
          file_name: fileName,
          id: dashboardId.toString(),
          account_id: accountSlug,
          runbook_id: runbookId.toString(),
          ...restFilters
        },
        {
          onSuccess: () => {
            setMenuIcon('check')
            notify.success(t('downloadPDF.notification.success.message'), {
              title: t('downloadPDF.notification.success.title')
            })
          },
          onSettled: () => {
            setTimeout(() => {
              setMenuIcon('more-vertical')
            }, 1500)
          }
        }
      )
    }
  }, [dashboard, dashboardId, accountSlug, runbookId, filters, dashboardPdfMutation, notify, t])

  const menuOptions = useMemo(() => {
    return [
      showShareRunbookDashboardButton && {
        label: t('dashboardMenu.manageSchedules'),
        icon: 'scheduled-share',
        onClick: () => {
          dashboardId && toggleSchedulePanel({ dashboardId })
        },
        appendDivider: true
      },
      {
        label: t('dashboardMenu.exportTasks'),
        icon: 'download',
        onClick: () => {
          openModal({ type: 'tasks-export' })
        }
      },
      {
        label: t('dashboardMenu.downloadAsPDF'),
        icon: 'download',
        onClick: downloadPDF
      }
    ].filter(Boolean) as MenuListItemProps[]
  }, [showShareRunbookDashboardButton, dashboardId, toggleSchedulePanel, downloadPDF])

  return (
    <Menu
      trigger={
        <IconButton
          tertiary
          data-testid="sub-header-options-menu"
          label={t('moreOptions')}
          disableTooltip
          icon={menuIcon}
          css="z-index: 1;"
        />
      }
    >
      {menuOptions.map(item => (
        <MenuListItem
          label={item.label}
          suffixColor="text-disabled"
          key={item.label}
          icon={item.icon}
          onClick={item.onClick}
          appendDivider={item.appendDivider}
        />
      ))}
    </Menu>
  )
}
