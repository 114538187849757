import { useParams } from 'react-router-dom'

import { ScrollContent } from '@cutover/react-ui'
import { DashboardPageForm } from './dashboard-page-form'
import { RunbookWidgetCollection } from 'main/components/dashboards/dashboard-widget-collection'
import { Dashboard as DashboardType } from 'main/services/queries/types'
import { useDashboardQuery } from 'main/services/queries/use-dashboard-query'

type PirDashboardProps = {
  dashboard: DashboardType
  runbookId: number
}

export const PirDashboard = ({ dashboard, runbookId }: PirDashboardProps) => {
  const { accountId } = useParams<{ accountId?: string }>()
  const { data } = useDashboardQuery(
    {
      accountId: accountId,
      dashboardKey: String(dashboard.id),
      runbookId: String(runbookId),
      filterParams: ''
    },
    { enabled: true }
  )

  const components = data?.dashboard?.components

  return (
    <>
      {components && (
        <DashboardPageForm components={components} dashboard={dashboard} isPir>
          <ScrollContent>
            <RunbookWidgetCollection components={components} media="screen" />
          </ScrollContent>
        </DashboardPageForm>
      )}
    </>
  )
}
