import { RecoilValueReadOnly } from 'recoil'

import { filterSelector } from './filter-state'
import { RunbookFilterType } from 'main/services/tasks/filtering'
import { RunbookTypeFilterType } from 'main/services/settings/filtering'

export const runbookTypeGlobalFilterState = filterSelector({ attribute: 'global' }) as RecoilValueReadOnly<
  RunbookTypeFilterType['global']
>

export const runbookTypeAccountFilterState = filterSelector({ attribute: 'account_id' }) as RecoilValueReadOnly<
  RunbookTypeFilterType['account_id']
>

export const runbookLevelFilterState = filterSelector({ attribute: 'lv' }) as RecoilValueReadOnly<
  RunbookFilterType['lv']
>

export const runbookUserFilterState = filterSelector({ attribute: 'user' }) as RecoilValueReadOnly<
  RunbookFilterType['user']
>

export const runbookTeamFilterState = filterSelector({ attribute: 'team' }) as RecoilValueReadOnly<
  RunbookFilterType['team']
>

export const runbookTeamIncludeUsersState = filterSelector({ attribute: 'includeUsers' }) as RecoilValueReadOnly<
  RunbookFilterType['includeUsers']
>

export const criticalPathFilterState = filterSelector({ attribute: 'critical' }) as RecoilValueReadOnly<
  RunbookFilterType['critical']
>

export const milestoneFilterState = filterSelector({ attribute: 'm' }) as RecoilValueReadOnly<RunbookFilterType['m']>

export const dateWithinFilterState = filterSelector({ attribute: 'dd' }) as RecoilValueReadOnly<RunbookFilterType['dd']>

export const startNotificationFilterState = filterSelector({ attribute: 'sn' }) as RecoilValueReadOnly<
  RunbookFilterType['sn']
>

export const fixedStartFilterState = filterSelector({ attribute: 'fs' }) as RecoilValueReadOnly<RunbookFilterType['fs']>

export const fixedEndFilterState = filterSelector({ attribute: 'fe' }) as RecoilValueReadOnly<RunbookFilterType['fe']>

export const hasCommentsFilterState = filterSelector({ attribute: 'c' }) as RecoilValueReadOnly<RunbookFilterType['c']>

export const lateFilterState = filterSelector({ attribute: 'l' }) as RecoilValueReadOnly<RunbookFilterType['l']>

export const overRunningFilterState = filterSelector({ attribute: 'or' }) as RecoilValueReadOnly<
  RunbookFilterType['or']
>

export const hasPredecessorsFilterState = filterSelector({ attribute: 'hp' }) as RecoilValueReadOnly<
  RunbookFilterType['hp']
>

export const hasSuccessorsFilterState = filterSelector({ attribute: 'hs' }) as RecoilValueReadOnly<
  RunbookFilterType['hs']
>

export const hasErrorsFilterState = filterSelector({ attribute: 'he' }) as RecoilValueReadOnly<RunbookFilterType['he']>

export const myTasksFilterState = filterSelector({ attribute: 'mt' }) as RecoilValueReadOnly<RunbookFilterType['mt']>

export const activeTasksFilterState = filterSelector({ attribute: 'at' }) as RecoilValueReadOnly<
  RunbookFilterType['at']
>

export const dateFromFilterState = filterSelector({ attribute: 'df' }) as RecoilValueReadOnly<RunbookFilterType['df']>

export const dateToFilterState = filterSelector({ attribute: 'dt' }) as RecoilValueReadOnly<RunbookFilterType['dt']>

export const completionTypeFilterState = filterSelector({ attribute: 'ct' }) as RecoilValueReadOnly<
  RunbookFilterType['ct']
>

export const startRequirementsFilterState = filterSelector({ attribute: 'sr' }) as RecoilValueReadOnly<
  RunbookFilterType['sr']
>

export const endRequirementsFilterState = filterSelector({ attribute: 'er' }) as RecoilValueReadOnly<
  RunbookFilterType['er']
>

export const assignedFilterState = filterSelector({ attribute: 'a' }) as RecoilValueReadOnly<RunbookFilterType['a']>

export const stageFilterState = filterSelector({ attribute: 'stage' }) as RecoilValueReadOnly<
  RunbookFilterType['stage']
>

export const streamFilterState = filterSelector({ attribute: 'stream' }) as RecoilValueReadOnly<
  RunbookFilterType['stream']
>

export const searchQueryFilterState = filterSelector({ attribute: 'q' }) as RecoilValueReadOnly<RunbookFilterType['q']>

export const taskTypeFilterState = filterSelector({ attribute: 'type' }) as RecoilValueReadOnly<
  RunbookFilterType['type']
>

export const criticalPathToHereFilterState = filterSelector({ attribute: 'critical_to_here' }) as RecoilValueReadOnly<
  RunbookFilterType['critical_to_here']
>

export const ancestorsFilterState = filterSelector({ attribute: 'predecessors_to_here' }) as RecoilValueReadOnly<
  RunbookFilterType['predecessors_to_here']
>

export const runbookComponentFilterState = filterSelector({ attribute: 'rbc' }) as RecoilValueReadOnly<
  RunbookFilterType['rbc']
>
