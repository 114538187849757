import { format } from 'date-fns'

import { Accordion, AccordionPanel } from '@cutover/react-ui'
import { useLanguage } from 'main/services/hooks'
import { CheckboxFieldControlled, TextAreaField, TextInputField } from 'main/components/shared/form'
import { ConnectSetting } from 'main/services/queries/types/connect'
import { ConnectSettingEditProps } from './connect-settings-edit-panel'

type ConnectSettingsEditFormProps = {
  data: ConnectSetting | undefined
}

export const ConnectSettingsEditForm = ({ data }: ConnectSettingsEditFormProps) => {
  const { t } = useLanguage('connectSettings')

  return (
    <>
      <TextInputField name="display_name" label={t('editPanel.notifications.name')} required />

      <Accordion>
        <AccordionPanel
          initialActive
          label={t('editPanel.notifications.title')}
          icon="notifications"
          iconColor="text-light"
        >
          <TextInputField
            name="notification_threshold"
            required
            label={t('editPanel.notifications.notifyThreshold.label')}
            tooltipText={t('editPanel.notifications.notifyThreshold.helpText')}
          />

          <TextInputField
            name="critical_window_interval"
            required
            label={t('editPanel.notifications.criticalThreshold.label')}
            tooltipText={t('editPanel.notifications.criticalThreshold.helpText')}
          />

          <TextAreaField
            name="notification_cadence"
            value={data?.notification_cadence}
            label={t('editPanel.notifications.notifyCadence.label')}
            tooltipText={t('editPanel.notifications.notifyCadence.helpText')}
            disabled
          />

          <CheckboxFieldControlled<ConnectSettingEditProps>
            name="mute_notifications"
            label={t('editPanel.notifications.mute.label')}
          />
        </AccordionPanel>

        <AccordionPanel label={t('editPanel.otherDetails')} icon="info" iconColor="text-light">
          <TextAreaField
            name="created_at"
            value={`${data?.created_at ? format(new Date(data.created_at), 'E, do MMM yyyy HH:mm') : ''}`}
            label={t('editPanel.accordion.createdAt')}
            disabled
          />
          <TextAreaField
            name="updated_at"
            value={`${data?.updated_at ? format(new Date(data.updated_at), 'E, do MMM yyyy HH:mm') : ''}`}
            label={t('editPanel.accordion.updatedAt')}
            disabled
          />
          <TextAreaField name="updater" value={data?.updater} label={t('editPanel.accordion.lastUpdatedBy')} disabled />
        </AccordionPanel>
      </Accordion>
    </>
  )
}
