import { useEffect } from 'react'
import { AxiosError } from 'axios'

import { RunbookViewModel } from 'main/data-access'
import { apiClient_UNSTABLE } from '..'

const RUNBOOK_VERSION_REGEX = /runbooks\/\d+\/runbook_versions/

// Runbook-level interceptor for all axios requests, needs to be called in the runbook context only
// TODO more errors need to be handled here, see promise_helper_factory.js for the full list
// https://cutover.atlassian.net/browse/CFE-2372
export const useRunbookAPIInterceptor = () => {
  const openModal = RunbookViewModel.useAction('modal:open')

  useEffect(() => {
    const responseIntercepter = apiClient_UNSTABLE.interceptors.response.use(
      response => response,
      function (error: AxiosError) {
        if (
          error.response &&
          error.response.status === 403 &&
          error.response.config &&
          error.response.config.url &&
          RUNBOOK_VERSION_REGEX.test(error.response.config?.url)
        ) {
          openModal({ type: 'task-unable-to-perform-action' })
        }

        return Promise.reject(error)
      }
    )

    return () => {
      apiClient_UNSTABLE.interceptors.response.eject(responseIntercepter)
    }
  }, [])
}
