import {
  LoadingPanel,
  PageFilterPanelLoader,
  PageHeadingLoader,
  PageSubHeadingLoader,
  SetDefaultLayoutProps
} from '@cutover/react-ui'
import { Layout } from 'main/components/layout'

export const LayoutLoading = ({ filterPanel, subHeader, rightNav }: SetDefaultLayoutProps) => {
  return (
    <Layout
      data-testid="layout-loading"
      header={<PageHeadingLoader />}
      subHeader={subHeader ? <PageSubHeadingLoader /> : null}
      filter={filterPanel ? <PageFilterPanelLoader /> : null}
      rightNavigation={rightNav ? <div></div> : null}
      // children here is what typically has the outlet that the page content is injected into
      // we need to overwrite that with the loading panel while all of the page dependencies load
      children={<LoadingPanel contained />}
    />
  )
}
