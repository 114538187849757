import { useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { eventManager } from 'event-manager'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'

import { Box, Button, Modal, useNotify } from '@cutover/react-ui'
import { EnterSnippetDetails } from './enter-snippet-details'
import { ValidateSnippet } from './validate-snippet'
import { useLanguage } from 'main/services/hooks'
import { DuplicateRunbookResponseType, useDuplicateRunbook } from 'main/services/queries/use-duplicate-runbooks'

type CreateSnippetProps = {
  runbookId: number
  taskInternalIds: number[]
  triggeredFromAngular?: boolean
  onClose?: () => void
}

type FormValues = {
  name: string
}

export const CreateSnippetFromRunbookModal = ({
  runbookId,
  taskInternalIds,
  triggeredFromAngular,
  onClose
}: CreateSnippetProps) => {
  const [isModalOpen, setIsModalOpen] = useState(true)
  const [modalStep, setModalStep] = useState<'enterDetailsModal' | 'validateSnippet'>('enterDetailsModal')
  const { t } = useLanguage('runbooks')
  const mutation = useDuplicateRunbook(runbookId)
  const notify = useNotify()

  const unmountConnector = () => {
    eventManager.emit('hide-create-snippet-from-runbook-modals')
  }

  const closeModalWithUnmount = () => {
    setIsModalOpen(false)
    if (triggeredFromAngular) {
      unmountConnector()
    } else {
      onClose?.()
    }
  }

  const methods = useForm<FormValues>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(
      yup.object().shape({
        name: yup.string().required(t('enterSnippetDetails.fields.name.errors.required'))
      })
    ),
    defaultValues: {
      name: ''
    }
  })

  const onFormSubmit = async ({ name }: FormValues) => {
    const payload = {
      name,
      template_type: 'snippet',
      template_status: 'template_draft',
      task_internal_ids: taskInternalIds,
      copy_tasks: true,
      copy_teams: false,
      copy_users: false
    }
    await mutation.mutateAsync(payload, {
      onSuccess: data => {
        const runbook: DuplicateRunbookResponseType = data

        if (runbook) {
          closeModalWithUnmount()
          notify.success(t('duplicateRunbookModal.notification.success.messageCreateSnippet', { name: name }), {
            title: t('duplicateRunbookModal.notification.success.title')
          })
        }
      }
    })
  }

  const isEnterDetailsModal = modalStep === 'enterDetailsModal'

  const modalContent = isEnterDetailsModal ? (
    <EnterSnippetDetails methods={methods} onClickContinue={() => setModalStep('validateSnippet')} />
  ) : (
    <ValidateSnippet taskCount={taskInternalIds.length} />
  )

  const title = isEnterDetailsModal ? t('enterSnippetDetails.heading') : t('enterSnippetDetails.createSnippet')
  const confirmIcon = isEnterDetailsModal ? ('chevron-right' as const) : ('add' as const)
  const confirmText = isEnterDetailsModal
    ? t('common:nextButton')
    : mutation?.isLoading
    ? t('common:addingText')
    : t('common:addButton')
  const handleClickConfirm = isEnterDetailsModal
    ? methods.formState.isDirty
      ? () => setModalStep('validateSnippet')
      : undefined
    : methods.handleSubmit(onFormSubmit)

  return (
    <Box pad="large">
      <Modal
        title={title}
        open={isModalOpen}
        onClose={closeModalWithUnmount}
        hideFooter={false}
        onClickBack={isEnterDetailsModal ? undefined : () => setModalStep('enterDetailsModal')}
        confirmIcon={confirmIcon}
        confirmText={confirmText}
        onClickConfirm={handleClickConfirm}
        customButton={
          isEnterDetailsModal ? (
            <Button
              tertiary
              href={t('enterSnippetDetails.learnMoreLink')}
              target="_blank"
              label={t('common:learnMore')}
            />
          ) : null
        }
      >
        <form onSubmit={methods.handleSubmit(onFormSubmit)} aria-label="form">
          {modalContent}
        </form>
      </Modal>
    </Box>
  )
}
