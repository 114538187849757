import styled from 'styled-components/macro'

import { Box } from '../../../layout'
import { themeColor } from '../../../theme'
import { ViewToggleIconButton } from '../../../button'

export const SectionWrapper = styled.div`
  display: flex;
  padding: 0 4px 0 2px;
  position: relative;
  &:before {
    position: absolute;
    left: 0;
    top: 6px;
    content: ' ';
    width: 1px;
    height: 20px;
    background-color: ${themeColor('bg-4')};
  }
`

export const ToolbarButton = styled(ViewToggleIconButton).attrs(() => ({
  size: 'medium',
  tipPlacement: 'top'
}))`
  margin-left: 2px;
`

export const ToolbarContainer = styled(Box)`
  container-type: inline-size;
  container-name: text-editor-toolbar;

  .show-above-medium,
  .show-above-large,
  .show-above-xlarge {
    display: none;
  }

  .link-section {
    padding-right: 0;
  }
  .list-section,
  .table-section {
    padding: 0;
  }
  .list-section:before,
  .table-section:before {
    display: none;
  }
`
