import { unescape } from 'lodash'
import { useMutation, useQuery } from 'react-query'
import { v4 as uuidv4 } from 'uuid'

import { RequestParams } from '@cutover/api'
import { DashboardComponentCollection, DashboardMediaType } from 'main/components/dashboards/widgets/types'
import { RunbooksFilter } from 'main/components/shared/filter/filter-types'
import { apiClient } from 'main/services/api'
import { ApiError } from 'main/services/api/http-gateway-adapter'
import { DashboardContextType } from 'main/services/queries/types'
import { getClientIANATimezone } from 'main/services/timezone-util'
import { RunbookFilterType } from 'main/services/tasks/filtering/filters'

export type DashboardResponse = {
  dashboard: {
    category: string
    name: string
    components: DashboardComponentCollection
    context: DashboardContextType
    media: DashboardMediaType
  }
  meta: DashboardMeta
  requestId: string
}

export type DashboardMeta = {
  all_filtered_runbook_ids: number[]
  filtered_results_count: number
  offset: number
  total_results_count: number
}

type UseDashboardQueryProps = {
  accountId?: string
  dashboardKey?: string
  runbookId?: string
  filterParams?: string
  requestId?: string
}

type UseDashboardQueryOption = {
  enabled?: boolean
}

export function useDashboardQuery(
  { accountId, dashboardKey, runbookId, filterParams }: UseDashboardQueryProps,
  opt: UseDashboardQueryOption = {}
) {
  return useQuery<DashboardResponse>(
    ['dashboards', dashboardKey, accountId, runbookId, filterParams],
    async () => {
      const requestId = uuidv4()
      const params = {
        account_id: accountId,
        template_type: 'off',
        sync: false,
        request_id: requestId,
        timezone: getClientIANATimezone()
      } as RequestParams

      if (runbookId) {
        params.runbook_id = [runbookId]
      }

      const { data } = await apiClient.get<DashboardResponse>({
        url: `dashboards/${dashboardKey}/results${filterParams}`,
        params
      })

      return {
        ...data,
        requestId: requestId,
        dashboard: {
          ...data.dashboard,
          name: unescape(data.dashboard.name),
          components: data.dashboard.components.map(component => {
            const componentData = {
              ...component,
              // TODO: type component values properly (dashboard/widget/types)
              // value structure vary by component type besides name and id
              values: component.values.map((value: any) => ({ ...value, name: unescape(value.name) }))
            }

            if (component.task_filters) {
              if (component.task_filters.type) {
                componentData.task_filters.type = component.task_filters.type?.map(Number)
              }

              if (component.task_filters.user) {
                componentData.task_filters.user = component.task_filters.user?.map(Number)
              }

              if (component.task_filters.stream) {
                componentData.task_filters.stream = component.task_filters.stream?.map(Number)
              }
              if (component.task_filters.mt) {
                componentData.task_filters.mt = component.task_filters.mt === 'true'
              }
            }

            return componentData
          })
        }
      }
    },
    opt
  )
}

type DashboardPdfRequestType = {
  id: string
  file_name: string
  account_id: string
  runbook_id?: string
} & Partial<RunbookFilterType | Record<RunbooksFilter, string | string[]>>

export function useDashboardPdf() {
  return useMutation<unknown, ApiError, DashboardPdfRequestType>('dashboard-pdf', async payload => {
    await apiClient.post({
      url: 'dashboards/pdf',
      data: payload,
      config: {
        responseType: 'arraybuffer'
      }
    })
  })
}
