import { useDefaultLayout } from '@cutover/react-ui'
import { ConnectSettingsHeader } from './connect-settings-header/connect-settings-header'
import { Layout } from 'main/components/layout'
import { useHasPermissionsOrNavigateHome } from 'main/services/hooks'
import { ConnectSettingEditPanel } from './right-panels/connect-settings-edit-panel'

export const ConnectSettingsLayout = () => {
  const userCanViewPage = useHasPermissionsOrNavigateHome('connect_settings')

  useDefaultLayout({
    filterPanel: false,
    subHeader: false,
    rightNav: false
  })

  return userCanViewPage ? (
    <Layout header={<ConnectSettingsHeader />} filter={null} rightPanels={<ConnectSettingEditPanel />} />
  ) : null
}
