// @ts-nocheck
import 'reflect-metadata'
import { AccountRepository } from 'app/Repositories/Account/AccountRepository'
import { AccountRunbooksRepository } from 'app/Repositories/Runbooks/AccountRunbooksRepository'
import { AccountsPageViewPresenter } from 'app/Settings/Accounts/AccountsPage/AccountsPageViewPresenter'
import { AccountsRepository } from 'app/Repositories/Account/AccountsRepository'
import { AuthenticationRepository } from 'app/Authentication/AuthenticationRepository'
import { BrowserHostGateway } from 'Gateways/Window/BrowserHostGateway'
import { CentralTeamsRepository } from 'app/Repositories/Teams/CentralTeamsRepository'
import { ConfigsRepository } from 'Configs/ConfigsRepository'
import { Container } from 'inversify'
import { CookieStorageGateway } from 'Gateways/Storage/CookieStorageGateway'
import { CurrentPagePresenter } from 'app/Routing/CurrentPage/CurrentPagePresenter'
import { CustomFieldGroupRepository } from 'app/Repositories/CustomFieldGroupRepository'
import { CustomFieldGroupsRepository } from 'app/Repositories/CustomFieldGroupsRepository'
import { CustomFieldGroupViewPresenter } from 'app/Settings/CustomFieldGroups/CustomFieldGroupsPage/CustomFieldGroupViewPresenter'
import { CustomFieldRepository } from 'app/Repositories/CustomField/CustomFieldRepository'
import { CustomFieldsRepository } from 'app/Repositories/CustomField/CustomFieldsRepository'
import { CustomFieldsViewPresenter } from 'app/Settings/CustomFields/CustomFieldsPage/CustomFieldsViewPresenter'
import { DashboardsRepository } from 'app/Repositories/Dashboard/DashboardsRepository'
import { FakeHostGateway } from 'Gateways/Window/FakeHostGateway'
import { FakeLanguageService } from 'Helpers/Language/FakeLanguageService'
import { FakeRouterGateway } from 'app/Routing/FakeRouterGateway'
import { FakeServiceGateway } from 'Gateways/Service/FakeServiceGateway'
import { FakeStorageGateway } from 'Gateways/Storage/FakeStorageGateway'
import { FakeWebSocketGateway } from 'Gateways/WebSocket/FakeWebSocketGateway'
import { FeatureFlagService } from 'app/Shared/Helpers/FeatureFlagService'
import { FieldOptionRepository } from 'app/Repositories/FieldOption/FieldOptionRepository'
import { FiltersRepository } from 'app/Filters/FiltersRepository'
import { HttpServiceGateway } from 'Gateways/Service/HttpServiceGateway'
import { I18NextLanguageService } from 'Helpers/Language/I18NextLanguageService'
import { RoleTypeRepository } from 'app/Repositories/RoleType/RoleTypeRepository'
import { RoleTypesApplicationPresenter } from 'app/Access/RoleTypes/RoleTypesPage/RoleTypesApplicationPresenter'
import { RoleTypesRepository } from 'app/Repositories/RoleType/RoleTypesRepository'
import { Router } from 'app/Routing/Router'
import { RouteRegistrar } from 'app/Routing/RouteRegistrar'
import { RouterGateway } from 'app/Routing/RouterGateway'
import { RouteUpdater } from 'app/Routing/RouteUpdater'
import { RoutingState } from 'app/Routing/RoutingState'
import { RunbookRepository } from 'app/Repositories/Runbook/RunbookRepository'
import { RunbooksRepository } from 'app/Repositories/Runbooks/RunbooksRepository'
import { RunbookTemplatesRepository } from 'app/Repositories/Runbooks/RunbookTemplatesRepository'
import { RunbookTeamRepository } from 'app/Repositories/Teams/RunbookTeamRepository'
import { RunbookTypeRepository } from 'app/Repositories/RunbookType/RunbookTypeRepository'
import { RunbookTypesRepository } from 'app/Repositories/RunbookType/RunbookTypesRepository'
import { RunbookUsersRepository } from 'app/Repositories/RunbookUser/RunbookUsersRepository'
import { RunbookUsersViewPresenter } from 'app/Account/Runbooks/RunbookPage/RunbookUsersPage/RunbookUsersViewPresenter'
import { RunbookVersionRepository } from 'app/Repositories/RunbookVersion/RunbookVersionRepository'
import { TableScrollerService } from 'Components/Organisms'
import { TaskTypesRepository } from 'app/Repositories/TaskType/TaskTypesRepository'
import { TaskTypesViewPresenter } from 'app/Settings/TaskTypes/TaskTypesPage/TaskTypesViewPresenter'
import { ToasterService } from 'Components/Molecules'
import { Types } from 'Gateways/Service/Types'
import { UserAppTokenApplicationPresenter } from 'app/Access/Users/UsersPage/UserAppTokenApplicationPresenter'
import { UserAppTokenRepository } from 'app/Repositories/User/UserAppTokenRepository'
import { UserOauthClientSessionRepository } from 'app/Repositories/User/UserOauthClientSessionRepository'
import { UserEditPresenter } from 'app/Access/Users/UsersPage/UsersEdit/UserEditPresenter'
import { UserEditUnlockPresenter } from 'app/Access/Users/UsersPage/UsersEdit/UserEditUnlockPresenter'
import { UserPageFilterPanelPresenter } from 'app/Access/Users/UsersPage/UsersPageFilterPanel/UserPageFilterPanelPresenter'
import { UserPageHeadingPresenter } from 'app/Access/Users/UsersPage/UsersPageHeading/UserPageHeadingPresenter'
import { UserPagePresenter } from 'app/Access/Users/UsersPage/UsersPagePresenter'
import { UserRepository } from 'app/Repositories/User/UserRepository'
import { UsersRepository } from 'app/Repositories/User/UsersRepository'
import { UsersViewPresenter } from 'app/Access/Users/UsersPage/UsersViewPresenter'
import { UserVerifyRepository } from 'app/Repositories/UserVerify/UserVerifyRepository'
import { UserVerifyViewPresenter } from 'app/UserVerify/UserVerifyPage/UserVerifyViewPresenter'
import * as Toastr from 'toastr'

/** @deprecated this container is deprecated and only part of the legacy react application (single-spa). Components should rely on globally available contexts instead. */
let container: any

export interface ContainerOptions {
  useFakeLanguageService?: boolean
  useFakeServiceGateway?: boolean
}

const isTest = process.env.NODE_ENV === 'test'

/** @deprecated createContainer should only be used as part of legacy React. Please rely
 * on globally available contexts instead.
 */
const createContainer = async (options?: ContainerOptions) => {
  const { useFakeLanguageService = isTest, useFakeServiceGateway = isTest } = options ?? {}

  container = new Container({
    autoBindInjectable: true,
    defaultScope: 'Transient'
  })

  if (isTest) {
    DIBindSingleton(Types.IRouterGateway, FakeRouterGateway)
    DIBindSingleton(Types.IStorageGateway, FakeStorageGateway)
    DIBindSingleton(Types.IUIHostGateway, FakeHostGateway)
    DIBindSingleton(Types.IWebSocketGateway, FakeWebSocketGateway)
  } else {
    DIBindSingleton(Types.IRouterGateway, RouterGateway)
    DIBindSingleton(Types.IStorageGateway, CookieStorageGateway)
    DIBindSingleton(Types.IUIHostGateway, BrowserHostGateway)
  }

  if (useFakeLanguageService) {
    DIBindSingleton(Types.ILanguageService, FakeLanguageService)
  } else {
    DIBindSingleton(Types.ILanguageService, I18NextLanguageService)
  }

  if (useFakeServiceGateway) {
    DIBindSingleton(Types.IServiceGateway, FakeServiceGateway)
  } else {
    DIBindSingleton(Types.IServiceGateway, HttpServiceGateway)
  }

  // LEGACY - should not use singleton unless Repository
  selfBindSingleton(UserEditPresenter)
  selfBindSingleton(UserPageHeadingPresenter)
  selfBindSingleton(UserEditUnlockPresenter)
  selfBindSingleton(UserPageFilterPanelPresenter)
  selfBindSingleton(UserPagePresenter)
  selfBindSingleton(ToasterService)
  selfBindSingleton(TableScrollerService)

  // Utilities
  selfBindSingleton(FeatureFlagService)

  // view Presenters
  selfBindSingleton(AccountsPageViewPresenter)
  selfBindSingleton(CustomFieldGroupViewPresenter)
  selfBindSingleton(CustomFieldsViewPresenter)
  selfBindSingleton(RoleTypesApplicationPresenter)
  selfBindSingleton(TaskTypesViewPresenter)
  selfBindSingleton(UserAppTokenApplicationPresenter)
  selfBindSingleton(UsersViewPresenter)
  selfBindSingleton(UserVerifyViewPresenter)

  // app state (observable)
  selfBindSingleton(AccountRepository)
  selfBindSingleton(AccountRunbooksRepository)
  selfBindSingleton(AccountsRepository)
  selfBindSingleton(CentralTeamsRepository)
  selfBindSingleton(AuthenticationRepository)
  selfBindSingleton(ConfigsRepository)
  selfBindSingleton(CurrentPagePresenter)
  selfBindSingleton(CustomFieldGroupRepository)
  selfBindSingleton(CustomFieldGroupsRepository)
  selfBindSingleton(CustomFieldRepository)
  selfBindSingleton(CustomFieldsRepository)
  selfBindSingleton(DashboardsRepository)
  selfBindSingleton(FieldOptionRepository)
  selfBindSingleton(FiltersRepository)
  selfBindSingleton(RoleTypeRepository)
  selfBindSingleton(RoleTypesRepository)
  selfBindSingleton(Router)
  selfBindSingleton(RouteRegistrar)
  selfBindSingleton(RouteUpdater)
  selfBindSingleton(RunbookRepository)
  selfBindSingleton(RunbooksRepository)
  selfBindSingleton(RunbookTemplatesRepository)
  selfBindSingleton(RunbookTeamRepository)
  selfBindSingleton(RunbookTypeRepository)
  selfBindSingleton(RunbookTypesRepository)
  selfBindSingleton(RunbookUsersRepository)
  selfBindSingleton(RunbookUsersViewPresenter)
  selfBindSingleton(RunbookVersionRepository)
  selfBindSingleton(TaskTypesRepository)
  selfBindSingleton(UserAppTokenRepository)
  selfBindSingleton(UserOauthClientSessionRepository)
  selfBindSingleton(UserRepository)
  selfBindSingleton(UsersRepository)
  selfBindSingleton(UserVerifyRepository)

  // VALID - add your new state and repos here
  // app routing
  selfBindSingleton(RoutingState)

  await container.get(Types.ILanguageService).mount(container.get(Types.IUIHostGateway).getHostLanguage())

  return container
}

const clearContainer = () => {
  if (!container) return
  container.get(Types.IRouterGateway).unload()
  Toastr.remove()
  container = null
}

const resetContainer = async () => {
  clearContainer()
  await createContainer()
}

let selfBindSingleton = ref => {
  DIBindSingleton(ref, ref)
}

let DIBindSingleton = (left, right) => {
  container.bind(left).to(right).inSingletonScope()
}

export { container, resetContainer, clearContainer, createContainer }
