export const routes = {
  toWorkspaceTemplates: ({ accountSlug }: { accountSlug: string }) => {
    return `/app/${accountSlug}/runbooks/list?sort_by=updated_at&sort_dir=desc&template_type=default`
  },
  toWorkspaceSnippets: ({ accountSlug }: { accountSlug: string }) => {
    return `/app/${accountSlug}/runbooks/list?sort_by=updated_at&sort_dir=desc&template_type=snippet`
  },
  toWorkspaceRunbooks: ({ accountSlug }: { accountSlug: string }) => {
    return `/app/${accountSlug}/runbooks/list?sort_by=updated_at&sort_dir=desc`
  },

  toRunbook: ({
    accountSlug,
    runbookId,
    versionId,
    params = '',
    reactRunbook
  }: {
    accountSlug: string
    runbookId: number
    versionId?: number
    reactRunbook?: boolean
    params?: string
  }) =>
    `/app/${accountSlug}/runbooks/${runbookId}/${versionId ? versionId : 'current_version'}/tasks/${
      reactRunbook ? 'react-list' : 'list'
    }${params}`,

  toDashboard: ({
    accountSlug,
    runbookId,
    versionId,
    dashboardId,
    reactDashboard,
    params = ''
  }: {
    accountSlug: string
    runbookId: number
    versionId?: number
    dashboardId?: number
    reactDashboard?: boolean
    params?: string
  }) =>
    `/app/${accountSlug}/runbooks/${runbookId}/${versionId ? versionId : 'current_version'}/tasks/${
      reactDashboard ? 'react-dashboard' : 'dashboard'
    }/${dashboardId}${params}`,

  toPage: ({
    accountSlug,
    runbookId,
    versionId,
    dashboardId,
    reactRunbook,
    params = ''
  }: {
    accountSlug: string
    runbookId: number
    versionId?: number
    dashboardId?: number
    reactRunbook?: boolean
    params?: string
  }) =>
    `/app/${accountSlug}/runbooks/${runbookId}/${versionId ? versionId : 'current_version'}/${
      reactRunbook ? 'react-page' : 'page'
    }/content/${dashboardId}${params}`,

  toRunbookUsers: ({
    accountSlug,
    runbookId,
    versionId
  }: {
    accountSlug: string
    runbookId: number
    versionId?: number
  }) => `/app/${accountSlug}/runbooks/${runbookId}/${versionId ? versionId : 'current_version'}/users`
}
