import { Box, Icon, Text } from '@cutover/react-ui'
import { useLanguage } from 'main/services/hooks'
import { TaskListTask } from 'main/services/queries/types/tasks'

type TaskEditUnlinkContentProps = {
  resourceType: 'runbook' | 'template'
  displayType: 'linked_resource' | 'task'
  linkedTasks: TaskListTask[]
}

export const TaskEditUnlinkContent = ({ resourceType, displayType, linkedTasks }: TaskEditUnlinkContentProps) => {
  const { t } = useLanguage('tasks', { keyPrefix: 'editPanel.taskEditUnlinkContent' })

  const description = t('unlinkDescription', {
    count: linkedTasks.length,
    resourceType:
      resourceType === 'template'
        ? t('template', { count: linkedTasks.length })
        : t('runbook', { count: linkedTasks.length })
  })
  return (
    <Box>
      <Text css="padding: 16px 0">{description}</Text>
      {linkedTasks.map(task => {
        return (
          <Box key={task.id} direction="row">
            <Icon icon={resourceType} color="primary-1" />
            <Text css="padding: 0 8px">
              {displayType === 'linked_resource'
                ? task.linked_resource?.meta
                  ? task.linked_resource.meta.name
                  : ''
                : task.name}
            </Text>
          </Box>
        )
      })}
    </Box>
  )
}
