import { useCallback, useState } from 'react'
import parse from 'html-react-parser'
import styled from 'styled-components'

import {
  Badge,
  Box,
  Checkbox,
  colors,
  IconButton,
  Link,
  RadioboxList,
  resolveColor,
  Text,
  useTheme
} from '@cutover/react-ui'
import { FilterGroupExtraOptions } from './filter-group-extra-options'
import { getFilterSelections } from '../filter-helpers'
import { useFilters, useSetFilterParams } from '../filter-provider'
import {
  FilterGroupCheckbox as FilterGroupCheckboxType,
  FilterOptionCheckbox,
  FilterOptionSelect,
  SelectedFilter,
  SelectedFilters
} from '../filter-types'
import { useLanguage } from 'main/services/hooks'

export const FilterGroupCheckbox = ({
  filter,
  onChange,
  onReverse,
  selected = {},
  displayLimit,
  withAnyValueOption,
  withNoValueOption
}: {
  filter: FilterGroupCheckboxType
  onChange: (option: FilterOptionCheckbox | FilterOptionSelect) => void
  onReverse?: (option: FilterOptionCheckbox) => void
  selected?: SelectedFilters
  displayLimit?: number
  withNoValueOption?: boolean
  withAnyValueOption?: boolean
}) => {
  const theme = useTheme()
  const { t } = useLanguage('common', { keyPrefix: 'filter' })
  const [showAll, setShowAll] = useState(false)
  const shouldShortenOptions = displayLimit && displayLimit < filter.options.length && !showAll
  const filters = useFilters()
  const setFilterParams = useSetFilterParams()
  const isOptionSelected = useCallback((option: FilterOptionCheckbox, selectedOptions: SelectedFilter) => {
    if (Array.isArray(selectedOptions)) return !!selectedOptions.find(s => s === option.value)
    if (option.labelReversed) return [option.valueReversed, option.value].includes(selectedOptions as string)

    const { options } = option
    if (options && Array.isArray(options)) return options.map(o => o.value).includes(selectedOptions as string)
    return selectedOptions === option.value
  }, [])

  return (
    <Box>
      {filter.options.map((option, i) => {
        const key = `${filter.title}-${option.value}-${option.label}`
        const selectedOptions = getFilterSelections(selected, filter, option)
        const isSelected = isOptionSelected(option, selectedOptions)
        const { options } = option
        const hasOptions = options && Array.isArray(options)
        const selectedRadioOption = filters.filters[option.slug ?? '']
        if (shouldShortenOptions && i >= displayLimit) return null
        return (
          <Box key={key} justify="between" width="100%" direction="row" align={hasOptions ? 'start' : 'center'}>
            <Checkbox
              label={
                (option.labelReversed && selectedOptions === option.valueReversed
                  ? parse(option.labelReversed)
                  : parse(option.label)) as string
              }
              checked={isSelected}
              onChange={() => onChange({ ...option, options: undefined })}
              value={option.value}
            />

            <Box flex={{ shrink: 0 }}>
              {isSelected && hasOptions && (
                <Box>
                  <RadioboxList
                    name={option.slug}
                    direction="column"
                    defaultValue={String(selectedRadioOption) ?? 'day'}
                    value={selectedRadioOption}
                    onChange={e => setFilterParams({ [`${option.slug}`]: e.target.value })}
                    options={options}
                  />
                </Box>
              )}
              {option.labelReversed && isSelected && (
                <ReversedIcon
                  data-testid={`${option.label}-reverse-button`}
                  reversed={selectedOptions === option.valueReversed}
                  icon="swap"
                  size="small"
                  tipPlacement="top"
                  label={t('filterByOpposite')}
                  onClick={() => onReverse?.(option)}
                  color={selectedOptions === option.valueReversed ? resolveColor('primary', theme) : undefined}
                />
              )}
              {option.count !== undefined && option.color && (
                <Badge
                  textColor="white"
                  data-testid={`${option.label}-count`}
                  color={option.color}
                  label={option.count}
                  max={4}
                />
              )}
            </Box>
          </Box>
        )
      })}
      {(withAnyValueOption || withNoValueOption) && filter.hasOwnProperty('slug') && (
        <FilterGroupExtraOptions
          withAnyValueOption={withAnyValueOption}
          withNoValueOption={withNoValueOption}
          onChange={(_, option) => onChange(option)}
          selected={getFilterSelections(selected, filter)}
        />
      )}
      {shouldShortenOptions && (
        <Box width="100%" justify="center" align="center">
          <Link
            underline="none"
            colorHover={colors.primary}
            color={colors.primary}
            as="button"
            onClick={() => setShowAll(true)}
            size={16}
          >
            <Text>
              {t('showAll')}
              {` (${filter.options.length})`}
            </Text>
          </Link>
        </Box>
      )}
    </Box>
  )
}

const ReversedIcon = styled(IconButton)<{ reversed: boolean }>`
  transform: rotate(${({ reversed }) => (reversed ? 180 : 0)}deg);
  transition: transform 400ms cubic-bezier(0.35, 0, 0.25, 1);
`
