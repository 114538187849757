import { useMemo } from 'react'
import { pullAllWith } from 'lodash'

import { Box, Message, Text, useNotify } from '@cutover/react-ui'
import { TaskLineItem } from 'main/components/shared/task-line-item'
import { useRightPanelTypeState } from 'main/components/layout/right-panel'
import { useLanguage } from 'main/services/hooks'
import { deleteTasks } from 'main/services/queries/use-tasks'
import { FormModal } from 'main/components/shared/form'
import { RunbookTaskBulkDeleteResponse } from 'main/services/api/data-providers/runbook-types'
import { ActiveRunbookModel, ActiveRunbookVersionModel, TaskModel } from 'main/data-access'
import { StreamModel, TaskTypeModel } from 'main/data-access/models'

export const TasksDeleteModal = ({
  open,
  taskIds: initialTaskIds = [],
  onClose,
  onSuccess
}: {
  open: boolean
  taskIds: number[]
  onClose?: () => void
  onSuccess?: () => void
}) => {
  const notify = useNotify()
  const { t } = useLanguage('tasks', { keyPrefix: 'tasksDeleteModal' })

  const runbookId = ActiveRunbookModel.useId()
  const runbookVersionId = ActiveRunbookVersionModel.useId()

  const handleSuccess = TaskModel.useOnAction('bulk_delete')
  const getBulkDeletePermission = TaskModel.usePermissionCallbackSync('bulk_delete')
  const tasksLookup = TaskModel.useGetLookup()

  const [{ taskId: editPanelId }, { closeRightPanel }] = useRightPanelTypeState('task-edit')

  const tasks = initialTaskIds.map(id => tasksLookup[id]).filter(task => !!task) // important! task might be deleted in another session or window
  const undeletableTaskErrors = getBulkDeletePermission(initialTaskIds).errors
  const undeletableIds = Object.keys(undeletableTaskErrors).map(id => parseInt(id))
  const taskIdsToDelete = pullAllWith(
    tasks.map(({ id }) => id),
    undeletableIds
  )
  const taskIdsToDeleteIsEmpty = taskIdsToDelete.length === 0
  const sortedTaskList = useMemo(
    () => tasks.sort((a, b) => (a?.start_latest_planned || 0) - (b?.start_latest_planned || 0)),
    [tasks]
  )
  const tasksWithMultiplePredsAndSuccs = useMemo(
    () => tasks.filter(task => (task ? task.predecessor_ids.length > 1 && task?.successor_ids.length > 1 : false)),
    [tasks]
  )

  const hasLinkedTasks = tasks.some(task => task.linked_resource)

  const handleSubmit = async () => {
    return await deleteTasks(runbookId, runbookVersionId, taskIdsToDelete)
  }

  return (
    <FormModal
      formElementWrapper={false}
      onClose={onClose}
      onSuccess={(response: RunbookTaskBulkDeleteResponse) => {
        handleSuccess(response)
        notify.success(t('success', { count: taskIdsToDelete.length }))
        if (editPanelId && taskIdsToDelete.includes(editPanelId)) closeRightPanel()
        onSuccess?.()
      }}
      onSubmit={handleSubmit}
      open={open}
      title={t('title', { count: initialTaskIds.length })}
      confirmText={t('submit', { count: initialTaskIds.length })}
      confirmIcon="delete"
      customErrors={
        tasks.length === 0
          ? [t('tasksNotAvailable', { count: initialTaskIds.length })]
          : taskIdsToDeleteIsEmpty
          ? [t('noDeletableTasksWarning', { count: initialTaskIds.length })]
          : []
      }
      confirmDisabled={initialTaskIds.length === 0 || taskIdsToDeleteIsEmpty}
    >
      {tasks.length > 0 && (
        <Box gap="small" margin={{ bottom: 'small' }}>
          {undeletableIds.length > 0 && !taskIdsToDeleteIsEmpty && (
            <Message data-testid="task-delete-modal-undeletable-tasks-warning" type="warning">
              {t('undeletableTasksWarning', { count: undeletableIds.length })}
            </Message>
          )}
          <Text>
            {hasLinkedTasks
              ? t('descriptionLinkedTask', { count: initialTaskIds.length })
              : t('description', { count: initialTaskIds.length })}
          </Text>
          <Box>
            {sortedTaskList.map(
              task =>
                task && (
                  <TaskDeleteLineItemWrapper hasError={!!undeletableTaskErrors[task.id]} key={task.id} id={task.id} />
                )
            )}
          </Box>
          {tasksWithMultiplePredsAndSuccs.length > 0 && (
            <Message
              type="warning"
              message={t('multiplePredecessorAndSuccessorWarning', {
                count: tasksWithMultiplePredsAndSuccs.length,
                taskIds: tasksWithMultiplePredsAndSuccs.map(t => `#${t.internal_id}`)
              })}
            />
          )}
        </Box>
      )}
    </FormModal>
  )
}

const TaskDeleteLineItemWrapper = ({ hasError, id }: { id: number; hasError: boolean }) => {
  const task = TaskModel.useGet(id)
  const stream = StreamModel.useGet(task.stream_id)
  const taskType = TaskTypeModel.useGet(task.task_type_id)

  return (
    <TaskLineItem
      task={task}
      stream={stream}
      taskType={taskType}
      errors={hasError ? ['Unable to delete'] : undefined}
    />
  )
}
