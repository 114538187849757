import axios from 'axios'

const PORT_STRING = process.env.API_PORT ? `:${process.env.API_PORT}` : ''
const { protocol, hostname } = window.location

export const apiClient_UNSTABLE = axios.create({
  baseURL: `${protocol}//${hostname}${PORT_STRING}/api/`,
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
    'Access-Control-Allow-Origin': '*',
    Accept: 'application/json',
    'Cache-Control': 'no-store, max-age=0',
    ClientTime: new Date().getTime(),
    Account: '',
    'Request-Origin': 'react',
    browserHash: window.sessionStorage.getItem('browserHash') as string
  }
})
