import { useCallback } from 'react'
import * as yup from 'yup'
import { useFormContext } from 'react-hook-form'

import { Box, useNotify } from '@cutover/react-ui'
import { useLanguage } from 'main/services/hooks'
import { FormModal, RadioboxGroupField, TextAreaField, TextEditorField } from 'main/components/shared/form'
import {
  ActiveAccountModel,
  ActiveRunbookModel,
  ActiveRunbookVersionModel,
  ActiveRunModel,
  ConfigModel
} from 'main/data-access'
import {
  RecipientMultiselect,
  RecipientType,
  TeamRecipient,
  UserRecipient
} from 'main/components/recipients-multiselect'
import { AdHocCommsPayload, useSendAdHocComms } from 'main/services/queries/use-runbook-versions'

type AdHocCommsModalProps = {
  onClose?: () => void
}

const validationSchema = yup.object({
  commsType: yup.string().oneOf(['sms', 'email', 'call']),
  message: yup.string().when('commsType', {
    is: (commsType: string) => commsType === 'call',
    then: schema => schema.notRequired(),
    otherwise: schema => schema.required()
  }),
  recipients: yup
    .object()
    .shape({
      users: yup.array().of(yup.mixed()).notRequired(),
      runbook_teams: yup.array().of(yup.number().required()).notRequired()
    })
    .notRequired(),
  recipientTeamIds: yup.array().of(yup.number().required()).notRequired(),
  recipientUserIds: yup.array().of(yup.number().required()).notRequired()
})

export type AdHocCommsFormType = yup.InferType<typeof validationSchema>

export const AdHocCommsModal = ({ onClose }: AdHocCommsModalProps) => {
  const { t } = useLanguage('runbook', { keyPrefix: 'adHocCommsModal' })
  const runbookVersionId = ActiveRunbookVersionModel.useId()
  const runbookId = ActiveRunbookModel.useId()
  const accountId = ActiveAccountModel.useId()
  const run = ActiveRunModel.useGet()
  const isLiveRun = run?.run_type === 'live'
  const notify = useNotify()
  const { mutateAsync, isLoading } = useSendAdHocComms(runbookId, runbookVersionId)

  const handleSubmit = async (data: AdHocCommsPayload) => {
    return await mutateAsync(data)
  }

  // Note: there is no response data
  const handleSuccess = () => {
    notify.success(t('successText'))
    onClose?.()
  }

  return (
    <FormModal<AdHocCommsFormType, AdHocCommsPayload>
      open
      title={t('title')}
      confirmText={t('confirmText')} // how to condtionally changet this to 'call' since cant use watch when not within formContext
      confirmIcon={'send'}
      loadingText={t('confirmingText')}
      loading={isLoading}
      onSubmit={handleSubmit}
      onSuccess={handleSuccess}
      onClose={onClose}
      schema={validationSchema}
      transformer={dataTransformer}
      defaultValues={{
        commsType: isLiveRun ? 'sms' : 'email'
      }}
      preventAutoClose
    >
      <AdHocCommsModalInner
        isLiveRun={isLiveRun}
        accountId={accountId}
        runbookId={runbookId}
        runbookVersionId={runbookVersionId}
      />
    </FormModal>
  )
}

const dataTransformer = (data: AdHocCommsFormType): AdHocCommsPayload => {
  return {
    commsType: data.commsType as 'sms' | 'email' | 'call',
    message: data.commsType !== 'call' ? data.message : undefined,
    users: data.recipientUserIds ?? [],
    runbookTeams: data.recipientTeamIds ?? []
  }
}

const AdHocCommsModalInner = ({
  accountId,
  runbookId,
  runbookVersionId,
  isLiveRun
}: {
  accountId: number
  runbookId: number
  runbookVersionId: number
  isLiveRun: boolean
}) => {
  const { t } = useLanguage('runbook', { keyPrefix: 'adHocCommsModal' })
  const { taskTypeSms: isEnabledSms, taskTypeCall: isEnabledCall } = ConfigModel.useGet()
  const { watch, setValue } = useFormContext()
  const commsType = watch('commsType')

  // Note: isEnabledSms etc more or less follows angular behaviour. Leaving email as always enabled
  // otherwise this modal can't be used. I think this is due to the comms settings being turned off at instance level sometimes
  const commsOptions: { label: string; value: 'sms' | 'email' | 'call'; disabled?: boolean }[] = [
    { label: t('commsType.sms'), value: 'sms', disabled: !isLiveRun || !isEnabledSms },
    { label: t('commsType.email'), value: 'email' },
    { label: t('commsType.call'), value: 'call', disabled: !isLiveRun || !isEnabledCall }
  ]

  const handleRecipientsChange = useCallback((recipients: RecipientType[] | null) => {
    const users = (recipients || []).filter(recipient => recipient.type === 'user') as UserRecipient[]
    const teams = (recipients || []).filter(recipient => recipient.type === 'team') as TeamRecipient[]
    setValue('recipientUserIds', users.map(user => user.id).sort())
    setValue('recipientTeamIds', teams.map(team => team.id).sort())
  }, [])

  return (
    <Box direction="column">
      <RecipientMultiselect
        initialSelected={[]}
        accountId={accountId}
        runbookId={runbookId}
        runbookVersionId={runbookVersionId}
        label={t('recipients')}
        onChange={recipients => handleRecipientsChange(recipients)}
      />
      <RadioboxGroupField<AdHocCommsFormType>
        name="commsType"
        label={isLiveRun ? t('commsType.label') : t('commsType.labelPlanning')}
        direction="row"
        options={commsOptions}
      />
      {commsType === 'email' ? (
        <TextEditorField<AdHocCommsFormType> name="message" label={t('message')} />
      ) : commsType === 'sms' ? (
        <TextAreaField<AdHocCommsFormType> name="message" label={t('message')} />
      ) : null}
    </Box>
  )
}
