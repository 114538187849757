import { useState } from 'react'

import { ReactAngularConnector } from 'main/connectors/react-angular-connector'
import { AiSuggestionsPanelContent } from 'main/components/runbook/right-panels/ai-suggestions/ai-suggestions-panel'

type AiSuggestionsState = {
  runbookId: number | null
}

export const AiSuggestionsPanelConnector = () => {
  const [{ runbookId }, setState] = useState<AiSuggestionsState>({
    runbookId: null
  })

  const handleOpenPanel = ({ runbookId }: AiSuggestionsState) => {
    setState({ runbookId })
  }

  const handleClosePanel = () => {
    setState({ runbookId: null })
  }

  return (
    <ReactAngularConnector
      mountEvent="open-ai-suggestions-panel"
      unmountEvent="close-ai-suggestions-panel"
      onMount={handleOpenPanel}
      onUnmount={handleClosePanel}
    >
      {runbookId && <AiSuggestionsPanelContent runbookId={runbookId} />}
    </ReactAngularConnector>
  )
}
