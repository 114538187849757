import { useLayoutEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { useParams } from 'react-router-dom'

import { useInitialMount } from '@cutover/react-ui'
import { RadioboxGroupField, RunbookSelectField } from 'main/components/shared/form'
import { useLanguage } from 'main/services/hooks'
import { MergeModalValidateMergeFormType } from '../merge-runbooks-modal-types'
import { useMergeRunbooksContext } from '../merge-runbooks-modal-provider'
import { ServerParams } from 'main/services/queries/use-runbooks'

const DEFAULT_FILTER_PARAMS = {
  limit: 10,
  a: true,
  fields: ['id', 'name']
}
export const StepChooseMergeType = ({
  canCreateRunbooks,
  runbooksFilterParams = {}
}: {
  canCreateRunbooks?: boolean
  runbooksFilterParams?: Partial<ServerParams>
}) => {
  const { t } = useLanguage('runbooks', { keyPrefix: 'mergeModal' })
  const { watch, formState, reset: resetValidateMergeForm } = useFormContext<MergeModalValidateMergeFormType>()
  const { accountId: accountSlug } = useParams()
  const { setMergeType } = useMergeRunbooksContext()
  const formTargetType = watch('target_type')
  const isInitialMount = useInitialMount()
  const { defaultValues } = formState

  useLayoutEffect(() => {
    if (!isInitialMount && formTargetType === 'new') {
      resetValidateMergeForm(
        { ...defaultValues, target_type: 'new', target_runbook: undefined },
        { keepErrors: false, keepValues: false }
      )
    }

    setMergeType(formTargetType)
  }, [formTargetType])

  return (
    <>
      <RadioboxGroupField<MergeModalValidateMergeFormType>
        name="target_type"
        label={t('fields.createType.label')}
        direction="row"
        options={[
          { label: t('fields.createType.new'), value: 'new', disabled: !canCreateRunbooks },
          { label: t('fields.createType.choose'), value: 'existing' }
        ]}
      />
      {accountSlug && formTargetType === 'existing' && (
        <RunbookSelectField<MergeModalValidateMergeFormType>
          name="target_runbook"
          label={t('fields.runbook.label')}
          accountSlug={accountSlug}
          params={{
            ...DEFAULT_FILTER_PARAMS,
            ...runbooksFilterParams
          }}
          placeholder={t('fields.runbook.placeholder')}
        />
      )}
    </>
  )
}
