import { format, utcToZonedTime } from 'date-fns-tz'

import { useAccountCustomFieldLookups } from 'main/services/api/data-providers/account/account-data'
import { CustomField, CustomFieldUser, FieldOption, FieldValue } from 'main/services/queries/types'
import { CustomFieldModel, CustomFieldUserModel, FieldOptionModel } from 'main/data-access'

export type CustomFieldPillLabelType = {
  label: string
  color?: string
  fieldLabel?: string
  value?: string
}

export const useTaskListCustomFieldPillLabels = ({
  fieldValues,
  timezone
}: {
  fieldValues: FieldValue[] | null
  timezone: string | null
}) => {
  const fieldOptionLookup = FieldOptionModel.useGetLookup()
  const customFieldsLookup = CustomFieldModel.useGetLookup()
  const cfUserLookup = CustomFieldUserModel.useGetLookup()

  const lookups = {
    fieldOptionLookup,
    customFieldsLookup,
    customFieldUserLookup: cfUserLookup
  }

  return useCustomFieldPillLabels({
    fieldValues,
    timezone,
    lookups
  })
}

export const useRunbokListCustomFieldPillLabels = ({
  fieldValues,
  timezone
}: {
  fieldValues: FieldValue[]
  timezone: string | null
}) => {
  const lookups = useAccountCustomFieldLookups()
  return useCustomFieldPillLabels({
    fieldValues,
    timezone,
    lookups
  })
}

const useCustomFieldPillLabels = ({
  fieldValues,
  timezone,
  lookups
}: {
  fieldValues: FieldValue[] | null
  timezone: string | null
  lookups: {
    fieldOptionLookup: Record<number, FieldOption> | undefined
    customFieldsLookup: Record<number, CustomField> | undefined
    customFieldUserLookup: Record<number, CustomFieldUser> | undefined
  }
}) => {
  const { fieldOptionLookup, customFieldsLookup, customFieldUserLookup } = lookups

  const customFieldPillLabels: CustomFieldPillLabelType[] = []

  const handlePrimaryOrRadioOrSelect = ({
    fieldValue,
    customField
  }: {
    fieldValue: FieldValue
    customField: CustomField
  }) => {
    if (fieldValue.field_option_id) {
      const fieldOption = fieldOptionLookup?.[fieldValue.field_option_id]
      return fieldOption
        ? {
            label: `${customField.display_name}: ${fieldOption.name}`,
            fieldLabel: customField.display_name,
            value: fieldOption.name,
            color: fieldOption.color || undefined
          }
        : null
    } else {
      return null
    }
  }

  const fieldValueToLabelData = (
    field_value: FieldValue
  ): { label: string; fieldLabel?: string; value?: string; color?: string } | null => {
    const customField = customFieldsLookup?.[field_value.custom_field_id]
    if (!customField?.archived && customField?.display_list) {
      if (customField.is_primary && customField.parent_field_id !== null) {
        return handlePrimaryOrRadioOrSelect({ fieldValue: field_value, customField })
      } else {
        switch (customField.field_type.slug) {
          case 'radiobox':
          case 'select_menu':
            return handlePrimaryOrRadioOrSelect({ fieldValue: field_value, customField })
          case 'checkboxes':
            if (!field_value.value) return null

            const valueArray = JSON.parse(field_value.value)
            if (!valueArray.length) return null

            const fieldOption = fieldOptionLookup?.[valueArray[0]] // display first one
            if (!fieldOption) return null

            const isSingleValue = valueArray.length === 1
            const displayName = customField.display_name
            const label = `${displayName}: ${fieldOption.name}${isSingleValue ? '' : ` +${valueArray.length - 1} more`}`
            const color = fieldOption.color || undefined
            const fullValue = isSingleValue
              ? fieldOption.name
              : valueArray.map((id: number) => fieldOptionLookup?.[id]?.name).join(', ')

            return { label, color, fieldLabel: displayName, value: fullValue }
          case 'text':
            if (field_value.value) {
              return {
                label: `${customField.display_name}: ${field_value.value}`,
                fieldLabel: customField.display_name,
                value: field_value.value
              }
            }
          case 'searchable':
          case 'multi_searchable':
            const value = field_value.computed_value ?? field_value.value
            if (value) {
              return {
                label: `${customField.display_name}: ${value}`,
                fieldLabel: customField.display_name,
                value
              }
            }
          case 'datetime': {
            if (field_value.value) {
              const date = format(
                timezone ? utcToZonedTime(new Date(field_value.value), timezone) : new Date(field_value.value),
                'EEE, dd LLL yyyy'
              )
              const label = `${customField.display_name}: ${date}`
              return { label, fieldLabel: customField.display_name, value: date }
            }
          }
          case 'user_select': {
            if (field_value.value) {
              const valueArray = JSON.parse(field_value.value)
              if (valueArray.length) {
                const firstUser = customFieldUserLookup?.[valueArray[0]]
                // FIXME: this is a hack to work around that we don't have in the data store any new custom field users that have been added when editing a runbook until a new
                // fetch of the account data because it only holds a collection of users that exist on custom field selects on any runbook in the account at the time the account loads.
                // We could optimistically update the account store's custom field users on mutate for the runbook edit.
                if (!firstUser) return null
                if (valueArray.length === 1) {
                  const label = `${customField.display_name}: ${firstUser.name}`
                  return { label, fieldLabel: customField.display_name, value: firstUser.name }
                } else {
                  const value = `${firstUser.name} +${valueArray.length - 1} more`
                  const label = `${customField.display_name}: ${value}`
                  return { label, fieldLabel: customField.display_name, value: value }
                }
              }
            }
          }
          case 'endpoint':
          case 'textarea':
        }
        return null
      }
    }
    return null
  }

  if (!fieldOptionLookup || !customFieldsLookup || !customFieldUserLookup) {
    return { customFieldPillLabels, fieldValueToLabelData }
  }

  fieldValues?.forEach(field_value => {
    const labelData = fieldValueToLabelData(field_value)
    if (labelData) {
      customFieldPillLabels.push(labelData)
    }
  })

  return { customFieldPillLabels, fieldValueToLabelData }
}
