import { useMemo } from 'react'

import { Avatar, Box, ColumnConfig, NoResourceFound, Table } from '@cutover/react-ui'
import { RunbookUser, useRunbookVersionAllUsers } from 'main/services/queries/use-runbook-versions'
import { ActiveRunbookModel, ActiveRunbookVersionModel } from 'main/data-access'
import { useAppliedFilters, useClearFilterState } from 'main/recoil/data-access'
import { useCloseRightPanelOnMount } from 'main/components/layout/right-panel'
import { UsersPageSubHeader } from './users-sub-header'
import { useLanguage } from 'main/services/hooks'

export const Users = () => {
  const { t } = useLanguage('runbookUsers', { keyPrefix: 'list.tableHeadings' })
  const runbookId = ActiveRunbookModel.useId()
  const runbookVersionId = ActiveRunbookVersionModel.useId()
  const filters = useAppliedFilters()

  const { data, fetchNextPage, isFetchingNextPage, isLoading } = useRunbookVersionAllUsers({
    runbookId,
    runbookVersionId,
    params: filters
  })

  useCloseRightPanelOnMount()

  const clearFilterSearch = useClearFilterState('q')

  const runbookUsers = useMemo(() => data?.pages.flatMap(page => page.runbook_users), [data?.pages])

  const columns: ColumnConfig<RunbookUser>[] = useMemo(
    () => [
      {
        property: 'avatar',
        header: '',
        render: datum => <Avatar subject={datum} size="small" />,
        size: '41px',
        sortable: false
      },
      {
        property: 'first_name',
        header: t('firstName'),
        size: '180px',
        sortable: true
      },
      {
        property: 'last_name',
        header: t('lastName'),
        size: '180px',
        sortable: true
      },
      {
        property: 'email',
        header: t('email'),
        size: '300px',
        sortable: true
      },
      {
        property: 'mobile_number_normalized',
        header: t('mobileNumber'),
        size: '200px',
        sortable: true
      },
      {
        property: 'streams',
        header: t('streamEditor'),
        size: '200px',
        sortable: false
      },
      {
        property: 'runbook_teams',
        header: t('assignedTeams'),
        size: '200px',
        sortable: false
      },
      {
        property: 'runbook_admin',
        header: t('admin'),
        render: (datum: RunbookUser) => booleanToString(datum.runbook_admin),
        sortable: true
      },
      {
        property: 'in_account',
        header: t('inAccount'),
        render: (datum: RunbookUser) => booleanToString(datum.in_account),
        sortable: true
      },
      {
        property: 'registered',
        header: t('registered'),
        render: (datum: RunbookUser) => booleanToString(datum.in_account),
        sortable: true
      },
      {
        property: 'pref_email',
        header: t('acceptEmail'),
        render: (datum: RunbookUser) => booleanToString(datum.pref_email),
        sortable: true
      },
      {
        property: 'pref_sms',
        header: t('acceptSMS'),
        render: (datum: RunbookUser) => booleanToString(datum.pref_sms),
        sortable: true
      }
    ],
    []
  )

  const booleanToString = (value: boolean) => (value ? t('yes') : t('no'))

  return (
    <UsersPageSubHeader usersCount={data?.pages[0].meta.total_filtered_count}>
      <Box pad={{ right: '16px' }} id="tabpanel-runbook-version-users" aria-labelledby="tab-runbook-users">
        {!isLoading && runbookUsers?.length === 0 ? (
          <NoResourceFound context="runbook user" clearAllFilters={clearFilterSearch} />
        ) : (
          <Table
            replace
            columns={columns}
            data={runbookUsers ?? undefined}
            onMore={fetchNextPage}
            sortable="client"
            isLoadingMore={isFetchingNextPage}
            isLoading={isLoading}
          />
        )}
      </Box>
    </UsersPageSubHeader>
  )
}
