import { Dispatch, SetStateAction } from 'react'

import { queryClient } from 'main/query-client'
import { QueryKeys } from 'main/services/queries/query-keys'
import { FormModal } from 'main/components/shared/form'
import { useRunbookTypeCreateMutation, useRunbookTypesQuery } from '../use-runbook-type'
import { ApprovalFlowType } from 'main/services/queries/types'
import { ConfigModel } from 'main/data-access'
import { RunbookTypeFormDetails } from '../runbook-type-form-details'
import { buildRunbookTypeSchema, RunbookTypesSchema } from '../schemas/runbook-types-schema'
import { useLanguage } from 'main/services/hooks'

type CreaterRunbookTypeModalType = {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
}

const randomElement = <T,>(array: T[]): T => array[Math.floor(Math.random() * array.length)]

export const CreateRunbookTypeModal = ({ open, setOpen }: CreaterRunbookTypeModalType) => {
  const { t } = useLanguage('runbookTypes')
  const createRunbookType = useRunbookTypeCreateMutation().mutateAsync
  const { data } = useRunbookTypesQuery()
  const approvalFlows = data?.meta?.available_approval_flows as ApprovalFlowType[]
  const { runbookTypeIcons, runbookTypeColors } = ConfigModel.useGet()

  const defaultValues = {
    global: 'true',
    icon_name: randomElement(runbookTypeIcons),
    icon_color: randomElement(runbookTypeColors)
  }

  return (
    <FormModal<RunbookTypesSchema>
      title={t('addSection.title')}
      confirmText={t('addSection.createButtonText')}
      confirmIcon={'add'}
      open={open}
      onClose={() => setOpen(false)}
      onSubmit={createRunbookType}
      schema={buildRunbookTypeSchema()}
      defaultValues={defaultValues}
      successMessage={t('toasters.add.notification.message')}
      onSuccess={() => queryClient.invalidateQueries([QueryKeys.RunbookTypes])}
      errorMessage={t('toasters.add.notification.failed')}
    >
      <RunbookTypeFormDetails mode="create" approvalFlows={approvalFlows} />
    </FormModal>
  )
}
