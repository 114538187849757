import { forwardRef, MouseEventHandler, ReactNode } from 'react'
import { Placement } from '@popperjs/core'
import { CSS } from '@dnd-kit/utilities'
import { useSortable } from '@dnd-kit/sortable'
import { LiteralUnion } from 'type-fest'

import { themeColor } from '../../theme'
import { ColorProp } from '../../theme/color'
import { Box, BoxProps } from '../../layout'
import { Icon } from '../../icon'
import { MenuListItemProps } from '../../menu'
import { ListItemInner } from './list-item-inner'

export type ListItemProps = {
  /** Sets the base size of the list item, S/M/L corresponds to 32, 40 and 56px overall heights */
  size: 'small' | 'medium' | 'large'
  /** Marks the list item as being expandable and shows an expand/collapse toggle, also requires 'expandableContent' */
  expandable?: boolean
  /** Should contain only text nodes, 13px size and 'text-light' color */
  expandableContent?: ReactNode
  /** Use this to pass an icon, image or avatar. Size must match the list item size, 24, 32 or 40 for S/M/L */
  startComponent?: ReactNode
  /** Use this to pass an array of end components to display on the right. Note: generally avoid more than 2 */
  endComponents?: ReactNode[] | ReactNode
  /** Passing an array of menuOptions will add a 'more' 3 dot dropdown menu to the right of the ListItem */
  menuOptions?: MenuListItemProps[]
  /** Passing an onClickRemove function will add an X icon on the right of the ListItem */
  onClickRemove?: () => void
  removeTooltip?: string
  removeTooltipPlacement?: Placement
  onClick?: (e: any) => void
  onMouseDown?: MouseEventHandler
  /** Sets a custom aria-label attribute */
  a11yTitle?: string
  className?: string
  /** Marks the list item as 'selected', eg the selected item in a Select dropdown */
  selected?: boolean
  /** Greys out the ListItem and disables all interactivity */
  disabled?: boolean
  /** Use this to mark the item as 'active', giving it a grey background. Eg when keyboard-navigating a list */
  active?: boolean
  /** Adds a right pointing chevron on the right of the ListItem to indicate clicking goes to the next step */
  hasNext?: boolean
  /** Adds a 1px border. Only used for cards */
  border?: boolean
  /** Defaults to 100% of its container */
  width?: BoxProps['width']
  /** Optionally add an indent, for example with a hierarchy select like streams or folders */
  level?: number
  /** Forces a bold, dark title when 'high'. Prominence="low" is used instead of disabled, when row remains clickable */
  prominence?: 'high' | 'default' | 'low'
  tabIndex?: number
  'data-testid'?: string
  /** Sets the aria role */
  role?: string
  /** Sets the main title of the list item */
  title?: string
  /** Appends a less important suffix to the title, in lighter text */
  titleSuffix?: string
  suffixColor?: LiteralUnion<ColorProp, string>
  /** Shows a second line of information in small, lighter text. Does not show when size is small */
  subTitle?: string
  /** Controls the expanded state of the list item. */
  open?: boolean
  /** Sets the initial expanded state of the list item. */
  initiallyOpen?: boolean
  /** Displays the text in red */
  hasError?: boolean
  /** @deprecated Only used for allowing additional startComponent with for checkbox hierarchy items */
  hasCheckboxExpandToggle?: boolean
}

type ListItemBaseProps = {
  sortable?: boolean
  id?: string | number
} & ListItemProps

// Note: needed to add a forwardRef here to get the keyboard nav working on the task-item-create-menu
export const ListItem = forwardRef<HTMLDivElement, ListItemBaseProps>(
  ({ sortable, id, ...props }: ListItemBaseProps, ref) => {
    if (sortable && id) {
      return <SortableListItemInner id={id} {...props} />
    } else {
      return <ListItemInner ref={ref} {...props} />
    }
  }
)

const SortableListItemInner = ({ id, ...props }: { id: string | number } & ListItemProps) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id })

  const styles = {
    transform: CSS.Transform.toString(transform),
    transition
  }

  const sortableProps = {
    attributes,
    listeners
  }

  const sortHandle = (
    <Box
      {...sortableProps.attributes}
      {...sortableProps.listeners}
      css={`
        outline: none;
        cursor: grab;
        &:hover svg {
          fill: ${themeColor('text')} !important;
        }
      `}
    >
      <Icon icon="drag" size={props.size} color="text-light" />
    </Box>
  )

  return <ListItemInner css={styles} sortHandle={sortHandle} ref={setNodeRef} {...props} />
}
