import { ReactNode, useEffect, useRef } from 'react'

import { Box, IconButton, Menu, MenuListItem, MenuListItemProps } from '@cutover/react-ui'
import { SearchType, SubHeaderResultsText, SubHeaderSearch } from 'main/components/layout/shared/sub-header'
import { ActiveRunbookModel, ActiveRunbookVersionModel, RunbookViewModel } from 'main/data-access'
import { useAppliedFilters, useClearFilterState, useSetFilterState } from 'main/recoil/data-access'
import { useLanguage } from 'main/services/hooks'
import { useRunbookSubHeader } from '../../runbook-sub-header/use-runbook-sub-header'
import { useRunbookVersionAllUsers } from 'main/services/queries/use-runbook-versions'

export const UsersPageSubHeader = ({ children, usersCount }: { children: ReactNode; usersCount?: number }) => {
  const { addSubHeaderContent, resetSubHeaderContent } = useRunbookSubHeader()

  useEffect(() => {
    addSubHeaderContent({
      left: <UsersPageSubHeaderLeft usersCount={usersCount} />,
      right: <UsersPageSubHeaderRight />
    })

    return resetSubHeaderContent
  }, [usersCount])

  return <>{children}</>
}

const UsersPageSubHeaderLeft = ({ usersCount }: { usersCount?: number }) => {
  const { t } = useLanguage('runbookUsers')
  const clearFilterSearch = useClearFilterState('q')
  const setSearchFilter = useSetFilterState('q')
  const searchRef = useRef<SearchType>(null)
  const filters = useAppliedFilters()
  const { q } = filters

  const handleSearch = (input?: string) => {
    if (input) {
      setSearchFilter(input)
    } else {
      clearFilterSearch('q')
    }
  }

  useEffect(() => {
    if (!q) {
      searchRef.current?.clear()
    }
  }, [q])

  return (
    <>
      <Box flex={false} gap="xsmall" align="center" direction="row">
        <SubHeaderSearch a11yTitle={t('filters.search.content')} onSearch={handleSearch} ref={searchRef} />
      </Box>
      {usersCount ? <SubHeaderResultsText value={t('filters.search.users', { count: usersCount })} /> : null}
    </>
  )
}

const UsersPageSubHeaderRight = () => {
  const { t } = useLanguage('common')
  const openModal = RunbookViewModel.useAction('modal:open')
  const runbookId = ActiveRunbookModel.useId()
  const runbookVersionId = ActiveRunbookVersionModel.useId()
  const { name: runbookName } = ActiveRunbookModel.useGet()
  const filters = useAppliedFilters()

  const { refetch } = useRunbookVersionAllUsers({
    runbookId,
    runbookVersionId,
    params: filters
  })

  const menuItems: MenuListItemProps[] = [
    {
      icon: 'download' as const,
      label: t('exportUsersButton.title'),
      onClick: () => {
        openModal({ type: 'runbook-users-export', data: { runbookId, runbookVersionId, runbookName } })
      }
    },
    { icon: 'refresh' as const, label: t('reloadButton'), onClick: refetch }
  ].filter(Boolean) as MenuListItemProps[]

  return (
    <Menu trigger={<IconButton tertiary label={t('moreOptions')} icon="more-vertical" />}>
      {menuItems.map(item => (
        <MenuListItem icon={item.icon} label={item.label} key={item.label} onClick={item.onClick} />
      ))}
    </Menu>
  )
}
