import { cloneElement, ReactElement } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { IconButton, Menu, MenuListItem, useToggleFilterPanel } from '@cutover/react-ui'
import {
  ActiveAccountModel,
  ActiveRunbookModel,
  ActiveRunbookVersionModel,
  ActiveRunModel,
  CurrentRunbookVersionModel
} from 'main/data-access'
import { useLanguage } from 'main/services/hooks'
import { ModalActiveType } from 'main/recoil/runbook'
import { routes } from 'main/services/routing'

export const RunbookSettingsMenu = ({
  onClickOption
}: {
  onClickOption: (option: ModalActiveType['type']) => void
}) => {
  const { t } = useLanguage('runbook', { keyPrefix: 'runbookSettingsMenu' })

  /* ------------------------------- Permissions ------------------------------ */
  // Note: All runbook permissions will be wrong unless runbook is reloaded or permission updated via sockets
  // This is relevant since in many cases we only reload the version/tasks and not the runbook (for performance reasons)
  // For example if runbook is archiveable then the template status changes without the runbook itself being reloaded
  const canUpdate = ActiveRunbookModel.useCan('update')
  const canArchive = ActiveRunbookModel.useCan('archive')
  const canDuplicate = ActiveRunbookModel.useCan('duplicate')
  const canImport = ActiveRunbookVersionModel.useCan('import')
  const canCreateVersion = ActiveRunbookVersionModel.useCan('create')
  const canDeleteVersion = ActiveRunbookVersionModel.useCan('destroy')

  /* ------------------------------- Properties ------------------------------- */
  const { template_type: templateType, linked_runbook_details: linkedRunbookDetails } = ActiveRunbookModel.useGet()

  const run = ActiveRunModel.useGet()
  const {
    is_current: isCurrent,
    template_version: templateVersion,
    approval_status: approvalStatus
  } = ActiveRunbookVersionModel.useGet()
  const { start_scheduled: startScheduled } = CurrentRunbookVersionModel.useGet()

  // Runbook type properties
  const { disabled: isRunbookTypeDisabled, restrict_create_to_templates: isRestrictCreateToTemplate } =
    ActiveRunbookModel.useRunbookType()

  /* --------------------------- Helper properties -------------------------- */
  const runType = run?.run_type
  const isTemplate = templateType === 'default'
  const isSnippet = templateType === 'snippet'
  const isRunbook = templateType === 'off'
  const isRehearsal = runType === 'rehearsal'
  const isLiveRun = runType === 'live'
  const resourceType = isSnippet ? 'Snippet' : isTemplate ? 'Template' : 'Runbook'
  // const isPaused = runbookStage === 'paused'
  const isPlanning = !isRehearsal && !isLiveRun
  const isLinkedRunbookChild = linkedRunbookDetails && !!linkedRunbookDetails.id
  // Check that it's not the last template version
  const hasTemplateVersions = templateVersion && templateVersion > 1
  const isDraftTemplate = isTemplate && approvalStatus === 'pending'

  /* ----------------------- Show/hide option conditions ---------------------- */
  const { can: showMakeTemplateOption } = ActiveRunbookVersionModel.usePermission('convert_to_template')
  const showDuplicateOption =
    !isRunbookTypeDisabled && canDuplicate && isCurrent && (isRestrictCreateToTemplate ? isTemplate : true)
  const showAddScheduledStart = canUpdate && isPlanning && (!startScheduled || isLinkedRunbookChild) && isRunbook
  const showRemoveScheduledStart = canUpdate && isPlanning && startScheduled && !isLinkedRunbookChild
  const showMergeRunbookOption = !isSnippet && canImport
  const showCreateVersionOption = canCreateVersion && isCurrent

  const navigate = useNavigate()
  const { slug: accountSlug } = ActiveAccountModel.useGet()
  const { runbookId, runbookVersionId } = useParams()
  const toggleFilterPanel = useToggleFilterPanel()

  const openUsers = () => {
    toggleFilterPanel(false)
    navigate(
      routes.toRunbookUsers({
        accountSlug: accountSlug,
        runbookId: Number(runbookId),
        versionId: Number(runbookVersionId)
      })
    )
  }

  /* --------- Options (array of arrays to represent grouped options) --------- */
  const groupedOptions = [
    [
      showMakeTemplateOption && (
        <MenuListItem
          a11yTitle={t('options.makeTemplate')}
          label={t('options.makeTemplate')}
          icon="template"
          onClick={() => onClickOption('runbook-make-template')}
          data-testid="menu-item-settings-make-template"
        />
      ),
      showDuplicateOption && (
        <MenuListItem
          label={
            isTemplate
              ? t('options.createRunbookFromTemplate')
              : t('options.duplicate', { resourceType: resourceType.toLowerCase() })
          }
          a11yTitle={
            isTemplate
              ? t('options.createRunbookFromTemplate')
              : t('options.duplicate', { resourceType: resourceType.toLowerCase() })
          }
          icon="copy"
          onClick={() => onClickOption('runbook-duplicate')}
          data-testid="menu-item-settings-duplicate"
        />
      )
    ].filter(Boolean),
    [
      showMergeRunbookOption && (
        <MenuListItem
          label={t('options.mergeRunbook')}
          icon="merge"
          onClick={() => onClickOption('runbook-merge')}
          data-testid="menu-item-merge-runbook"
        />
      ),
      canImport && (
        <MenuListItem
          label={t('options.importTasks')}
          icon="upload"
          onClick={() => onClickOption('tasks-csv-import')}
          data-testid="menu-item-settings-import"
        />
      )
    ].filter(Boolean),
    [
      showCreateVersionOption && (
        <MenuListItem
          label={t('options.createVersion')}
          icon="task-started"
          data-testid="menu-item-create-version"
          onClick={() => onClickOption('version-create')}
        />
      ),
      canDeleteVersion && isTemplate && hasTemplateVersions && isDraftTemplate && (
        <MenuListItem
          label={t('options.deleteDraftVersion')}
          icon="delete"
          onClick={() => onClickOption('template-draft-version-delete')}
        />
      ),
      <MenuListItem
        label={t('options.viewVersion')}
        icon="history"
        onClick={() => onClickOption('runbook-view-history')}
        data-testid="menu-item-settings-view-history"
      />
    ].filter(Boolean),
    [
      canUpdate && (
        <MenuListItem
          label={t('options.users', { resourceType: resourceType })}
          icon="user-list"
          onClick={openUsers}
          data-testid="menu-item-settings-users"
        />
      ),
      <MenuListItem
        label={t('options.auditLog')}
        icon="page-text"
        onClick={() => onClickOption('audit-log-download')}
        data-testid="menu-item-settings-download-audit-log"
      />
    ].filter(Boolean),
    [
      showAddScheduledStart && (
        <MenuListItem
          label={isLinkedRunbookChild ? t('options.scheduledStart') : t('options.addScheduledStart')}
          icon="time"
          onClick={() => onClickOption('runbook-schedule')}
          data-testid="menu-item-settings-scheduled-start"
        />
      ),
      showRemoveScheduledStart && (
        <MenuListItem
          label={t('options.removeScheduledStart')}
          icon="time"
          onClick={() => onClickOption('runbook-schedule')}
          data-testid="menu-item-settings-remove-scheduled-start"
        />
      )
    ].filter(Boolean),
    [
      <MenuListItem
        label={t('options.saveAsSavedView')}
        icon="save"
        onClick={() => onClickOption('runbook-save-as-saved-view')}
        data-testid="menu-item-settings-save-as-saved-view"
      />
    ],
    [
      canArchive && (
        <MenuListItem
          label={t('options.archive')}
          icon="archive"
          onClick={() => onClickOption('runbook-archive')}
          data-testid="menu-item-settings-archive"
          destructive
        />
      )
    ].filter(Boolean)
  ]

  return (
    <>
      <Menu
        align="start"
        menuStyle={{ width: '320px' }}
        data-testid="runbook-settings-menu"
        trigger={
          <IconButton
            icon="settings"
            tertiary
            label={t('iconLabel')}
            tipPlacement="bottom"
            size="medium"
            disableTooltip
            onClick={e => e.stopPropagation()}
          />
        }
      >
        {groupedOptions.map((group, groupIndex) =>
          group.map((element, elementIndex) =>
            // casting because linting doesn't respond to filter(Boolean)
            cloneElement(element as ReactElement, {
              key: `${groupIndex}-${elementIndex}`,
              // Append divider if the option is the last in its group but not in the last group
              appendDivider:
                !!groupedOptions[groupIndex + 1] &&
                groupedOptions[groupIndex + 1].length !== 0 &&
                !group[elementIndex + 1]
            })
          )
        )}
      </Menu>
    </>
  )
}

/* -------------------------------------------------------------------------- */
/*          OLD CODE, PLEASE DELETE WHEN OPTION HAS BEEN IMPLEMENTED          */
/* -------------------------------------------------------------------------- */

// {/* {canArchive && !isPaused && (
//       <MenuListItem label={t('options.archive')} icon="archive" onClick={() => console.log('Duplicate!')} />
//     )} */}
//   {/* For portable runbooks */}
//   {/* {isTemplate && (
//       <MenuListItem
//         label={t('options.downloadTemplate')}
//         icon="download"
//         onClick={() => console.log('With portable runbooks enabled!')}
//       />
//     )} */}

//   {/* TODO see https://cutover.atlassian.net/browse/CFE-1372 */}
//   {/* <VersionOptions
//       isTemplate={isTemplate}
//       hasTemplateVersions={hasTemplateVersions}
//       isCurrent={isCurrent}
//       resourceType={resourceType}
//       canCreate={canCreate}
//       canDelete={canDelete}
//     /> */}
//   {/* <MenuListItem label={t('options.reload')} icon="refresh" onClick={() => console.log('reload!')} /> */}

// TODO see https://cutover.atlassian.net/browse/CFE-1372
// const VersionOptions = ({ isTemplate, hasTemplateVersions, isCurrent, canCreate, canDelete }: VersionOptionsProps) => {
//   const { t } = useLanguage('runbook', { keyPrefix: 'runbookSettingsMenu' })
//   return (
//     <>
//       {canCreate &&
//         (isTemplate ? (
//           <MenuListItem
//             label={t('options.newTemplateVersion')}
//             icon="save"
//             onClick={() => console.log('New template!')}
//           />
//         ) : (
//           <MenuListItem
//             label={t('options.restoreVersion')}
//             icon="save"
//             onClick={() => console.log('Restoring this version')}
//           />
//         ))}
//     </>
//   )
// }
