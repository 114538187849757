import * as yup from 'yup'

import { Message } from '@cutover/react-ui'
import { FormModal, TimezoneSelectField } from 'main/components/shared/form'
import { useLanguage } from 'main/services/hooks'
import { ActiveRunbookModel, RunbookViewModel } from 'main/data-access'

type SelectedTimezoneModalProps = {
  onClose: () => void
}

const validationSchema = yup.object().shape({
  timezone: yup.string().nullable()
})

export type SelectedTimezoneForm = yup.InferType<typeof validationSchema>

export const SelectedTimezoneModal = ({ onClose }: SelectedTimezoneModalProps) => {
  const { t } = useLanguage('runbook', { keyPrefix: 'selectedTimezoneModal' })
  const runbook = ActiveRunbookModel.useGet()
  const canUpdateRunbook = ActiveRunbookModel.useCan('update')
  const setTimezone = RunbookViewModel.useAction('selectedTimezone:set')
  const selectedTimezone = RunbookViewModel.useGet('selectedTimezone')
  const { timezone: runbookDefaultTimezone } = runbook

  const handleSubmit = async (payload: { timezone?: string | null }) => {
    setTimezone(payload.timezone ?? null)
    onClose()
  }

  return (
    <FormModal<SelectedTimezoneForm>
      preventAutoClose
      onClose={onClose}
      defaultValues={{
        timezone: selectedTimezone
      }}
      open
      title={t('title')}
      description={
        runbookDefaultTimezone ? t('descriptionDefault', { timezone: runbookDefaultTimezone }) : t('description')
      }
      schema={validationSchema}
      confirmText={t('confirmText')}
      onSubmit={handleSubmit}
    >
      {canUpdateRunbook && <Message type="info" message={t('extraText')} margin />}
      <TimezoneSelectField<SelectedTimezoneForm>
        name="timezone"
        label={t('selectedTimezone')}
        clearable
        customFirstOption={null}
      />
    </FormModal>
  )
}
