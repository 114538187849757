import { useState } from 'react'

import { RunbookType } from 'main/components/settings/runbook-types/use-runbook-type'
import { ChooseStartTimeModal } from 'main/components/workspace/modals/choose-start-time-modal'
import { ReactAngularConnector } from 'main/connectors/react-angular-connector'
import { CustomField } from 'main/services/queries/types'

/**
 * @deprecated use one of our runbook types instead or name this type more specifically
 */
export type Runbook = {
  timing_mode: 'scheduled' | 'unscheduled'
  copy_tasks: boolean
  copy_teams: boolean
  copy_users: boolean
  end_scheduled?: Date | null
  event_name?: string
  name: string
  projectModifyType: string
  shift_time: boolean
  start_planned?: Date | null
  start_scheduled?: Date | null
  is_template?: boolean
  template_status?: string
  currentTemplateVersionApprovalStatus: string
}

export type DialogData = {
  modalTitle: string
  ngDialogId: string
  openPreviousModal: Function
  openStoredModal: Function
  runbook?: {
    id: string
    is_template: boolean
    name: string
    runbook_type_id: string
    selectedTimezone: string
    start_planned: string
    start_scheduled: string
    shift_time: boolean
  }
  runbookData: Runbook
  runbookIds: string[]
}

type ChooseStartTimeRunbookConnectorState = {
  data: {
    customFields: CustomField[]
    dialogData: DialogData
    runbook: Runbook
    runbookType: RunbookType
  } | null
}

export const ChooseStartTimeRunbookConnector = () => {
  const [state, setState] = useState<ChooseStartTimeRunbookConnectorState>({
    data: null
  })

  const handleOnMount = ({ data }: ChooseStartTimeRunbookConnectorState) => {
    setState({ data })
  }

  const handleOnUnmount = () => {
    setState({ data: null })
  }

  return (
    <ReactAngularConnector
      mountEvent="open-start-time-runbook-modal"
      unmountEvent="close-start-time-runbook-modal"
      onMount={handleOnMount}
      onUnmount={handleOnUnmount}
    >
      {state.data && (
        <ChooseStartTimeModal
          customFields={state.data.customFields}
          runbook={state.data.runbook}
          dialogData={state.data.dialogData}
          runbookType={state.data.runbookType}
        />
      )}
    </ReactAngularConnector>
  )
}
