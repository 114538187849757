import { Select, SelectProps } from '@cutover/react-ui'
import { TimezoneOption, useTimezones } from 'main/services/hooks'

export type TimezoneSelectProps = Omit<SelectProps<TimezoneOption, string | null | undefined>, 'options' | 'icon'> & {
  /** targetDate is required to generate an offset for a given timezone */
  targetDate?: Date | null
  customFirstOption?: {
    id: string
    name: string
  } | null
}

export const TimezoneSelect = ({ clearable = false, customFirstOption, targetDate, ...props }: TimezoneSelectProps) => {
  const timezones = useTimezones({ customFirstOption, targetDate })

  return <Select {...props} clearable={clearable} options={timezones} icon="timezones" />
}
