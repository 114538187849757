import { useQuery, UseQueryOptions } from 'react-query'

import { QueryKeys } from './query-keys'
import { FolderListFolder, RunbookListRunbook, User } from './types'
import { apiClient } from '../api'
import {
  EXCLUDE_KEYS_FROM_RUNBOOKS_SERVER_QUERY,
  serverQueryObjectToServerQueryString
} from 'main/components/shared/filter/filter-params'
import { RunbooksFilter } from 'main/components/shared/filter/filter-types'

export type RunbookCentralTeam = { id: number; name: string; color: string; count: number }
export type CustomFieldOptionId = number
export type CustomFieldOptionCounts = Record<CustomFieldOptionId, number>
export type RunbooksResponseMetaFull = {
  all_filtered_runbook_ids: number[]
  all_filtered_runbook_type_ids: { [id: number]: number }
  custom_field_ids: number[]
  events: number[] // is this type correct?
  field_option_count_map: CustomFieldOptionCounts
  field_option_ids: CustomFieldOptionId[]
  filtered_results_count: number
  offset: number
  permissions: { [action: string]: number[] }
  projects: FolderListFolder[]
  teams: RunbookCentralTeam[]
  template_action_required: boolean
  missing_ids: number[]
  total_results_count: number
  users: User[] // TODO is this correct? Is this a shortened version of a user?
}
export type RunbooksResponseMeta = Pick<
  RunbooksResponseMetaFull,
  | 'all_filtered_runbook_ids'
  | 'all_filtered_runbook_type_ids'
  | 'filtered_results_count'
  | 'offset'
  | 'template_action_required'
  | 'total_results_count'
  | 'missing_ids'
>
export type RunbooksResponse<
  RunbookType extends RunbookListRunbook = RunbookListRunbook,
  // full meta is only returned when meta=true in the request
  Meta = RunbooksResponseMeta
> = {
  meta: Meta
  runbooks: RunbookType[]
}

export type ServerParams = {
  accountId: string
  meta: boolean
  limit: number | 'none'
  offset: number
  starts_after: number
  is_parent?: boolean
  is_child?: boolean
}

export type UseRunbookParams = Partial<Record<RunbooksFilter, any> & ServerParams>

export function useRunbooks<
  RunbookType extends RunbookListRunbook = RunbookListRunbook,
  Meta extends RunbooksResponseMeta = RunbooksResponseMeta
>(params: UseRunbookParams = {}, options: UseQueryOptions<RunbooksResponse<RunbookType, Meta>, Error> = {}) {
  const serverParams = serverQueryObjectToServerQueryString({
    queryObject: params,
    excludeKeys: EXCLUDE_KEYS_FROM_RUNBOOKS_SERVER_QUERY
  })

  return useQuery<RunbooksResponse<RunbookType, Meta>, Error>(
    [QueryKeys.Runbooks, serverParams],
    () => fetchRunbooks<RunbookType, Meta>(params),
    options
  )
}

export async function fetchRunbooks<
  RunbookType extends RunbookListRunbook = RunbookListRunbook,
  Meta extends RunbooksResponseMeta = RunbooksResponseMeta
>(params: UseRunbookParams) {
  const serverParams = serverQueryObjectToServerQueryString({
    queryObject: params,
    excludeKeys: EXCLUDE_KEYS_FROM_RUNBOOKS_SERVER_QUERY
  })

  const { data } = await apiClient.get<RunbooksResponse<RunbookType, Meta>>({
    url: `runbooks${serverParams}`
  })

  return data
}
