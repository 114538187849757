import { RequireExactlyOne, Simplify } from 'type-fest'

import { TaskListTask } from 'main/services/queries/types'
import {
  TaskActionType,
  useActionTask,
  useActionTaskPermissionCallback,
  useCanTask,
  useCountTasks,
  useCountTasksCallback,
  useGetAllTasks,
  useGetAllTasksBy,
  useGetAllTasksByCallback,
  useGetAllTasksCallback,
  useGetAllTasksLoadable,
  useGetAllTasksLoadableCallback,
  useGetTask,
  useGetTaskBy,
  useGetTaskByCallback,
  useGetTaskCallback,
  useGetTaskLookup,
  useGetTaskLookupCallback,
  useGetTaskLookupLoadable,
  useGetTaskOverride,
  useGetTaskOverrideCallback,
  useHandleTaskAction,
  useReloadTasks,
  useReloadTasksSync,
  useTaskFloat,
  useTaskFloatCallback,
  useTaskIds,
  useTaskIdsBy,
  useTaskIdsByCallback,
  useTaskIdsCallback,
  useTaskIsCritical,
  useTaskIsCriticalCallback
} from '../hooks'
import {
  CollectionModelInterfaceBase,
  ModelCollectionAccessScope,
  ModelMultiAccessBy,
  CollectionModelInterfaceWithCollectionAccessBy as WithAccessBy,
  CollectionModelInterfaceWithActions as WithActions,
  CollectionModelIntefaceWithLookupLoadable as WithLookupLoadable,
  CollectionModelInterfaceWithMultiLoadableAccess as WithMultiLoadable,
  CollectionModelInterfaceWithPermissions as WithPermissions,
  CollectionModelInterfaceWithReload as WithReload,
  CollectionModelInterfaceWithSingleAccessBy as WithSingleAccessBy
} from './types'
import { TaskProgressionState, TasksPermissionsResponse } from 'main/recoil/runbook'
import { RunbookRequestClassMethods } from 'main/services/api/data-providers/runbook-types'

/* --------------------------------- Helpers -------------------------------- */

type TaskCollectionScope = ModelCollectionAccessScope<'filtered' | 'critical'>
type TaskUniqueId = 'id' | 'internal_id'
type TaskMultiAccessBy = ModelMultiAccessBy<{ runbook_team_id: number }>
type TaskGetIdsBy = RequireExactlyOne<{ runbook_team_id: number; user_id: number }>

type SingleTaskActions =
  | Extract<
      RunbookRequestClassMethods<'Task'>,
      'create' | 'finish' | 'integration' | 'move' | 'multiply' | 'quick_update' | 'start' | 'update'
    >
  | 'progress'
  | 'delete'
  | 'skip'
type BulkTaskActions = Extract<
  RunbookRequestClassMethods<'Task'>,
  'bulk_create' | 'bulk_delete' | 'bulk_skip' | 'bulk_update'
>

/* -------------------------- Custom function defs -------------------------- */

type TaskModelCustomFunctions = {
  useGetFloat: (identifier: number) => number | undefined
  useGetFloatCallback: () => (identifier: number) => Promise<number | undefined>

  useGetOverride: (identifier: number) => TaskProgressionState['override'] | undefined
  useGetOverrideCallback: () => (identifier: number) => Promise<TaskProgressionState['override'] | undefined>

  useGetIsCritical: (identifier: number) => boolean
  useGetIsCriticalCallback: () => (identifier: number) => Promise<boolean>

  useGetCount: (opts?: { scope?: TaskCollectionScope }) => number
  useGetCountCallback: (opts?: { scope?: TaskCollectionScope }) => () => Promise<number>

  useGetIds: (opts?: { scope?: TaskCollectionScope }) => number[]
  useGetIdsCallback: (opts?: { scope?: TaskCollectionScope }) => () => Promise<number[]>

  useGetIdsBy: (opts?: TaskGetIdsBy) => number[]
  useGetIdsByCallback: () => (opts?: TaskGetIdsBy) => Promise<number[]>

  useAction: <TAction extends keyof TaskActionType>(action: TAction) => TaskActionType[TAction]

  // [X]CallbackSync is used here because we need to use these functions within the body of components for performance reasons
  // this is not a pattern that should be replicated without discussion
  usePermissionCallbackSync: <TAction extends SingleTaskActions | BulkTaskActions | undefined = undefined>(
    action?: TAction
  ) => (
    taskId: TAction extends BulkTaskActions ? number[] : number
  ) => TAction extends BulkTaskActions
    ? { can: boolean; errors: Record<number, { can: false; error: string }> }
    : { can: boolean; error?: string }
}

/* ----------------------------- Model type def ----------------------------- */

export type TaskModelType = Simplify<
  CollectionModelInterfaceBase<TaskListTask, TaskCollectionScope, TaskUniqueId> &
    // NOTE: useOnAction is omitted and overridden with a custom function, this is *not* as a pattern that should be replicated in other
    // models without discussion and we should probably look to refactor the functions here so they fit a generic pattern
    // NOTE: (another one) useCanAction and useCanActionAll are not available in this model for performance reasons; we'd need to create
    // atoms/selectors in global state which would cause recomputing of the logic regardless of whether the tasks are on the screen
    Omit<WithActions<'Task'>, 'usePermission' | 'usePermissionCallback'> &
    WithPermissions<TasksPermissionsResponse> &
    WithAccessBy<TaskListTask, TaskMultiAccessBy> &
    WithReload &
    WithMultiLoadable<TaskListTask> &
    WithLookupLoadable<TaskListTask> &
    WithSingleAccessBy<TaskListTask, { internal_id: number }> &
    TaskModelCustomFunctions
>

/* -------------------------------------------------------------------------- */
/*                                    Model                                   */
/* -------------------------------------------------------------------------- */

export const TaskModel: TaskModelType = {
  useCan: useCanTask,

  useGet: useGetTask,
  useGetCallback: useGetTaskCallback,

  useGetAll: useGetAllTasks,
  useGetAllCallback: useGetAllTasksCallback,

  useGetAllBy: useGetAllTasksBy,
  useGetAllByCallback: useGetAllTasksByCallback,

  useGetBy: useGetTaskBy,
  useGetByCallback: useGetTaskByCallback,

  useGetAllLoadable: useGetAllTasksLoadable,
  useGetAllLoadableCallback: useGetAllTasksLoadableCallback,

  useGetLookup: useGetTaskLookup,
  useGetLookupCallback: useGetTaskLookupCallback,

  useGetLookupLoadable: useGetTaskLookupLoadable,

  useOnAction: useHandleTaskAction,
  // NOTE: useCanAction is not available in this model for performance reasons; we'd need to create atoms/selectors
  // in global state which would cause recomputing of the logic regardless of whether the tasks are on the screen
  usePermissionCallbackSync: useActionTaskPermissionCallback,
  useAction: useActionTask,

  useReload: useReloadTasks,
  useReloadSync: useReloadTasksSync,

  /* ---------------------------- Custom Functions ---------------------------- */

  useGetFloat: useTaskFloat,
  useGetFloatCallback: useTaskFloatCallback,

  useGetIsCritical: useTaskIsCritical,
  useGetIsCriticalCallback: useTaskIsCriticalCallback,

  useGetCount: useCountTasks,
  useGetCountCallback: useCountTasksCallback,

  useGetIds: useTaskIds,
  useGetIdsCallback: useTaskIdsCallback,

  useGetIdsBy: useTaskIdsBy,
  useGetIdsByCallback: useTaskIdsByCallback,

  useGetOverride: useGetTaskOverride,
  useGetOverrideCallback: useGetTaskOverrideCallback
}
