import { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'

import { Box, CheckboxGroup, Form, Message, Modal, RadioboxGroup, Text, TextInput } from '@cutover/react-ui'
import { useLanguage } from 'main/services/hooks'
import { useRunAction } from 'main/services/queries/use-run'
import { ActiveRunModel, ConfigModel } from 'main/data-access'
import { RunbookRunPauseResponse } from 'main/services/api/data-providers/runbook-types'

type PauseRunbookModalProps = {
  onClose: () => void
  runbookId: string | number
  runId: string | number
  templateType: 'off' | 'default' | 'snippet'
  runType: 'rehearsal' | 'live'
  isParentRunbook: boolean
  runbookVersionId?: string | number
  isRunCommsOff: boolean
}

type PauseRunbookModalForm = {
  type: 'pause' | 'cancel'
  actionMessage: string | null
  options: 'actionNotify'[] | null
}

//TODO: pause and resume modals look very similar, perhaps consolidate.
export const PauseRunbookModal = ({
  onClose,
  runbookId,
  runId,
  templateType,
  runType,
  isParentRunbook,
  isRunCommsOff
}: PauseRunbookModalProps) => {
  const { t } = useLanguage('runbook')
  const pauseMutation = useRunAction({ runbookId, runId, type: 'pause' })
  const cancelMutation = useRunAction({ runbookId, runId, type: 'cancel' })
  const isReactRunbookEnabled = ConfigModel.useIsFeatureEnabled('react_runbook')
  const processRunPauseResponse = ActiveRunModel.useOnAction('pause')

  const isSnippet = templateType === 'snippet'

  const methods = useForm<PauseRunbookModalForm>({
    defaultValues: {
      type: 'pause',
      actionMessage: null,
      options: null
    }
  })

  const type = methods.watch('type')
  const options = methods.watch('options')
  const actionNotify = options && options.includes('actionNotify')

  const mutation = type === 'pause' ? pauseMutation : cancelMutation

  useEffect(() => {
    if (!actionNotify) {
      methods.setValue('actionMessage', null)
    }
  }, [actionNotify])

  const onFormSubmit = ({ type, actionMessage, options }: PauseRunbookModalForm) => {
    const mutationPayload = {
      type,
      action_notify: !!(options && options.includes('actionNotify')),
      action_message: actionMessage
    }

    // @ts-ignore
    mutation.mutate(mutationPayload, {
      onSuccess: response => {
        if (isReactRunbookEnabled) {
          processRunPauseResponse(response as RunbookRunPauseResponse)
        }

        onClose()
      }
    })
  }

  const isSubmitting = pauseMutation.isLoading || cancelMutation.isLoading

  const confirmText = () => {
    if (type === 'pause') {
      return isSubmitting ? t('pauseRunbookModal.pauseSubmitingText') : t('pauseRunbookModal.pauseSubmitText')
    } else {
      return isSubmitting ? t('pauseRunbookModal.cancelSubmitingText') : t('pauseRunbookModal.cancelSubmitText')
    }
  }

  return (
    <Modal
      title={t('pauseRunbookModal.heading')}
      open
      onClose={onClose}
      hasCancelButton={false}
      loading={isSubmitting}
      confirmText={confirmText()}
      confirmIcon={type === 'pause' ? 'pause' : 'dnd-forwardslash'}
      onClickConfirm={isSubmitting ? undefined : methods.handleSubmit(onFormSubmit)}
    >
      {mutation.isError && (
        <Box margin={{ bottom: 'medium' }}>
          <Message type="error" message={mutation.error.errors} />
        </Box>
      )}
      <Text css="padding-right: 10px">
        {t('pauseRunbookModal.description', {
          item: isSnippet ? 'snippet' : 'runbook'
        })}
      </Text>
      <Form>
        <Box margin={{ top: '16px' }}>
          {/* TODO:  Investigate issue with tasks dissappearing on submit */}
          <Controller // controlling because it renders the correct confirmText for submit button
            name="type"
            control={methods.control}
            render={({ field: { name, value, onChange, onBlur, ref } }) => (
              <RadioboxGroup
                name={name}
                label={t('pauseRunbookModal.runActionLabel')}
                disabled={isSubmitting}
                direction="row"
                options={[
                  { value: 'pause', label: t('pauseRunbookModal.pause') },
                  { value: 'cancel', label: t('pauseRunbookModal.cancel') }
                ]}
                defaultValue="pause"
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                ref={ref}
              />
            )}
          />
          {type === 'cancel' && runType === 'live' && (
            <Message
              type="error"
              message={isParentRunbook ? t('pauseRunbookModal.parentRunbookProblem') : t('pauseRunbookModal.problem')}
            />
          )}
          {type === 'pause' && isParentRunbook && <Message type="info" message={t('pauseRunbookModal.warning')} />}
          {!isRunCommsOff && (
            <Controller
              name="options"
              control={methods.control}
              render={({ field: { onChange } }) => (
                <CheckboxGroup
                  label={t('pauseRunbookModal.options')}
                  onChange={event => event && onChange(event.value)}
                  options={[{ value: 'actionNotify', option: 'actionNotify', label: t('pauseRunbookModal.notify') }]}
                />
              )}
            />
          )}

          {actionNotify && (
            <TextInput
              {...methods.register('actionMessage')}
              label={t('pauseRunbookModal.messageLabel')}
              disabled={isSubmitting}
            />
          )}
        </Box>
      </Form>
    </Modal>
  )
}
