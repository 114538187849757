// @ts-nocheck
import { ChangeEvent, MouseEvent as ReactMouseEvent, useMemo } from 'react'

import { CheckboxHierarchyGroup, RecursiveCheckboxChild } from '@cutover/react-ui'
import { getFilterSelections } from '../filter-helpers'
import {
  FilterGroupHierarchy as FilterGroupHierarchyType,
  FilterOptionHierarchy,
  SelectedFilters
} from '../filter-types'

export const FilterGroupHierarchy = ({
  filter,
  onChange,
  selected = {},
  onOptionEditClick
}: {
  filter: FilterGroupHierarchyType
  selected?: SelectedFilters
  onChange: (selected: number[]) => void
  onOptionEditClick?: (e: ReactMouseEvent, option: FilterOptionHierarchy) => void
}) => {
  const value = getFilterSelections(selected, filter) as number[]
  const valueKey = 'value'
  const options = filter.options

  const { parentToChildrenValues, childToParentsValues } = useMemo(() => {
    const parentToChildrenValues: Record<string, string[]> = {}
    const childToParentsValues: Record<string, string[]> = {}

    const buildChildrenRecursive = (next: RecursiveCheckboxChild, prev: string[] = []) => {
      childToParentsValues[next[valueKey]] = prev
      parentToChildrenValues[next[valueKey]] = []

      prev.forEach(p => (parentToChildrenValues[p] = [next[valueKey], ...(parentToChildrenValues[p] || [])]))
      if (next.children) next.children.forEach(child => buildChildrenRecursive(child, [next[valueKey], ...prev]))
    }

    options.forEach(o => o.children?.forEach(child => buildChildrenRecursive(child, [o[valueKey]])))
    return { parentToChildrenValues, childToParentsValues }
  }, [options])

  const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>, option: RecursiveCheckboxChild) => {
    const optVal = option[valueKey] as number

    if (e.target.checked) {
      return onChange(
        // add new option and deselect any children (they are technically not selected but will show as selected in the UI)
        // @ts-ignore
        [...(value ?? []), optVal].filter(s => !(parentToChildrenValues[optVal] || []).includes(s))
      )
    } else {
      // @ts-ignore
      const parentSelected: boolean = (childToParentsValues[optVal] || []).some(r => value.includes(r))
      const directParent = childToParentsValues[optVal] && childToParentsValues[optVal][0]

      return onChange(
        // add in all siblings (direct parent's child values) if parent is selected (this will add in the unselected child but is filtered out on next line)
        (parentSelected && directParent
          ? [...parentToChildrenValues[directParent], ...(value ?? [])]
          : value ?? []
        ).filter(
          val =>
            // unselect parents and actual clicked checkbox
            val !== option[valueKey] && !(childToParentsValues[option[valueKey]] || []).includes(val)
        )
      )
    }
  }

  return (
    <CheckboxHierarchyGroup
      onEditClick={onOptionEditClick ? (_, option) => onOptionEditClick(_, option as FilterOptionHierarchy) : undefined}
      withTooltipLabel
      value={value ?? []}
      onChange={handleCheckboxChange}
      options={filter.options}
      itemCreateInputProps={filter.itemCreateInputProps}
    />
  )
}
