import {
  TransactionInterface_UNSTABLE,
  useRecoilCallback,
  useRecoilTransaction_UNSTABLE,
  useRecoilValue,
  useRecoilValueLoadable
} from 'recoil'
import { produce } from 'immer'
import { extend } from 'lodash'

import {
  runbookIdState,
  runbookPermission,
  runbookResponseState_INTERNAL,
  runbookRunbookTypeState,
  runbookState,
  runbookVersionResponseState_INTERNAL,
  taskListResponseState_INTERNAL
} from 'main/recoil/runbook'
import { updateAllChangedTasks } from 'main/recoil/data-access'
import { RunbookShowRunbook } from 'main/services/queries/types'
import { getTasks, TaskListResponseType } from 'main/services/queries/use-tasks'
import { getRunbookVersion, GetRunbookVersionResponse } from 'main/services/queries/use-runbook-versions'
import { RunbookMergeResponse, RunbookUpdateResponse } from 'main/services/api/data-providers/runbook-types'
import { ActiveRunbookModelType } from 'main/data-access/models'
import { updateVersionData } from 'main/recoil/data-access/updaters__TEMPORARY/shared-updates'
import { useEnsureStableArgs } from 'main/data-access/models/model-utils'

/* ----------------------------------- Get ---------------------------------- */

export const useGetActiveRunbook: ActiveRunbookModelType['useGet'] = () => useRecoilValue(runbookState)

export const useGetActiveRunbookCallback: ActiveRunbookModelType['useGetCallback'] = () =>
  useRecoilCallback(
    ({ snapshot }) =>
      async () =>
        await snapshot.getPromise(runbookState),
    []
  )

/* ------------------------------ Get Loadable ------------------------------ */

export const useActiveRunbookLoadableValue: ActiveRunbookModelType['useGetLoadable'] = () =>
  useRecoilValueLoadable(runbookState)

export const useActiveRunbookLoadableCallback: ActiveRunbookModelType['useGetLoadableCallback'] = () =>
  useRecoilCallback(
    ({ snapshot }) =>
      () =>
        snapshot.getLoadable(runbookState),
    []
  )

/* ----------------------------------- Can ---------------------------------- */

export const useActiveRunbookCan: ActiveRunbookModelType['useCan'] = permission => {
  return useRecoilValue(runbookPermission({ attribute: permission }))
}

/* ----------------------------------- Id ----------------------------------- */

export const useActiveRunbookId: ActiveRunbookModelType['useId'] = () => {
  return useRecoilValue(runbookIdState)
}

export const useActiveRunbookIdCallback: ActiveRunbookModelType['useIdCallback'] = () =>
  useRecoilCallback(
    ({ snapshot }) =>
      async () =>
        await snapshot.getPromise(runbookIdState),
    []
  )

/* --------------------------------- Action --------------------------------- */

// @ts-ignore
export const useActiveRunbookActionHandler: ActiveRunbookModelType['useOnAction'] = action => {
  useEnsureStableArgs(action)

  const processMergeResponse = useProcessRunbookMergeResponse()
  const processUpdateResponse = useProcessRunbookUpdateResponse()
  return action === 'merge' ? processMergeResponse : processUpdateResponse
}

/* ---------------------------- Get Runbook Type ---------------------------- */

export const useActiveRunbookRunbookType: ActiveRunbookModelType['useRunbookType'] = () =>
  useRecoilValue(runbookRunbookTypeState)

export const useActiveRunbookRunbookTypeCallback: ActiveRunbookModelType['useRunbookTypeCallback'] = () =>
  useRecoilCallback(
    ({ snapshot }) =>
      async () =>
        await snapshot.getPromise(runbookRunbookTypeState),
    []
  )

/* -------------------------------- Internal -------------------------------- */

const useProcessRunbookUpdateResponse = () =>
  useRecoilTransaction_UNSTABLE(
    transactionInterface => (response: RunbookUpdateResponse) => {
      updateRunbookData(transactionInterface)(response.runbook)
      updateVersionData(transactionInterface)(response.runbook.current_version)
      updateAllChangedTasks(transactionInterface)(response.meta.changed_tasks)
    },
    []
  )

const updateRunbookData =
  ({ set }: TransactionInterface_UNSTABLE) =>
  (responseRunbook: Partial<RunbookShowRunbook>) => {
    set(runbookResponseState_INTERNAL, prevRunbookResponse =>
      produce(prevRunbookResponse, draftRunbookResponse => {
        extend(draftRunbookResponse.runbook, responseRunbook)
      })
    )
  }

const useProcessRunbookMergeResponse = () => {
  const updatePageData = useRecoilTransaction_UNSTABLE(
    ({ set }) =>
      ({
        taskData,
        runbookVersionData,
        runbookData
      }: {
        taskData: TaskListResponseType
        runbookVersionData: GetRunbookVersionResponse
        runbookData: RunbookShowRunbook
      }) => {
        set(
          taskListResponseState_INTERNAL,
          produce(taskData, draft => {
            extend(draft, taskData)
          })
        )

        set(
          runbookVersionResponseState_INTERNAL,
          produce(runbookVersionData, draft => {
            extend(draft, runbookVersionData)
          })
        )

        set(runbookResponseState_INTERNAL, prev =>
          produce(prev, draft => {
            extend(draft.runbook, runbookData)
          })
        )
      },
    []
  )

  return useRecoilCallback(
    () => async (response: RunbookMergeResponse) => {
      const responseRunbook = response.runbook
      const responseRunbookId = responseRunbook.id
      const responseRunbookCurrentVersionId = responseRunbook.current_version?.id || responseRunbook.runbook_version_id

      const [taskData, runbookVersionData] = await Promise.all([
        getTasks(responseRunbookId, responseRunbookCurrentVersionId),
        getRunbookVersion(responseRunbookId, responseRunbookCurrentVersionId)
      ])

      updatePageData({
        taskData,
        runbookVersionData,
        runbookData: responseRunbook
      })
    },
    [updatePageData]
  )
}
