import { useMutation } from 'react-query'

import { apiClient } from 'main/services/api'
import { ApiError } from 'main/services/api/http-gateway-adapter'
import { QueryKeys } from 'main/services/queries/query-keys'
import { Run, RunbookShowRunbook, RunComms, RunType } from 'main/services/queries/types'
import {
  RunbookRunCancelResponse,
  RunbookRunCreateResponse,
  RunbookRunPauseResponse,
  RunbookRunResumeResponse,
  RunbookRunUpdateResponse
} from '../api/data-providers/runbook-types'

export type RunPayload = {
  notify: boolean
  message: string | null
  recalc_timings: boolean
  run: {
    comms: RunComms
    disable_task_notify: boolean
    name?: string
    run_type: RunType
  }
  shift_time: boolean
}

export type RunResponse = {
  meta: {
    headers: {
      request_class: 'Run'
      request_hash: string
      request_method: 'create'
      request_user_id: number
    }
    runbook: RunbookShowRunbook
  }
  run: Run & {
    end_display: number | null
    name: string | null
  }
}

export type PauseAndCancelRunPayload = {
  type: 'pause' | 'cancel'
  action_notify: boolean
  action_message: string | null
}

export type ResumeRunPayload = {
  type: 'resume'
  resume_notify: boolean
  resume_message: string | null
  task_notify: boolean
}

export const useRunCreate = ({ runbookId }: { runbookId: string | number }) => {
  return useMutation<RunbookRunCreateResponse, ApiError, RunPayload>(
    [QueryKeys.Runbooks, String(runbookId), QueryKeys.Runs],
    async (payload: RunPayload) => {
      const { data } = await apiClient.post<RunPayload, RunbookRunCreateResponse>({
        url: `runbooks/${runbookId}/runs`,
        data: payload
      })

      return data as RunbookRunCreateResponse
    }
  )
}

export type RunActionPayload = PauseAndCancelRunPayload | ResumeRunPayload

type RunActionResponse = RunbookRunCancelResponse | RunbookRunPauseResponse | RunbookRunResumeResponse

export const useRunAction = ({
  runbookId,
  runId,
  type
}: {
  runbookId: string | number
  runId: string | number
  type: 'pause' | 'cancel' | 'resume'
}) => {
  return useMutation<RunActionResponse, ApiError, RunActionPayload>(
    [QueryKeys.Runbooks, String(runbookId), QueryKeys.Runs, String(runId), type],
    async (payload: RunActionPayload) => {
      const { data } = await apiClient.patch<{ runbook_version: RunActionPayload }, RunActionResponse>({
        url: `runbooks/${runbookId}/runs/${runId}/${type}`,
        data: { runbook_version: payload }
      })

      return data as RunActionResponse
    }
  )
}

export type UpdateRunPayload = {
  comms: RunComms
  disable_task_notify: boolean
}

export const updateRun = async ({
  runbookId,
  runId,
  payload
}: {
  runbookId: number | string
  runId: number | string
  payload: UpdateRunPayload
}) => {
  const { data } = await apiClient.patch<UpdateRunPayload, RunbookRunUpdateResponse>({
    url: `runbooks/${runbookId}/runs/${runId}`,
    data: payload
  })

  return data as RunbookRunUpdateResponse
}
