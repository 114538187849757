import { useMutation, useQuery } from 'react-query'

import { RunbookTeamResponse, TeamResponse, UserResponse } from '../../components/recipients-multiselect/types'
import { ApiError } from '../api/http-gateway-adapter'
import { apiClient } from 'main/services/api/api-client'
import { RunbookTeamDestroyResponse } from '../api/data-providers/runbook-types'
import { apiClient_UNSTABLE } from '../api'

type UsersAndTeams = {
  users: (string | number)[]
  teams: number[]
}

type UserTeamResponse = {
  users: UserResponse[]
  teams: RunbookTeamResponse[] | TeamResponse[]
}

const fetchUsers = async (users: (string | number)[]): Promise<UserResponse[]> => {
  if (users.length === 0) {
    return []
  }
  const query = users.map((id: string | number) => `query[]=${id}`)
  const { data } = await apiClient.get<{ users: UserResponse[] }>({
    url: `users/list?${query.join('&')}`
  })
  return data.users
}

const fetchTeams = async (
  teams: number[],
  runbookId?: number,
  runbookVersionId?: number
): Promise<RunbookTeamResponse[] | TeamResponse[]> => {
  if (teams.length === 0) {
    return []
  }

  const query = teams.map((id: number) =>
    runbookId && runbookVersionId ? `runbook_team_ids[]=${id}` : `query[]=${id}`
  )

  const url =
    runbookId && runbookVersionId
      ? `runbooks/${runbookId}/runbook_versions/${runbookVersionId}/runbook_teams/list`
      : 'teams/list'

  const { data } = await apiClient.get<
    { runbook_team: RunbookTeamResponse[]; team: undefined } | { runbook_team: undefined; team: TeamResponse[] }
  >({
    url: `${url}?${query.join('&')}`
  })

  return data.runbook_team || data.team
}

export function useUsersAndTeamsListQuery(
  userAndTeamIds: UsersAndTeams,
  runbookId?: number,
  runbookVersionId?: number
) {
  return useQuery<UserTeamResponse, ApiError>([userAndTeamIds], () => {
    async function fetchUsersAndTeams(userAndTeamIds: UsersAndTeams, runbookId?: number, runbookVersionId?: number) {
      const [users, teams] = await Promise.all([
        fetchUsers(userAndTeamIds.users),
        fetchTeams(userAndTeamIds.teams, runbookId, runbookVersionId)
      ])

      return {
        users,
        teams
      }
    }

    return fetchUsersAndTeams(userAndTeamIds, runbookId, runbookVersionId)
  })
}

type RunbookTeamDeleteProps = {
  runbookId: number
  runbookVersionId: number
  runbookTeamId: number
}

type RunbookTeamDeletePayload = {
  reassign: {
    selected: string
    users_to_reassign: number[]
  }
}

export const useDeleteRunbookTeam = ({ runbookId, runbookVersionId, runbookTeamId }: RunbookTeamDeleteProps) => {
  return useMutation<RunbookTeamDestroyResponse, ApiError, RunbookTeamDeletePayload>(
    [runbookId, runbookVersionId, runbookTeamId],
    async payload => {
      const { data } = await apiClient_UNSTABLE.patch<RunbookTeamDestroyResponse>(
        `runbooks/${runbookId}/runbook_versions/${runbookVersionId}/runbook_teams/${runbookTeamId}`,
        payload
      )
      return data
    }
  )
}

type ImportUserTeamProps = {
  formData: FormData
  runbookId: number | string
  runbookVersionId: number | string
}

export const importUserTeam = async ({ formData, runbookId, runbookVersionId }: ImportUserTeamProps) => {
  const { data } = await apiClient_UNSTABLE.post<RunbookTeamDestroyResponse>(
    `runbooks/${runbookId}/runbook_versions/${runbookVersionId}/user_team_import`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  )
  return data
}
