import { memo, useMemo } from 'react'
import { Stack, ThemeContext } from 'grommet'
import { LiteralUnion } from 'type-fest'

import { BrandIconName } from '@cutover/icons'
import { BrandIcon } from './brand-icon'
import { Box } from '../layout/box'
import { Tooltip } from '../overlays/tooltip'
import { RagIndicator } from '../rag-indicator'

export type RunbookTypeIconName = BrandIconName

export type RunbookTypeIconProps = {
  color: string // comes from backend config
  size?: 'small' | 'medium' | 'large'
  template?: boolean
  icon: LiteralUnion<RunbookTypeIconName, string>
  status?: 'red' | 'amber' | 'green' | 'off'
  statusMessage?: string
}

export const RunbookTypeIcon = memo(
  ({ icon: iconName, color, size = 'large', template, status, statusMessage }: RunbookTypeIconProps) => {
    const cleanedIconName = useMemo(() => iconName?.replace(/_/g, '-') as BrandIconName, [iconName])

    const icon = (
      <ThemeContext.Extend value={{ dark: false }}>
        <BrandIcon
          icon={cleanedIconName}
          color={!!template ? color : 'bg'}
          size={size === 'small' ? '12px' : size === 'medium' ? '18px' : '24px'}
        />
      </ThemeContext.Extend>
    )
    const indicatorElement = status ? <RagIndicator status={status} tip={statusMessage} /> : null
    const statusIndicator =
      status && statusMessage ? (
        <Tooltip content={statusMessage} placement="bottom">
          {indicatorElement}
        </Tooltip>
      ) : status ? (
        indicatorElement
      ) : null

    const iconSize = size === 'small' ? '24px' : size === 'medium' ? '32px' : '40px'
    const iconRadius = size === 'small' ? '4px' : size === 'medium' ? '6px' : '8px'

    const content = (
      <Box
        data-testid={`${cleanedIconName}${template ? '-template' : ''}`}
        align="center"
        justify="center"
        width={iconSize}
        height={iconSize}
        round={iconRadius}
        background={!template ? { custom: color } : 'bg'}
        border={
          !!template
            ? {
                size: '3px',
                color: { custom: color },
                style: 'dashed'
              }
            : undefined
        }
      >
        {icon}
      </Box>
    )

    return statusIndicator ? (
      <Box width={size === 'small' ? '24' : '40'} height={size === 'small' ? '24px' : '40px'}>
        <Stack
          anchor="bottom-right"
          css={`
            > div:last-child {
              bottom: -3px;
              right: -4px;
            }
          `}
        >
          {content}
          <Box>{statusIndicator}</Box>
        </Stack>
      </Box>
    ) : (
      content
    )
  }
)
