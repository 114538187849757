import { useEffect } from 'react'
import { AxiosRequestConfig, AxiosResponse } from 'axios'
import Cookies from 'js-cookie'

import { apiClient_UNSTABLE } from '..'

// App-wide interceptor for all axios requests, this does:
// - add tokens to headers for auth access
// - updates tokens based on response
// - adds app-wide error handling, e.g.
//   redirect to login on 401 (TODO, see https://cutover.atlassian.net/browse/CFE-2198)
export const useAppAPIInterceptor = () => {
  useEffect(() => {
    const requestIntercepter = apiClient_UNSTABLE.interceptors.request.use((request: any) => {
      request = setAuthHeaders(request)
      return request
    })

    const responseIntercepter = apiClient_UNSTABLE.interceptors.response.use(
      // onSuccess handler
      (response: AxiosResponse) => {
        response = responseAuthHandler(response)
        return response
      }
    )

    return () => {
      apiClient_UNSTABLE.interceptors.request.eject(requestIntercepter)
      apiClient_UNSTABLE.interceptors.response.eject(responseIntercepter)
    }
  }, [])
}

/* -------------------------------- Internal -------------------------------- */

function setAuthHeaders(request: AxiosRequestConfig) {
  const blob = getCookie('auth_headers')
  const authHeaders = blob ? JSON.parse(blob) : {}
  request.headers = {
    ...(request.headers ?? {}),
    ...authHeaders
  }
  return request
}

function responseAuthHandler(response: AxiosResponse) {
  const { headers } = response

  const authHeaders = getCookie('auth_headers')
  const oldToken = authHeaders ? JSON.parse(authHeaders) : null
  const oldTokenExpiry = oldToken?.expiry ?? 0
  const newTokenExpiry = headers['expiry']

  if (newTokenExpiry !== '' && Number(newTokenExpiry) > Number(oldTokenExpiry)) {
    setCookie(
      'auth_headers',
      JSON.stringify({
        'access-token': headers['access-token'],
        client: headers['client'],
        expiry: headers['expiry'],
        'token-type': headers['token-type'],
        uid: headers['uid']
      })
    )
  }

  return response
}

function getCookie(key: string) {
  return document ? Cookies.get(key) || null : null
}

function setCookie(key: string, value: string) {
  return Cookies.set(key, value, { expires: 7, path: '/', secure: true, sameSite: 'strict' })
}
