import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import * as yup from 'yup'
import { unescape } from 'lodash'
import { useFormContext } from 'react-hook-form'

import { EditPanel, IconButton, useNotify } from '@cutover/react-ui'
import { useLanguage } from 'main/services/hooks'
import {
  ColorPickerField,
  FormEditPanel,
  RadioboxGroupField,
  SelectField,
  TextAreaField,
  TextInputField,
  UserSelectField
} from 'main/components/shared/form'
import { useRightPanelTypeState } from 'main/components/layout/right-panel'
import { Role, RoleType, StreamListStream } from 'main/services/queries/types'
import { StreamUpdatePayload, useGetStream, useUpdateStream } from 'main/services/queries/use-stream'
import { RagStatusFields } from 'main/components/shared/runbook-edit/runbook-edit-form/rag-status-fields'
import { StreamDeleteModal, StreamUnableToDeleteModal } from '../../modals/stream/stream-delete-modal'
import { RunbookStreamShowResponse, RunbookStreamUpdateResponse } from 'main/services/api/data-providers/runbook-types'
import {
  ActiveAccountModel,
  ActiveRunbookModel,
  ActiveRunbookVersionModel,
  RunbookUserModel,
  StreamModel,
  TaskTypeModel
} from 'main/data-access'
import { usePermitRoleAddition } from 'main/components/shared/hooks/runbook'
import { FormType } from 'main/components/shared/form/form'
import {
  DeleteAndReassignUserTeamModal,
  DeleteUserTeamForm
} from '../people-panel/delete-reassign-user-team/delete-and-reassign-user-team-modal'

export const StreamEditPanel = memo(() => {
  const [{ streamId }, { closeRightPanel }] = useRightPanelTypeState('stream-edit')

  return <>{streamId && <StreamEditForm streamId={streamId} closeRightPanel={closeRightPanel} />}</>
})

export const StreamEditForm = ({ streamId, closeRightPanel }: { streamId: number; closeRightPanel: any }) => {
  const { t } = useLanguage('runbook', { keyPrefix: 'streamEditPanel' })
  const { t: commonT } = useLanguage('common')
  const notify = useNotify()

  const runbookId = ActiveRunbookModel.useId()
  const runbookVersionId = ActiveRunbookVersionModel.useId()
  const streamListData = StreamModel.useGet(streamId)

  const { data, isLoading, isError } = useGetStream({ runbookId, runbookVersionId, streamId: streamListData.id })

  useEffect(() => {
    if (isError) notify.error(commonT('defaultApiError'))
  }, [isError])

  return (
    <>
      {isLoading || isError ? (
        <EditPanel // when loading, show dummy edit panel
          isDirty={false}
          onReset={() => {}}
          onSubmit={() => {}}
          loading={isLoading}
          isError={isError}
          isSubmitting={false}
          onClose={closeRightPanel}
          title={streamListData.parent_id ? t('titleSubstream') : t('title')}
        >
          <></>
        </EditPanel>
      ) : streamListData && data ? (
        <StreamEditFormInner
          key={streamId}
          data={{ listStream: streamListData, showStream: data }}
          onClose={closeRightPanel}
        />
      ) : null}
    </>
  )
}

type StreamEditFormType = yup.InferType<typeof validationSchema>

const StreamEditFormInner = memo(
  ({
    data,
    onClose
  }: {
    data: { listStream: StreamListStream; showStream: RunbookStreamShowResponse }
    onClose: () => void
  }) => {
    const { t } = useLanguage('runbook', { keyPrefix: 'streamEditPanel' })
    const formRef = useRef<FormType<StreamEditFormType>>(null)
    const updateStream = useUpdateStream()
    const notify = useNotify()

    const [unableToDeleteModalOpen, setUnableToDeleteModalOpen] = useState(false)
    const [reassignModalUserIds, setReassignModalUserIds] = useState<number[]>([])
    const [deleteModalOpen, setDeleteModalOpen] = useState(false)

    const handleUpdatedStream = StreamModel.useOnAction('update')
    const filterIdsWhereNoRoles = RunbookUserModel.useFilteredIdsWhereTasksAndNoRolesCallback()
    const runbookId = ActiveRunbookModel.useId()
    const runbookVersionId = ActiveRunbookVersionModel.useId()

    const listStream = data.listStream
    const {
      stream: showStream,
      meta: { permissions }
    } = data.showStream

    const defaultValues = {
      color_type: showStream.has_custom_color ? 'custom' : 'auto',
      ...showStream
    }

    const handleSuccess = (response: RunbookStreamUpdateResponse) => {
      notify.success(t('successMessage'))
      const colorType = response.stream.has_custom_color ? 'custom' : 'auto'
      formRef?.current?.reset({ ...response.stream, color_type: colorType })
      handleUpdatedStream(response)
    }

    const requestUpdateStream = async (formData: StreamUpdatePayload) => {
      return await updateStream.mutateAsync(
        { streamId: listStream.id, runbookVersionId, runbookId, ...formData },
        { onSuccess: handleSuccess }
      )
    }

    const handleSubmit = async (formData: StreamUpdatePayload) => {
      if (formData.reassign) return await requestUpdateStream(formData)

      const removedUserIds = getRemovedUserIds(showStream.role_types, formData.stream.roles)
      const userIdsToReassign = await filterIdsWhereNoRoles(removedUserIds, { streamId: listStream.id })

      if (userIdsToReassign.length > 0) return setReassignModalUserIds(userIdsToReassign)
      return await requestUpdateStream(formData)
    }

    const getRemovedUserIds = useCallback((roleTypes: RoleType[], roles: Role[]) => {
      const roleUserIds = new Set(roles.filter(role => role.subject_type === 'User').map(role => role.subject_id))
      return roleTypes.flatMap(roleType =>
        roleType.users.filter(user => !roleUserIds.has(user.id)).map(user => user.id)
      )
    }, [])

    const handleReassignUserModalSubmit = async (form: DeleteUserTeamForm) => {
      await handleSubmit({
        // @ts-ignore we can expect the form is valid at this point as we've already been through handleSubmit
        // so we don't need to revalidate
        stream: dataTransformer(formRef.current?.getValues()).stream,
        reassign: form as StreamUpdatePayload['reassign'],
        users_to_remove_from_runbook: reassignModalUserIds
      })
      setReassignModalUserIds([])
    }

    const handleClickDelete = () => {
      const hasChildren = listStream.children?.length > 0
      if (hasChildren) {
        setUnableToDeleteModalOpen(true)
      } else {
        setDeleteModalOpen(true)
      }
    }

    const onCloseDeleteModal = () => {
      setUnableToDeleteModalOpen(false)
      setDeleteModalOpen(false)
    }

    return (
      <>
        {deleteModalOpen && (
          <StreamDeleteModal
            streamName={listStream.name}
            streamId={listStream.id}
            tasksCount={showStream.tasks_count ?? 0}
            onClose={onCloseDeleteModal}
          />
        )}
        {reassignModalUserIds.length > 0 && (
          <DeleteAndReassignUserTeamModal
            mode="user"
            onClose={() => setReassignModalUserIds([])}
            onSubmit={handleReassignUserModalSubmit}
            ids={reassignModalUserIds}
          />
        )}
        {unableToDeleteModalOpen && <StreamUnableToDeleteModal onClose={onCloseDeleteModal} />}
        {/* NOTE: the onSuccess prop is purposesfully not specified here because we need to call the handleSubmit 
        function directly in this component which doesn't go through the 'onSubmit => onSuccess' flow which occurs 
        in the form edit panel, so it's been written manually in the handleSubmit function
        Usually you should just use the prop but this is a valid case where we shouldn't use it */}
        <FormEditPanel<StreamEditFormType, StreamUpdatePayload>
          ref={formRef}
          onClose={onClose}
          onSubmit={handleSubmit}
          transformer={dataTransformer}
          defaultValues={defaultValues}
          readOnly={!permissions.update.length}
          disabled={!permissions.update.length}
          headerItems={
            permissions.destroy.length
              ? [<IconButton label={t('delete')} tipPlacement="top" icon="delete" onClick={handleClickDelete} />]
              : undefined
          }
          title={listStream.parent_id ? t('titleSubstream') : t('title')}
          schema={validationSchema}
        >
          <StreamEditFormFields permissions={permissions} isChild={!!listStream.parent_id} />
        </FormEditPanel>
      </>
    )
  }
)

const StreamEditFormFields = ({
  permissions: can,
  isChild
}: {
  permissions: Record<string, number[]>
  isChild: boolean
}) => {
  const { t } = useLanguage('runbook', { keyPrefix: 'streamEditPanel' })
  const { watch } = useFormContext<StreamEditFormType>()
  const roleTypes = watch('role_types')
  const colorType = watch('color_type')
  const { slug: accountSlug } = ActiveAccountModel.useGet()
  const runbookType = ActiveRunbookModel.useRunbookType()
  const taskTypes = TaskTypeModel.useGetAll()
  const permitRoleAddition = usePermitRoleAddition(runbookType)

  const taskTypeOptions = useMemo(() => {
    return taskTypes ? taskTypes.map(({ id, name }) => ({ label: unescape(name), value: id })) : []
  }, [taskTypes])

  return (
    <>
      <TextAreaField<StreamEditFormType> name="name" label={t('fields.name')} autoFocus />
      <TextAreaField<StreamEditFormType> name="description" label={t('fields.description')} />
      {!isChild &&
        roleTypes &&
        roleTypes.map((roleType, index) => {
          return (
            <UserSelectField<StreamEditFormType>
              data-testid={`role-type-${roleType.id}`}
              key={`role_types.${roleType.id}`}
              name={`role_types.${index}.users`}
              accountId={accountSlug}
              // @ts-ignore
              defaultValue={roleType.users}
              label={roleType.name}
              helpText={roleType.description || undefined}
              // TODO: fix without hard coding required state https://cutover.atlassian.net/browse/CFE-1578
              required={false}
              disabled={!permitRoleAddition}
            />
          )
        })}
      <RagStatusFields<StreamEditFormType>
        name="status"
        statusMessageFieldName="status_message"
        disabled={!can?.update.length}
        readOnly={!can?.update.length}
      />
      <RadioboxGroupField<StreamEditFormType>
        options={[
          { value: 'auto', label: t('fields.colorTypeAuto') },
          { value: 'custom', label: t('fields.colorTypeCustom') }
        ]}
        name="color_type"
        label={t('fields.colorType')}
        direction="row"
      />
      {colorType === 'custom' && <ColorPickerField<StreamEditFormType> name="color" label={t('fields.color')} />}
      <SelectField<StreamEditFormType>
        name="default_task_type_id"
        options={taskTypeOptions}
        label={t('fields.defaultTaskType')}
        data-testid="default-task-type-select"
      />
      <TextInputField<StreamEditFormType> name="tasks_count" label={t('fields.tasksCount')} readOnly />
    </>
  )
}

const dataTransformer = (data: StreamEditFormType): StreamUpdatePayload => {
  return {
    stream: {
      name: data.name.trim(),
      description: data.description ?? null,
      default_task_type_id: data.default_task_type_id,
      color: data.color_type === 'custom' ? data.color ?? null : null,
      status: data.status,
      status_message: data.status_message,
      roles:
        data.role_types
          ?.map(role =>
            (role.users || []).map(user => ({
              id: user.role_id,
              resource_id: data.id,
              resource_type: 'Stream',
              role_type_id: role.id,
              subject_id: user.id,
              subject_type: 'User'
            }))
          )
          .flat() ?? []
    },
    users_to_remove_from_runbook: [] // See comment above handleSubmit
  }
}

const validationSchema = yup.object({
  id: yup.number().required(),
  description: yup.string().nullable(),
  name: yup.string().required(),
  color: yup.string().nullable(),
  tasks_count: yup.number().notRequired(),
  default_task_type_id: yup.number().nullable(),
  color_type: yup.string().oneOf(['auto', 'custom']),
  status: yup.string().oneOf(['off', 'red', 'amber', 'green']).required(),
  status_message: yup
    .string()
    .nullable()
    .when('status', {
      is: (val: string) => val !== 'off',
      then: () => yup.string().required()
    }),
  role_types: yup.array().of(
    yup.object({
      id: yup.number().required(),
      description: yup.string().nullable(),
      name: yup.string(),
      users: yup.array().of(
        yup.object({
          role_id: yup.number(),
          id: yup.number().required()
        })
      )
    })
  )
})
