import { Controller, FieldValues, useFormContext } from 'react-hook-form'

import { ListItem, MultiSelect } from '@cutover/react-ui'
import { FieldProps, getInputProps } from './form-fields'
import { getSnippets, SnippetListSnippet } from 'main/services/queries/use-snippets'

type SnippetSelectProps = {
  name: string
  isSubmitting: boolean
  accountSlug: string
  label: string
  a11yTitle: string
}

export type SnippetSelectFieldProps<TFieldValues extends FieldValues> = FieldProps<SnippetSelectProps, TFieldValues>

export const SnippetSelectField = <TFieldValues extends FieldValues>({
  ...props
}: SnippetSelectFieldProps<TFieldValues>) => {
  const formContext = useFormContext<TFieldValues>()
  const inputProps = getInputProps<TFieldValues>({ ...props, formContext })
  const { control } = formContext

  return (
    <Controller
      name={props.name}
      control={control}
      render={({ field: { onChange, value, ref } }) => {
        return (
          <MultiSelect<SnippetListSnippet>
            {...props}
            {...inputProps}
            icon="snippet"
            valueKey="id"
            value={value}
            inputRef={ref}
            onChange={onChange}
            minChars={0}
            required={false} // since doesnt display errors correctly anyway & not required in angular (although should be)
            loadOptions={async input => {
              const resp = await getSnippets({ accountSlug: props.accountSlug, params: { q: input } })
              return resp.snippets
            }}
            renderOption={(option, { onDeselect, highlighted }) => (
              <ListItem
                size="small"
                onClickRemove={onDeselect ? () => onDeselect(option) : undefined}
                disabled={props.isSubmitting}
                active={highlighted}
                title={option.name}
              />
            )}
          />
        )
      }}
    />
  )
}
