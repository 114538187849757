import { useMemo } from 'react'
import { Meter } from 'grommet'
import styled from 'styled-components'

import {
  Box,
  Button,
  ColorProp,
  Icon,
  IconButton,
  IconName,
  resolveColor,
  themeColor,
  useTheme
} from '@cutover/react-ui'
import {
  PauseRunbookModal,
  ResumeRunbookModal,
  StartRunbookModal,
  UpdateRunModal
} from 'main/components/runbook/modals/run-modals'
import { useSetActiveRightPanelState } from 'main/components/layout/right-panel'
import { TaskListTask } from 'main/services/queries/types'
import { useLanguage } from 'main/services/hooks'
import {
  ActiveRunbookModel,
  ActiveRunbookVersionModel,
  ActiveRunModel,
  CurrentRunbookVersionModel,
  RunbookViewModel
} from 'main/data-access'

const CONTROL_ICONS: { [stage: string]: IconName } = {
  planning: 'play-arrow',
  active: 'pause',
  paused: 'play-arrow',
  complete: 'check-solid',
  cancelled: 'close'
}

const COMMS_ICONS: { [comms: string]: IconName } = {
  off: 'volume-mute',
  on: 'volume-high',
  test: 'volume-low'
}

const COMMS_ICON_COLORS: { [comms: string]: ColorProp } = {
  off: 'text-light',
  on: 'success',
  test: 'warning'
}

export type RunModalAction = 'show-start' | 'show-pause' | 'show-resume'

export const RunbookControl = () => {
  const { t } = useLanguage('pageHeader', { keyPrefix: 'runbook' })
  const theme = useTheme()
  const runbookType = ActiveRunbookModel.useRunbookType()
  const { closeRightPanel } = useSetActiveRightPanelState()

  const openModal = RunbookViewModel.useAction('modal:open')
  const closeModal = RunbookViewModel.useAction('modal:close')
  const { active: activeModal } = RunbookViewModel.useGet('modal')

  const {
    id: runbookId,
    template_type: templateType,
    linked_runbook_details: linkedRunbookDetails,
    timezone
  } = ActiveRunbookModel.useGet()
  const run = ActiveRunModel.useGet()

  const {
    stage,
    id: runbookVersionId,
    auto_start,
    completed_tasks_count,
    tasks_count
  } = ActiveRunbookVersionModel.useGet()

  const { start_scheduled, timing_mode } = CurrentRunbookVersionModel.useGet()
  const progressValue = completed_tasks_count ? (completed_tasks_count / tasks_count) * 100 : 0

  const tasks = [] as TaskListTask[] // TODO: use recoil state for this

  const isTemplate = templateType === 'default'

  const canCreateRun = ActiveRunModel.useCan('create')
  const canPauseRun = ActiveRunModel.useCan('pause')
  const canResumeRun = ActiveRunModel.useCan('resume')
  const canUpdateRun = ActiveRunModel.useCan('update')

  const comms = run?.comms
  const canEditComms = canUpdateRun && stage !== 'complete' && stage !== 'cancelled'

  const handleIconClick = () => {
    closeRightPanel()
    switch (stage) {
      case 'planning':
        return openModal({ type: 'run-start' })
      case 'active':
        return openModal({ type: 'run-pause' })
      case 'paused':
        return openModal({ type: 'run-resume' })
    }
  }

  const handleCommsClick = () => {
    openModal({ type: 'run-update' })
  }

  const runModal = useMemo(() => {
    // const isParentRunbook = tasks.find(task => task.linked_resource && task.linked_resource.id)
    const isParentRunbook = false // TODO: use recoil state for this

    switch (activeModal?.type) {
      case 'run-start':
        const hasParentRunbook = Object.keys(linkedRunbookDetails || {}).length !== 0
        const isLiveRunInParentRunbook = hasParentRunbook && linkedRunbookDetails?.linked_resource?.run_type === 'live'

        return (
          <StartRunbookModal
            onClose={closeModal}
            runbookVersionId={runbookVersionId}
            runbookId={runbookId}
            templateType={templateType}
            hasParentRunbook={hasParentRunbook}
            isLiveRunInParentRunbook={isLiveRunInParentRunbook}
            isScheduled={timing_mode === 'scheduled'}
            autoStart={auto_start}
            startScheduled={start_scheduled}
            timezone={null} // TODO: timezone is handled as a TimezoneType in modal but is a string here
          />
        )
      case 'run-pause':
        return (
          <>
            {run && (
              <PauseRunbookModal
                onClose={closeModal}
                runbookId={runbookId}
                runbookVersionId={runbookVersionId}
                runId={run.id}
                templateType={templateType}
                isParentRunbook={isParentRunbook}
                runType={run.run_type}
                isRunCommsOff={run.comms === 'off'}
              />
            )}
          </>
        )
      case 'run-resume':
        return (
          <>
            {run && (
              <ResumeRunbookModal
                onClose={closeModal}
                runId={run.id}
                runbookVersionId={runbookVersionId}
                runbookId={runbookId}
                templateType={templateType}
                isParentRunbook={isParentRunbook}
                runType={run.run_type}
                isRunCommsOff={run.comms === 'off'}
              />
            )}
          </>
        )
      case 'run-update':
        return run && <UpdateRunModal onClose={closeModal} />
      default:
        return null
    }
  }, [
    activeModal?.type,
    closeModal,
    runbookId,
    runbookVersionId,
    templateType,
    linkedRunbookDetails,
    runbookType,
    timezone,
    tasks
  ])

  const showControl = () => {
    if (runbookVersionId && !isTemplate && !runbookType?.incident) {
      if (
        (stage === 'planning' &&
          canCreateRun &&
          tasks_count > 0 &&
          !linkedRunbookDetails?.current_status?.linked_resource) ||
        (stage === 'active' && canPauseRun) ||
        (stage === 'paused' && canResumeRun) ||
        stage === 'cancelled' ||
        (stage === 'complete' && !linkedRunbookDetails?.current_status?.linked_resource)
      ) {
        return true
      }
    }
    return false
  }

  return (
    <>
      <Box css="position: relative">
        {stage === 'planning' ? (
          <DashedCircle />
        ) : (
          <Meter
            type="circle"
            background="bg-4"
            size="52px"
            css={{ minWidth: '52px', maxWidth: '52px' }}
            thickness="5px"
            values={[{ value: progressValue, color: run?.run_type === 'rehearsal' ? 'text-disabled' : 'primary' }]}
          />
        )}
        {stage && showControl() ? (
          <Box
            direction="row"
            align="center"
            css="position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%)"
          >
            {stage === 'cancelled' ? (
              <Icon icon="close" size="32px" color="bg-4" aria-hidden="true" aria-label="icon-cancelled" />
            ) : (
              <Button
                data-testid={`runbook-control-${stage}-button`}
                plain
                icon={CONTROL_ICONS[stage]}
                a11yTitle={stage === 'active' ? t('pauseRun') : stage === 'complete' ? t('runComplete') : t('startRun')}
                onClick={handleIconClick}
                className={stage}
                css={`
                  border-radius: 50%;
                  padding: 5px;
                  cursor: pointer;
                  svg {
                    fill: ${run?.run_type === 'live' && stage === 'complete'
                      ? resolveColor('primary', theme)
                      : resolveColor('text-light', theme)};
                  }
                  &.complete {
                    cursor: default;
                  }
                  &:not(.complete):hover {
                    svg {
                      fill: ${resolveColor('text', theme)};
                    }
                  }
                `}
              />
            )}
          </Box>
        ) : null}
        {comms && (
          <Box css="position: absolute; right: -3px; bottom: -3px;">
            <IconButton
              icon={COMMS_ICONS[comms]}
              size="small"
              label={comms === 'off' ? t('commsOff') : comms === 'test' ? t('commsTest') : t('commsOn')}
              onClick={handleCommsClick}
              disabled={!canEditComms}
              data-testid="runbook-comms-button"
              css={`
                opacity: 1 !important;
                background: ${resolveColor('bg', theme)};
                svg {
                  fill: ${resolveColor(COMMS_ICON_COLORS[comms], theme)};
                }
                &:hover {
                  background: ${resolveColor('bg-1', theme)};
                  svg {
                    fill: ${resolveColor(COMMS_ICON_COLORS[comms], theme)};
                  }
                }
              `}
            />
          </Box>
        )}
      </Box>
      {runModal}
    </>
  )
}

const DashedCircle = styled(Box)`
  width: 52px;
  height: 52px;
  border: 4px dashed ${themeColor('bg-3')};
  border-radius: 50%;
`
