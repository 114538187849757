import { FocusEventHandler } from 'react'

import { BrandIconName } from '@cutover/icons'
import { themeColor } from '../../theme'
import { Box, Grid } from '../../layout'
import { BrandIcon } from '../../icon/brand-icon'
import { FormField } from '../internal/form-field'

export interface IconSelectProps {
  label?: string
  iconNames?: BrandIconName[]
  value?: string | null | undefined
  disabled?: boolean
  readOnly?: boolean
  required?: boolean
  onSelect: (id: string) => void
  selectedColor: string
  onFocus?: FocusEventHandler<HTMLDivElement>
  onBlur?: FocusEventHandler<HTMLDivElement>
}

export function IconSelect({
  label,
  iconNames = [],
  value,
  disabled,
  readOnly,
  required,
  onSelect,
  onFocus,
  onBlur,
  selectedColor
}: IconSelectProps) {
  return (
    <FormField label={label} labelSize="small" disabled={disabled} readOnly={readOnly} required={required}>
      <Grid minWidth={40} gap={4} onFocus={onFocus} onBlur={onBlur} css="width: 100%; margin: 8px 0">
        {iconNames.map(name => {
          return (
            <Box
              key={name}
              onClick={() => onSelect(name)}
              css={`
                width: 32px;
                height: 32px;
                align-items: center;
                justify-content: center;
                border-radius: 8px;
                background-color: ${name === value ? selectedColor : themeColor('bg')};
                &:hover {
                  background-color: ${name === value ? selectedColor : themeColor('bg-1')};
                }
              `}
            >
              <BrandIcon icon={name} color={name === value ? 'bg' : 'text-light'} />
            </Box>
          )
        })}
      </Grid>
    </FormField>
  )
}
