import { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import * as yup from 'yup'

import { useWorkspaceView } from '../../layout/sidebar/hooks'
import { getSavedViewURL } from 'main/components/shared/filter/filter-params'
import { FormModal, RadioboxGroupField, SelectField, TextInputField } from 'main/components/shared/form'
import { useLanguage } from 'main/services/hooks'
import { usePermissions } from 'main/services/queries/use-permissions'
import {
  CreateSavedViewResponse,
  SavedViewGroup,
  useCreateSavedView,
  useSavedViewGroups
} from 'main/services/queries/use-saved-view-groups'
import { ConfigModel, CurrentUserModel } from 'main/data-access'

const validationSchema = yup.object({
  name: yup.string().required(),
  group_option: yup.string(),
  group_name: yup.string().when('group_option', {
    is: (val: string) => val === 'group',
    then: schema => schema.required()
  })
})

type CreateSavedViewForm = yup.InferType<typeof validationSchema>

type CreateSavedViewModalProps = {
  accountId: number
  accountSlug: string
  runbookName?: string
  runbookId?: number
  onClose?: () => void
}

export const CreateSavedViewModal = ({
  accountId,
  accountSlug,
  runbookName,
  runbookId,
  onClose
}: CreateSavedViewModalProps) => {
  const { t } = useLanguage('runbooks', { keyPrefix: 'savedView' })
  const currentUser = CurrentUserModel.useGet()
  const navigate = useNavigate()
  const workspaceView = useWorkspaceView()
  const isReactRunbookEnabled = ConfigModel.useIsFeatureEnabled('react_runbook')
  const { data, isLoading: isLoadingSavedViewGroups } = useSavedViewGroups(accountId)
  const savedViewGroups = data?.saved_view_groups
  const { dashboardId } = useParams()
  const { mutateAsync: createSavedView } = useCreateSavedView(accountId)

  const handleSuccess = (data: CreateSavedViewResponse) => {
    // TODO: can we pull something out to share this logic with nav-item-saved-view.tsx?
    const queryObject = JSON.parse(data.filter.query_string)
    const navPath = getSavedViewURL({ ...queryObject, accountSlug }, isReactRunbookEnabled)

    navigate(navPath, {
      state: {
        activeSavedView: data.filter
      }
    })
  }

  const workspaceViewToSavedViewObject = () => {
    return {
      ...workspaceView?.filters,
      accountId: accountSlug,
      ...(workspaceView?.display === 'dashboard' && { dashboardId }),
      _display: workspaceView?.display
    }
  }

  // Note: as per angular, runbook filters are not saved in the saved view, it is just a link to the runbook
  const runbookToSavedViewObject = () => {
    return {
      accountId: accountSlug, // Many inconsistencies with IDs vs slugs
      runbookId,
      runbookVersionId: 'current_version',
      _display: 'runbook'
    }
  }

  const transformer = (data: CreateSavedViewForm) => {
    const isGroupOptionChecked = data.group_option === 'group'
    const groupId = isGroupOptionChecked && savedViewGroups?.find(group => group.name === data.group_name)?.id

    const savedView = runbookId ? runbookToSavedViewObject() : workspaceViewToSavedViewObject()
    return {
      account_id: accountId,
      name: data.name,
      user_id: currentUser.id,
      query_string: JSON.stringify(savedView),
      resource_type: 'Runbook',
      saved_view_group_id: groupId || undefined
    }
  }

  // TODO: determine why using name as value key vs id
  const groupNames =
    savedViewGroups?.filter(group => group.id > 0)?.map((o: SavedViewGroup) => ({ value: o.name, label: o.name })) ?? []

  return (
    <FormModal<CreateSavedViewForm>
      open
      title={t('modal.title')}
      confirmText={t('modal.confirmText')}
      confirmIcon="save"
      loading={isLoadingSavedViewGroups}
      onClose={onClose}
      onSuccess={handleSuccess}
      onSubmit={createSavedView}
      schema={validationSchema}
      successMessage={t('notification.success.message')}
      transformer={transformer}
      description={t('modal.description')}
      defaultValues={{
        name: runbookName ?? '',
        group_option: 'private',
        // auto select the first group name if there is only one
        group_name: groupNames.length === 1 ? groupNames[0].value : ''
      }}
    >
      <Fields groupNames={groupNames} />
    </FormModal>
  )
}

const Fields = ({ groupNames }: { groupNames: { value: string; label: string }[] }) => {
  const { t } = useLanguage('runbooks', { keyPrefix: 'savedView' })

  const permissions = usePermissions('saved-view-group')
  const canUpdate = permissions('update')

  const { watch, resetField } = useFormContext<CreateSavedViewForm>()
  const groupOption = watch('group_option')

  useEffect(() => {
    if (groupOption !== 'group') resetField('group_name')
  }, [groupOption])

  const groupOptions = groupNames.length
    ? [
        { value: 'private', label: t('modal.privateButton') },
        { value: 'group', label: t('modal.publicGroupButton') }
      ]
    : [{ value: 'private', label: t('modal.privateButton') }]

  return (
    <>
      <TextInputField<CreateSavedViewForm>
        autoFocus
        name="name"
        label={t('modal.nameLabel')}
        // No idea why this specific input is triggering a 1password icon. Per discussion this is wildly inconsistent and can happen
        // adding this attribute for now. see https://1password.community/discussion/117501/as-a-web-developer-how-can-i-disable-1password-filling-for-a-specific-field/p3
        data-1p-ignore
      />
      {canUpdate && (
        <RadioboxGroupField<CreateSavedViewForm>
          name={'group_option'}
          label={t('modal.sharingOptionsLabel')}
          direction="row"
          options={groupOptions}
        />
      )}
      {groupOption === 'group' && (
        <SelectField<CreateSavedViewForm>
          name="group_name"
          options={groupNames}
          css="width: 100%"
          label={t('modal.selectLabel')}
          required // hard coding becuase conditional schema makes it more difficult to determine if required via schedma.describe since it depensd on current form state
        />
      )}
    </>
  )
}
