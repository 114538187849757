import { useState } from 'react'
import { Heading } from 'grommet'
import styled from 'styled-components'

import { Box, Button } from '@cutover/react-ui'
import { useLanguage } from 'main/services/hooks'
import { CreateRunbookTypeModal } from '../modals/create-runbook-type-modal'
import { usePermissions } from 'main/services/queries/use-permissions'

export const RunbookTypesHeader = () => {
  const [isCreateModalOpen, setCreateModalOpen] = useState(false)
  const { t } = useLanguage('runbookTypes')
  const permissions = usePermissions('runbook-types')
  const canCreateRunbookTypes = permissions('create')

  const handleClickCreate = () => {
    setCreateModalOpen(true)
  }

  return (
    <Box direction="row" width="100%">
      <Box flex="grow" justify="center">
        <RunbookTypesTitle responsive={false} margin={{ left: 'xsmall', right: 'none', vertical: 'none' }} level="1">
          {t('header.title')}
        </RunbookTypesTitle>
      </Box>
      <Box>
        {canCreateRunbookTypes && (
          <Button
            data-testid="add-runbook-type-button"
            icon="add"
            primary
            label={t('header.addButton.label')}
            onClick={handleClickCreate}
          />
        )}
      </Box>
      <CreateRunbookTypeModal open={isCreateModalOpen} setOpen={setCreateModalOpen} />
    </Box>
  )
}

const RunbookTypesTitle = styled(Heading)`
  font-size: 20px;
  line-height: 1.2;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  position: relative;
`
