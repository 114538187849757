import { eventManager } from 'event-manager'

import { Modal } from '@cutover/react-ui'
import { EnterSnippetDetails } from './enter-snippet-details'
import { useLanguage } from 'main/services/hooks'

type CreateSnippetModalsProps = {
  accountId: string | number
  isModalOpen?: boolean
  setIsModalOpen?: (isModalOpen: boolean) => void
}

export const CreateSnippetModal = ({ accountId, isModalOpen = true, setIsModalOpen }: CreateSnippetModalsProps) => {
  const { t } = useLanguage('runbooks')

  const unmountConnector = () => {
    eventManager.emit('hide-create-snippet-modals')
  }

  const closeEnterSnippetDetailsModalWithUnmount = () => {
    setIsModalOpen?.(false)
    unmountConnector()
  }

  return (
    <>
      <Modal
        title={t('enterSnippetDetails.heading')}
        width={554}
        open={isModalOpen}
        onClose={closeEnterSnippetDetailsModalWithUnmount}
        hideFooter
      >
        <EnterSnippetDetails closeModal={closeEnterSnippetDetailsModalWithUnmount} accountId={accountId} />
      </Modal>
    </>
  )
}
