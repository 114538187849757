import { ReactNode, useCallback, useEffect } from 'react'
import { getUnixTime } from 'date-fns'
import { eventManager } from 'event-manager'
import Cookies from 'js-cookie'
import { useIdleTimer } from 'react-idle-timer'
import { useQueryClient } from 'react-query'
import { useLocation, useNavigate } from 'react-router-dom'
import { useUnmount } from 'react-use'

import { LoadingPanel, useToggleFilterPanel } from '@cutover/react-ui'
import { CurrentUserSetter } from 'main/components/authentication/current-user-setter'
import { useSidebarNavContext } from 'main/components/layout/sidebar/nav-context'
import { CurrentUser, useGetValidateToken } from 'main/services/queries/use-get-validate-token'
import { useLogout } from 'main/services/queries/use-logout'
import { useGetConfigs } from 'main/services/queries/use-get-configs'
import { ConfigModel, CurrentUserModel } from 'main/data-access'

const LOGIN_PATH = '/login'

export type AuthHeaders = {
  'access-token': string
  'token-type': string
  client: string
  expiry: string
  uid: string
}

export const Session = ({ children, ...props }: { children: ReactNode }) => {
  const isReactLoginEnabled = ConfigModel.useIsFeatureEnabled('react_login')

  return isReactLoginEnabled ? (
    <SessionTimer {...props}>{children}</SessionTimer>
  ) : (
    <CurrentUserSetter>{children}</CurrentUserSetter>
  )
}

const SessionTimer = ({ children }: { children: ReactNode }) => {
  const { toggleLeftSidebar } = useSidebarNavContext()
  const toggleFilterPanel = useToggleFilterPanel()
  const queryClient = useQueryClient()
  const setCurrentUser = CurrentUserModel.useSetCurrentUser()
  const { data } = useGetConfigs()

  const navigate = useNavigate()
  const location = useLocation()

  const getAuthHeaders = () => JSON.parse(Cookies.get('auth_headers') || '{}')
  const sessionTimeout = (parseInt(getAuthHeaders().expiry, 10) - getUnixTime(Date.now())) * 1000

  const { mutate: logout, isLoading: isLoadingLogout } = useLogout({
    onSuccess: () => {
      if (data?.customSessionExpiryRedirect) {
        window.location.href = data.customSessionExpiryRedirect
      } else {
        Cookies.remove('auth_headers')
        setCurrentUser(null)
        localStorage.setItem('reactLogout', JSON.stringify({ success: ['logout'] }))
        queryClient.clear()
        navigate(LOGIN_PATH)
      }
    }
  })

  const handleSuccessValidateToken = useCallback((user: CurrentUser) => {
    eventManager.emit('login', user)
    sessionStorage.removeItem('reactLoginRedirect')
  }, [])

  const handleErrorValidateUser = useCallback(() => {
    Cookies.remove('auth_headers')
    setCurrentUser(null)
    navigate(LOGIN_PATH)
  }, [])

  const { isLoading: isLoadingValidateToken, data: userData } = useGetValidateToken({
    onError: handleErrorValidateUser,
    onSuccess: handleSuccessValidateToken
  })

  const onIdle = () => {
    Cookies.remove('auth_headers')
    if (data?.customSessionExpiryRedirect && getTotalActiveTime() >= sessionTimeout) {
      window.location.href = data.customSessionExpiryRedirect
    } else {
      setCurrentUser(null)
      sessionStorage.setItem('reactLoginRedirect', `${location.pathname}${location.search}`)
      localStorage.setItem('showRedirectMessage', 'true')
      navigate(LOGIN_PATH)
      eventManager.emit('session-expiry')
    }
  }

  const { reset, getTotalActiveTime } = useIdleTimer({
    onIdle,
    timeout: sessionTimeout,
    events: []
  })

  const updateSession = useCallback(
    (authHeaders: AuthHeaders) => {
      const currentExpiry = parseInt(getAuthHeaders().expiry, 10)
      const newExpiry = parseInt(authHeaders.expiry, 10)

      if (currentExpiry < newExpiry) {
        Cookies.set('auth_headers', authHeaders)
        reset()
      }
    },
    [reset]
  )

  const handleValidateToken = () => {
    // TODO: use internal helper or update helper to getJSON?
    const expiry = Cookies.getJSON('auth_headers')?.expiry
    const now = Math.trunc(new Date().getTime() / 1000)

    if (expiry && parseInt(expiry, 10) < now) {
      handleErrorValidateUser()
    }
  }

  useEffect(() => {
    eventManager.on('token-update', updateSession)
    eventManager.on('token-validate', handleValidateToken)
    eventManager.on('logout', logout)

    return () => {
      eventManager.off('token-update', updateSession)
      eventManager.off('token-validate', handleValidateToken)
      eventManager.off('logout', logout)
    }
  }, [])

  useUnmount(() => {
    toggleLeftSidebar(false, true)
    toggleFilterPanel(false)
  })

  if (isLoadingValidateToken || isLoadingLogout) return <LoadingPanel />
  return <CurrentUserSetter user={userData}>{children}</CurrentUserSetter>
}
