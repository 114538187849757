import { format as formatDate, fromUnixTime, getUnixTime } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'

import { TaskItemDateData } from '@cutover/react-ui'

export const createDateDisplayForScheduled = ({
  startDisplay,
  previousTaskStartDisplay,
  activeTimezone
}: {
  startDisplay: number | null
  previousTaskStartDisplay?: number
  activeTimezone?: string | null
}) => {
  const parseDateTime = (timestamp: number | undefined | null, tz?: string | null) => {
    if (!timestamp) return [null, null, null]
    const date = fromUnixTime(timestamp as number)
    const zonedDate = tz ? utcToZonedTime(date, tz) : date
    return timestamp ? formatDate(zonedDate, 'd LLL HH:mm').split(' ') : [null, null, null]
  }

  const [day, month, time] = parseDateTime(startDisplay, activeTimezone)
  const [pDay, pMonth, pTime] = parseDateTime(previousTaskStartDisplay, activeTimezone)

  //active tz is null if not set or same as local. if it is set, we need to show additional localtz col
  const [localDay, , localTime] = activeTimezone ? parseDateTime(startDisplay) : [null, null, null]

  let localDayDiff = undefined
  if (localDay !== null && day !== null) {
    const dayDiff = parseInt(localDay) - parseInt(day)
    // Use a trick to avoid more date initialization
    if (dayDiff === 1 || dayDiff < -1) {
      localDayDiff = '+1 day'
    } else if (dayDiff === -1 || dayDiff > 1) {
      localDayDiff = '-1 day'
    }
  }

  // Dates/Times do not get shown if same as previous. However still need to be returned for sticky behaviour
  const showDay = !previousTaskStartDisplay || pDay !== day || pMonth !== month
  const showTime = !previousTaskStartDisplay || (time && pTime !== time)

  const dateData: TaskItemDateData = {
    date: `${day} ${month}`,
    time: time ?? '',
    showDate: !!showDay,
    showTime: !!showTime,
    localTime: localTime ?? '',
    localDayDiff: localDayDiff
  }

  return dateData
}

export const createDateDisplayForUnscheduled = ({
  taskStartDate,
  runbookStartPlanned,
  previousStartDisplay,
  dayLabel
}: {
  taskStartDate: number
  runbookStartPlanned: number
  previousStartDisplay?: number | null
  dayLabel: string
}) => {
  const currentTaskDay = calculateDayNumber({ taskValue: taskStartDate, dayZeroValue: runbookStartPlanned })
  const currentTaskTimeStamp = formatDate(new Date(taskStartDate * 1000), 'HH:mm')

  const previousTaskTimeStamp = previousStartDisplay && formatDate(new Date(previousStartDisplay * 1000), 'HH:mm')
  const previousTaskDay = previousStartDisplay
    ? calculateDayNumber({
        taskValue: previousStartDisplay,
        dayZeroValue: runbookStartPlanned
      })
    : null

  const showDay = !previousStartDisplay || previousTaskDay !== currentTaskDay
  const showTime = !previousTaskTimeStamp || previousTaskTimeStamp !== currentTaskTimeStamp

  const dateData: TaskItemDateData = {
    date: `${dayLabel} ${currentTaskDay}`,
    time: currentTaskTimeStamp,
    showDate: !!showDay,
    showTime: !!showTime
  }

  return dateData
}

export const calculateDayNumber = ({ taskValue, dayZeroValue }: { taskValue: number; dayZeroValue: number }) => {
  const SECONDS_IN_DAY = 60 * 60 * 24
  return (
    Math.floor(
      (getUnixTime(new Date(taskValue * 1000)) - getUnixTime(new Date(dayZeroValue * 1000))) / SECONDS_IN_DAY
    ) + 1
  )
}
