import { memo, ReactNode, useEffect, useState } from 'react'
import styled, { css } from 'styled-components/macro'

import { Accordion, AccordionPanel, AccordionProps } from './accordion'
import { Pill } from '../../pill'
import { Box } from '../box'
import { useFilterPanelOpenState } from '../layout-hooks'

type FilterAccordionProps = AccordionProps & {
  isLayoutFilter?: boolean
}

export const FilterAccordion = ({ isLayoutFilter, ...restProps }: FilterAccordionProps) => {
  const isFilterPanelOpen = useFilterPanelOpenState()
  const [disableAnimation, setDisableAnimation] = useState<boolean | undefined>(!isFilterPanelOpen)

  useEffect(() => {
    if (!isFilterPanelOpen) {
      setDisableAnimation(true)
    } else {
      setDisableAnimation(undefined)
    }
  }, [isFilterPanelOpen])

  return (
    <StyledFilterAccordion
      a11yTitle="Filters" // defaults to Filters but can be overridden with more specific name
      disableAnimation={disableAnimation}
      isLayoutFilter={isLayoutFilter}
      {...restProps}
    />
  )
}

type FilterAccordionPanelProps = {
  label: string
  children: ReactNode
  applied?: boolean
  // TODO: check if we still want to enable overriding this
  /** defaults to "applied" */
  appliedLabel?: string
  initialActive?: boolean
  suffix?: ReactNode
  a11yTitle?: string
}

export const FilterAccordionPanel = memo(
  ({ label, children, applied, appliedLabel, ...restProps }: FilterAccordionPanelProps) => {
    return (
      <AccordionPanel
        label={label}
        labelSuffix={
          !!applied ? (
            <Pill css="margin-left: 4px; flex-shrink: 0;" label={appliedLabel ?? 'applied'} size="small" />
          ) : undefined
        }
        {...restProps}
      >
        <Box flex={false}>{children}</Box>
      </AccordionPanel>
    )
  }
)

// TODO: check what/if styles needed
// TODO: doesn't seem right that this references things that are defined in layout in the app
// but would like to have filter accordion stories so will resolve if we ever combine react-ui and the app
export const StyledFilterAccordion = styled(Accordion)<FilterAccordionProps>`
  ${({ isLayoutFilter }) =>
    isLayoutFilter &&
    css`
      #page-root:not(.filter-panel-open) & {
        transition: none;
      }

      .accordion-item-content {
        opacity: 1;

        #page-root.filter-panel-open & {
          opacity: 1;
        }
      }
    `}
`
