import { eventManager } from 'event-manager'
import { useLocation, useNavigate } from 'react-router-dom'

import { IconName, MenuListItemProps, useNotify } from '@cutover/react-ui'
import { useSavedViewPermissions, useSidebarNavigate } from '../hooks'
import { NavItem } from '../nav-item'
import { getQueryFromSavedView, getSavedViewURL } from 'main/components/shared/filter/filter-params'
import { useLanguage } from 'main/services/hooks'
import {
  SavedView,
  SavedViewGroup,
  useDeleteSavedView,
  useUpdateSavedView
} from 'main/services/queries/use-saved-view-groups'
import { ConfigModel, CurrentUserModel } from 'main/data-access'

export const NavItemSavedView = ({
  savedView,
  accountId,
  isGlobal,
  groups
}: {
  isGlobal?: boolean
  accountId: string | number
  savedView: SavedView
  groups: SavedViewGroup[]
}) => {
  const { t } = useLanguage('sidebarNav', { keyPrefix: 'defaultNav' })
  const notify = useNotify()
  const location = useLocation()
  const navigate = useSidebarNavigate()
  const navigateReplaceState = useNavigate()
  const currentUser = CurrentUserModel.useGet()
  const queryObject = JSON.parse(savedView.query_string)
  const { accountId: accountSlug } = queryObject
  const isSavedViewActive = (location.state as any)?.activeSavedView?.id === savedView.id
  const userCan = useSavedViewPermissions(accountId)
  const canMove = userCan('update')
  const isReactRunbookEnabled = ConfigModel.useIsFeatureEnabled('react_runbook')

  const { mutate: updateSavedView } = useUpdateSavedView(accountId, {
    onSuccess: data => {
      // need the pill in the subheader to update
      if (isSavedViewActive) {
        navigateReplaceState(`${location.pathname}${location.search}`, {
          state: { activeSavedView: data.filter },
          replace: true
        })
      }
      notify.success(t('notification.savedViewUpdatedSuccess'))
    },
    onError: () => notify.error(t('notification.savedViewUpdatedError'))
  })

  const { mutate: deleteSavedView } = useDeleteSavedView(accountId, {
    onSuccess: () => notify.success(t('notification.savedViewDeletedSuccess')),
    onError: () => notify.error(t('notification.savedViewDeletedError'))
  })

  const handleClickSavedView = () => {
    if (isSavedViewActive) return

    const queryFromSavedView = getQueryFromSavedView({ ...queryObject, accountSlug })
    const navPath = getSavedViewURL(queryFromSavedView, isReactRunbookEnabled)

    navigate(navPath, {
      state: {
        activeSavedView: savedView
      }
    })
  }

  const handleClickRenameSavedView = (newName: string) => {
    // TODO: handle case for updating angular if failed to rename/refresh angular saved view data
    if (isSavedViewActive) {
      eventManager.emit('react-update-active-saved-view', { view: { ...savedView, name: newName } })
    }

    updateSavedView({
      id: savedView.id,
      name: newName
    })
  }

  const handleClickDeleteSavedView = () => {
    // TODO: handle case for updating angular if failed to delete/refresh angular saved view data
    if (isSavedViewActive) {
      eventManager.emit('react-update-active-saved-view', { view: undefined })
    }

    deleteSavedView(savedView.id)
  }

  const moveItems: MenuListItemProps[] =
    !canMove || (!isGlobal && !groups.length)
      ? []
      : [
          ...(isGlobal && savedView.user_id === currentUser.id
            ? [
                {
                  label: t('makeSavedViewPrivate'),
                  icon: 'visibility-hide',
                  onClick: () => {
                    updateSavedView({
                      id: savedView.id,
                      saved_view_group_id: null,
                      global: false
                    })
                  }
                } as MenuListItemProps
              ]
            : []),
          ...groups.map(group => {
            return {
              label: group.name,
              onClick: () => {
                updateSavedView({
                  id: savedView.id,
                  saved_view_group_id: group.id
                })
              }
            } as MenuListItemProps
          })
        ]

  return (
    <NavItem
      key={savedView.id}
      label={savedView.name}
      level={2}
      icon={savedView.resource_type === 'Event' ? 'calendar' : (queryObject._display as IconName)}
      onClick={handleClickSavedView}
      isActive={isSavedViewActive}
      onRename={handleClickRenameSavedView}
      onDelete={handleClickDeleteSavedView}
      moveMenuItems={moveItems}
    />
  )
}
