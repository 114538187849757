import { useRef } from 'react'

export const useStickyDates = ({
  wrapperId,
  stickyDayId,
  stickyTimeId,
  stickyLocalTimeId,
  itemHeight
}: {
  wrapperId: string
  stickyDayId: string
  stickyTimeId: string
  stickyLocalTimeId: string | null
  itemHeight: number
}) => {
  const lastScrollTopRef = useRef(0)
  const topElemIndexRef = useRef<number | null>(null)

  const wrapper = document.getElementById(wrapperId)
  const stickyDayContainer = document.getElementById(stickyDayId)
  const stickyTimeContainer = document.getElementById(stickyTimeId)
  const stickyLocalTimeContainer = stickyLocalTimeId ? document.getElementById(stickyLocalTimeId) : null

  const updateSticky = (type: 'day' | 'time' | 'localTime', text?: string | null) => {
    const container =
      type === 'day' ? stickyDayContainer : type === 'time' ? stickyTimeContainer : stickyLocalTimeContainer
    if (!container) return
    container.textContent = text || '' // Set text or clear if text is undefined/null
  }

  const handleScroll = (e: React.UIEvent<'div'>) => {
    // TS error when HTMLDivElement is used
    const target = e.target as HTMLDivElement
    const direction = target.scrollTop > lastScrollTopRef.current ? 'down' : 'up'
    const topElemIndex = Math.floor(target.scrollTop / itemHeight)
    if (topElemIndex === topElemIndexRef.current) return // Prevent unnecessary calculations
    topItemChanged(topElemIndex, direction)
    topElemIndexRef.current = topElemIndex
    lastScrollTopRef.current = target.scrollTop
  }

  const topItemChanged = (topElemIndex: number, direction: 'down' | 'up') => {
    if (!stickyDayContainer || !wrapper) return

    const updateDom = (
      type: 'day' | 'time' | 'localTime',
      currentText: string | null,
      nextText: string | null,
      currentElem: Element | null,
      nextElem: Element | null
    ) => {
      const isSameContent = currentText === nextText

      if (isSameContent) {
        updateSticky(type, currentText)
        currentElem?.classList.add('hide')
        nextElem?.classList.add('hide')
      } else {
        updateSticky(type)
        currentElem?.classList.remove('hide')
        nextElem?.classList.remove('hide')
      }
    }

    const [currentIndex, nextIndex] =
      direction === 'down' ? [topElemIndex, topElemIndex + 1] : [topElemIndex + 1, topElemIndex]

    const currentElem = wrapper.querySelector(`[data-index="${currentIndex}"]`)
    const nextElem = wrapper.querySelector(`[data-index="${nextIndex}"]`)
    if (!currentElem || !nextElem) return
    const currentDayElem = currentElem.querySelector('span.date-day')
    const nextDayElem = nextElem.querySelector('span.date-day')
    const currentTimeElem = currentElem.querySelector('span.date-time')
    const nextTimeElem = nextElem.querySelector('span.date-time')
    const currentLocalTimeElem = currentElem.querySelector('span.date-local-time')
    const nextLocalTimeElem = nextElem.querySelector('span.date-local-time')

    updateDom('day', currentDayElem?.textContent ?? null, nextDayElem?.textContent ?? null, currentDayElem, nextDayElem)
    updateDom(
      'time',
      currentTimeElem?.textContent ?? null,
      nextTimeElem?.textContent ?? null,
      currentTimeElem,
      nextTimeElem
    )
    if (stickyLocalTimeContainer) {
      updateDom(
        'localTime',
        currentLocalTimeElem?.textContent ?? null,
        nextLocalTimeElem?.textContent ?? null,
        currentLocalTimeElem,
        nextLocalTimeElem
      )
    }
  }

  return { handleScroll }
}
