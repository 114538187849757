import { memo } from 'react'

import { CheckboxGroup, FilterAccordionPanel } from '@cutover/react-ui'
import { toSnakeCase } from '@cutover/api'
import { ToggleControl } from '../shared/toggle-control'
import {
  useAssignedFilter,
  useClearFilterState,
  useEndRequirementsFilter,
  useStartRequirementsFilter
} from 'main/recoil/data-access'
import { useLanguage } from 'main/services/hooks'
import { ConfigModel } from 'main/data-access'

export const PermissionsGroup = memo(() => {
  const { t } = useLanguage('common', { keyPrefix: 'filter' })
  const [assigned] = useAssignedFilter()
  const [startRequirements] = useStartRequirementsFilter()
  const [endRequirements] = useEndRequirementsFilter()

  const applied = [assigned, startRequirements, endRequirements].some(filter => filter !== undefined)

  return (
    <FilterAccordionPanel label={t('permissions')} applied={applied}>
      <AssignedToggle />
      <StartRequirementsFilter />
      <EndRequirementsFilter />
    </FilterAccordionPanel>
  )
})

const AssignedToggle = () => {
  const [value, setValue] = useAssignedFilter()

  return <ToggleControl filterKey="a" value={value} setValue={setValue} />
}

const StartRequirementsFilter = memo(() => {
  const { t } = useLanguage('common', { keyPrefix: 'filter.runbook' })
  const [value, setValue] = useStartRequirementsFilter()
  const clearValue = useClearFilterState('sr')
  const { startRequirements } = ConfigModel.useGet()

  return (
    <CheckboxGroup
      plain
      a11yTitle={t('sr')}
      options={Object.entries(startRequirements).map(([key, value]) => ({
        value: toSnakeCase(key),
        label: value.name
      }))}
      value={value}
      onChange={(event: any) => {
        const value = event.value
        if (!value || value.length === 0) {
          clearValue()
        }
        setValue(value)
      }}
    />
  )
})

const EndRequirementsFilter = memo(() => {
  const { t } = useLanguage('common', { keyPrefix: 'filter.runbook' })
  const [value, setValue] = useEndRequirementsFilter()
  const clearValue = useClearFilterState('er')
  const { endRequirements } = ConfigModel.useGet()

  return (
    <CheckboxGroup
      plain
      a11yTitle={t('er')}
      options={Object.entries(endRequirements).map(([key, value]) => ({
        value: toSnakeCase(key),
        label: value.name
      }))}
      value={value}
      onChange={(event: any) => {
        const value = event.value
        if (!value || value.length === 0) {
          clearValue()
        }
        setValue(value)
      }}
    />
  )
})
