import { useCallback } from 'react'
import { useRecoilCallback, useRecoilValue, useSetRecoilState } from 'recoil'
import { produce } from 'immer'

import { CurrentUserModelType } from 'main/data-access/models/current-user-model'
import { currentUserIdState, currentUserState } from 'main/recoil/current-user'
import { CurrentUser, OauthClientSession } from 'main/services/queries/use-get-validate-token'
import { FrontendUserSettingType } from 'main/services/queries/types'

export const useGetCurrentUser: CurrentUserModelType['useGet'] = () => useRecoilValue(currentUserState) as CurrentUser

export const useGetCurrentUserCallback: CurrentUserModelType['useGetCallback'] = () =>
  useRecoilCallback(
    ({ snapshot }) =>
      async () =>
        (await snapshot.getPromise(currentUserState)) as CurrentUser,
    []
  )

export const useGetCurrentUserId: CurrentUserModelType['useId'] = () => useRecoilValue(currentUserIdState)

export const useSetCurrentUser: CurrentUserModelType['useSetCurrentUser'] = () => useSetRecoilState(currentUserState)

export const useUpdateFrontendUserSettings: CurrentUserModelType['useUpdateFrontendUserSettings'] = () => {
  const updateSettings = useSetRecoilState(currentUserState)

  return useCallback(
    ({ payload }: { payload: FrontendUserSettingType['data'] }) =>
      updateSettings(prevUser => {
        if (prevUser) {
          const newSettings = { ...prevUser?.frontend_user_setting, data: payload }
          return { ...prevUser, frontend_user_setting: { ...newSettings } }
        } else {
          return null
        }
      }),
    [updateSettings]
  )
}

export const useUpdateOauthClientSessionCallback: CurrentUserModelType['useUpdateOauthClientSessionCallback'] = () => {
  return useRecoilCallback(
    ({ set }) =>
      async ({ oauthClientSessions }: { oauthClientSessions: OauthClientSession[] }) => {
        set(currentUserState, prevUser =>
          produce(prevUser, draft => {
            if (draft) {
              draft.oauth_client_sessions = oauthClientSessions
            }
          })
        )
      },
    []
  )
}
