import { useCallback, useMemo } from 'react'

import { Box, ColumnConfig, RunbookTypeIcon, Table, Text } from '@cutover/react-ui'
import { useLanguage } from 'main/services/hooks'
import { RunbookTypeType } from 'main/services/queries/types'
import { useRunbookTypesQuery } from './use-runbook-type'
import { useSetActiveRightPanelState } from 'main/components/layout/right-panel'
/**
 * Note: We're using recoil sync to get applied filters in this global admin settings page.
 * This is *not* a pattern CFE is recommending as Recoil should only be using within the context
 * of a runbook currently, and we will transition away from Recoil in the future.
 */
import { useAppliedFilters } from 'main/recoil/data-access'

export const RunbookTypesListPage = () => {
  const { t } = useLanguage('runbookTypes')
  const { openRightPanel } = useSetActiveRightPanelState()

  const appliedFilters = useAppliedFilters() || {}

  const { data: runbookTypesData } = useRunbookTypesQuery(appliedFilters)
  const items = runbookTypesData?.runbook_types || []

  const runbookTypeColumns: ColumnConfig<RunbookTypeType>[] = useMemo(() => {
    return [
      {
        header: t('list.tableHeadings.icon'),
        property: 'display_name',
        sortable: false,
        render: (datum: RunbookTypeType) => (
          <RunbookTypeIcon size="small" icon={datum.icon_name} color={datum.icon_color} />
        )
      },
      {
        header: t('list.tableHeadings.name'),
        property: 'name',
        sortable: true
      },
      {
        header: t('list.tableHeadings.accountName'),
        property: 'account_name',
        sortable: true
      },
      {
        header: t('list.tableHeadings.default'),
        property: 'default',
        sortable: true,
        render: (datum: RunbookTypeType) => (datum.default ? <YesText /> : <NoText />)
      },
      {
        header: t('list.tableHeadings.dynamic'),
        property: 'dynamic',
        sortable: true,
        render: (datum: RunbookTypeType) => (datum.dynamic ? <YesText /> : <NoText />)
      },
      {
        header: t('list.tableHeadings.incident'),
        property: 'incident',
        sortable: true,
        render: (datum: RunbookTypeType) => (datum.incident ? <YesText /> : <NoText />)
      },
      {
        header: t('list.tableHeadings.disabled'),
        property: 'disabled',
        sortable: true,
        render: (datum: RunbookTypeType) => (datum.disabled ? <YesText /> : <NoText />)
      }
    ]
  }, [])

  const handleClickRow = useCallback(
    ({ datum }: { datum: RunbookTypeType }) => {
      openRightPanel({ type: 'runbook-type-edit-panel', runbookTypeId: datum?.id })
    },
    [openRightPanel]
  )

  return (
    <Box pad={{ right: '16px' }}>
      <Table columns={runbookTypeColumns} data={items} onClickRow={handleClickRow} />
    </Box>
  )
}

const YesText = () => {
  const { t } = useLanguage('common')
  return <Text>{t('yes')}</Text>
}

const NoText = () => {
  const { t } = useLanguage('common')
  return <Text>{t('no')}</Text>
}
