import { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useParams } from 'react-router-dom'

import { Box, Card, CardProps, FeatureIcon, Grid, Pill, Text } from '@cutover/react-ui'
import { useLanguage } from 'main/services/hooks'
import {
  CustomFieldsGroupsForm,
  CustomFieldsGroupsFormProps
} from 'main/components/shared/custom-field-form/custom-field-groups-form'
import {
  CheckboxFieldControlled,
  DateTimePickerField,
  FileInputField,
  FolderSelectField,
  RadioboxGroupField,
  RunbookSelectField,
  TextInputField
} from 'main/components/shared/form'
import { PermittedProject } from 'main/services/queries/use-permitted-resources'
import { CreateRunbookFormType } from './create-runbook-schema'
import { useRunbookTypeQuery } from 'main/components/settings/runbook-types/use-runbook-type'
import { ConfigModel } from 'main/data-access'

type CreateRunbookDetailsProps = CustomFieldsGroupsFormProps & {
  folders: PermittedProject[] | undefined
  isTemplate?: boolean
}

export const CreateRunbookDetails = ({
  isTemplate = false,
  folders,
  ...customFieldsProps
}: CreateRunbookDetailsProps) => {
  const { t } = useLanguage('runbooks', { keyPrefix: 'newCreateRunbookModal' })
  const { watch, getValues, setValue, setFocus, reset } = useFormContext<CreateRunbookFormType>()

  const { accountId: accountSlug } = useParams()

  const [isStartScheduled, setIsStartScheduled] = useState<boolean>(false)
  const [showAdvancedOptions, setShowAdvancedOptions] = useState(false)
  const [activeCard, setActiveCard] = useState<number>(1)
  const isCreateFromTemplate = activeCard === 1
  const isCreateFromBlank = activeCard === 2
  const isCreateFromAi = activeCard === 3

  const runbookTypeId = getValues('runbook.runbook_type_id')
  const { data: runbookTypeResult } = useRunbookTypeQuery(runbookTypeId)
  const runbookType = runbookTypeResult?.runbook_type
  const aiFeaturesEnabled = ConfigModel.useIsFeatureEnabled('ai_features')

  const aiSupportingOptions: { label: string; value: string }[] = [
    { label: t('enterDetails.attributes.aiFields.supportingOptions.none'), value: 'none' },
    { label: t('enterDetails.attributes.aiFields.supportingOptions.textEntry'), value: 'text' },
    { label: t('enterDetails.attributes.aiFields.supportingOptions.fileUpload'), value: 'file' }
  ]

  const currentAiSupportingOption = watch('ai_config.supporting_ai_options')

  const cardOptions: CardProps[] = [
    {
      title: t('enterDetails.attributes.createOptions.createFromTemplate.title'),
      subtitle: t('enterDetails.attributes.createOptions.createFromTemplate.subTitle'),
      startComponent: <FeatureIcon size="large" icon="template" status={isCreateFromTemplate ? 'idea' : undefined} />,
      selected: isCreateFromTemplate,
      key: 1,
      'data-testid': 'create-runbook-from-template-card'
    },
    {
      title: isTemplate
        ? t('enterDetails.attributes.createOptions.createBlank.template.title')
        : t('enterDetails.attributes.createOptions.createBlank.runbook.title'),
      subtitle: isTemplate
        ? t('enterDetails.attributes.createOptions.createBlank.template.subTitle')
        : t('enterDetails.attributes.createOptions.createBlank.runbook.subTitle'),
      startComponent: (
        <FeatureIcon size="large" icon="runbook-created" status={isCreateFromBlank ? 'idea' : undefined} />
      ),
      selected: isCreateFromBlank,
      key: 2,
      'data-testid': 'create-blank-runbook-card'
    }
  ]

  const createWithAiCard: CardProps = {
    title: t('enterDetails.attributes.createOptions.createBlank.withAi.title'),
    subtitle: t('enterDetails.attributes.createOptions.createBlank.withAi.subTitle'),
    startComponent: <FeatureIcon size="large" icon="rocket" status={isCreateFromAi ? 'idea' : undefined} />,
    endComponents: [<Pill label="New" color="#E42CBC" />],
    selected: isCreateFromAi,
    key: 3,
    'data-testid': 'create-runbook-with-ai-card'
  }

  if (aiFeaturesEnabled && runbookType?.ai_create_enabled) {
    cardOptions.push(createWithAiCard)
  }

  // used to ensure correct fields are focused
  useEffect(() => {
    const baseTemplate = getValues('base_template')
    const projectId = baseTemplate?.project?.id

    setValue('runbook.name', baseTemplate ? `${baseTemplate.name} copy` : '')

    if (isCreateFromTemplate) {
      if (baseTemplate) {
        setFocus('runbook.name')
      } else {
        setFocus('base_template')
      }
    } else {
      setFocus('runbook.name')
    }
    projectId ? setValue('runbook.project_id', projectId) : undefined
  }, [watch('base_template'), isCreateFromTemplate, isCreateFromBlank, isCreateFromAi])

  // used to show or hide additional fields based on start scheduled
  useEffect(() => {
    if (!!getValues('runbook.runbook_versions_attributes.0.start_scheduled')) {
      setIsStartScheduled(true)
    } else {
      setIsStartScheduled(false)
    }
  }, [watch('runbook.runbook_versions_attributes.0.start_scheduled')])

  // used to reset the form when switching cards
  useEffect(() => {
    const runbookTypeId = getValues('runbook.runbook_type_id')
    setShowAdvancedOptions(false)
    reset()
    setValue('runbook.runbook_type_id', runbookTypeId)
    setValue('_step', 2)
  }, [isCreateFromTemplate, isCreateFromBlank, isCreateFromAi])

  useEffect(() => {
    const defaultFolder = folders?.find(folder => folder.name === 'Default')
    setValue('runbook.project_id', defaultFolder ? defaultFolder.id : folders?.[0]?.id)
  }, [folders, isCreateFromTemplate])

  return (
    <Box>
      <Grid minWidth={120}>
        {cardOptions.map(option => (
          <Card onClick={() => setActiveCard(option.key as number)} {...option} />
        ))}
      </Grid>

      {isCreateFromTemplate && accountSlug && (
        <RunbookSelectField<CreateRunbookFormType>
          name="base_template"
          label={t('enterDetails.attributes.template.label')}
          accountSlug={accountSlug}
          required
          data-testid="base-template-form-field"
          params={{
            // TODO: for X runbook type only
            limit: 10,
            a: true,
            template_type: 'default',
            fields: ['id', 'name', 'project', 'approval_status']
          }}
        />
      )}

      <TextInputField<CreateRunbookFormType>
        name="runbook.name"
        label={t('enterDetails.attributes.name')}
        required
        data-testid="runbook-name-form-field"
      />

      {isCreateFromAi && (
        <>
          <TextInputField<CreateRunbookFormType>
            name="ai_config.purpose"
            label={t('enterDetails.attributes.purpose.label')}
          />

          <RadioboxGroupField<CreateRunbookFormType>
            name="ai_config.supporting_ai_options"
            label={t('enterDetails.attributes.aiFields.supportingOptions.label')}
            defaultValue="none"
            direction="row"
            options={aiSupportingOptions}
          />

          {currentAiSupportingOption === 'text' && (
            <TextInputField<CreateRunbookFormType>
              name="ai_config.supporting_ai_text"
              label={'Supporting text info'}
              data-testid="runbook-name-form-field"
            />
          )}

          {currentAiSupportingOption === 'file' && (
            <FileInputField<CreateRunbookFormType>
              name="ai_config.supporting_ai_file"
              accept="text/csv"
              data-testid="supporting-ai-info-file-field"
            />
          )}
        </>
      )}

      <FolderSelectField<CreateRunbookFormType>
        name="runbook.project_id"
        label={t('enterDetails.attributes.folderSelect')}
        folders={folders ?? []}
        clearable={false}
        loading={folders === undefined}
      />

      {!isTemplate && (
        <>
          <DateTimePickerField<CreateRunbookFormType>
            name="runbook.runbook_versions_attributes.0.start_scheduled"
            label={t('enterDetails.attributes.startScheduled')}
            fixed
            fixedWidth
          />

          {isStartScheduled && (
            <>
              <DateTimePickerField<CreateRunbookFormType>
                name="runbook.runbook_versions_attributes.0.end_scheduled"
                label={t('enterDetails.attributes.endScheduled')}
                fixed
                fixedWidth
              />

              {isCreateFromTemplate && (
                <CheckboxFieldControlled<CreateRunbookFormType>
                  name="shift_time"
                  label={t('enterDetails.attributes.shiftTime.label')}
                  helpText={t('enterDetails.attributes.shiftTime.helpText')}
                />
              )}
            </>
          )}
        </>
      )}

      {showAdvancedOptions && (
        <>
          {/* TODO add errors={errors} prop and feed through? */}
          <CustomFieldsGroupsForm {...customFieldsProps} />
        </>
      )}

      <Text
        size="medium"
        css="text-decoration-line: underline; cursor: pointer; margin-top: 10px"
        role="button"
        onClick={() => setShowAdvancedOptions(!showAdvancedOptions)}
        tabIndex={0}
        onKeyDown={e => {
          if (e.code === 'Enter') {
            setShowAdvancedOptions(!showAdvancedOptions)
          }
        }}
      >
        {showAdvancedOptions ? t('enterDetails.hideAdvancedOptions') : t('enterDetails.showAdvancedOptions')}
      </Text>
    </Box>
  )
}
