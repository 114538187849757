import { AccordionPanel, Box, Text } from '@cutover/react-ui'
import { useRunbokListCustomFieldPillLabels } from 'main/components/workspace/pages/runbooks-list/use-custom-field-pill-labels'
import { useLanguage } from 'main/services/hooks'

type TaskSourceRunbookPanelProps = {
  iconColor: string
  sourceRunbookName: string
  sourceRunbookId: number
  sourceRunbookTaskInternalId: number | null
  sourceRunbookFieldValues: any[]
  timezone: string | null
}

export const TaskSourceRunbookPanel = ({
  iconColor,
  timezone,
  sourceRunbookName,
  sourceRunbookId,
  sourceRunbookTaskInternalId,
  sourceRunbookFieldValues
}: TaskSourceRunbookPanelProps) => {
  const { t } = useLanguage('tasks', { keyPrefix: 'editPanel.sourceRunbook' })
  const { customFieldPillLabels } = useRunbokListCustomFieldPillLabels({
    fieldValues: sourceRunbookFieldValues,
    timezone: timezone as string
  })

  return (
    <AccordionPanel label={t('accordionLabel')} iconColor={iconColor} icon="runbook">
      <Box direction="column" gap="xsmall">
        <Box direction="row" gap="xsmall">
          <Box width="xsmall" flex={false}>
            <Text css="font-weight: 500;">{t('title')}</Text>
          </Box>
          <Box>
            <Text color="text-light" css="white-space: normal">
              {sourceRunbookName}
            </Text>
          </Box>
        </Box>
        <Box direction="row" gap="xsmall">
          <Box width="xsmall" flex={false}>
            <Text truncate="tip" css="font-weight: 500;">
              {t('originalRunbookId')}
            </Text>
          </Box>
          <Box>
            <Text color="text-light" css="white-space: normal">
              #{sourceRunbookId}
            </Text>
          </Box>
        </Box>
        <Box direction="row" gap="xsmall">
          <Box width="xsmall" flex={false}>
            <Text truncate="tip" css="font-weight: 500;">
              {t('originalTaskId')}
            </Text>
          </Box>
          <Box>
            <Text color="text-light" css="white-space: normal">
              #{sourceRunbookTaskInternalId}
            </Text>
          </Box>
        </Box>
        {customFieldPillLabels?.map((fieldValue, index) => (
          <Box direction="row" gap="xsmall" key={`fv-${index}`}>
            <Box width="xsmall" flex={false}>
              <Text truncate="tip" css="font-weight: 500;">
                {fieldValue.fieldLabel}
              </Text>
            </Box>
            <Box>
              <Text color="text-light" css="white-space: normal">
                {fieldValue.value}
              </Text>
            </Box>
          </Box>
        ))}
      </Box>
    </AccordionPanel>
  )
}
