import { keyBy } from 'lodash'
import { selector, selectorFamily } from 'recoil'

import { runbookVersionMetaState } from './runbook-version'
import { RunbookComponent } from 'main/services/queries/types'

export const runbookComponentsState = selector<RunbookComponent[]>({
  key: 'runbook-components:list',
  get: ({ get }) => {
    return get(runbookVersionMetaState).runbook_components
  }
})

export const runbookComponentsStateLookup = selector<Record<RunbookComponent['id'], RunbookComponent>>({
  key: 'runbook-components:lookup',
  get: ({ get }) => {
    return keyBy(get(runbookComponentsState), 'id')
  }
})

export const runbookComponentState = selectorFamily<RunbookComponent, number>({
  key: 'runbook-components:id',
  get:
    id =>
    ({ get }) => {
      return get(runbookComponentsStateLookup)[id]
    }
})

export const runbookComponentsInternalIdLookup = selector<Record<RunbookComponent['internal_id'], RunbookComponent>>({
  key: 'runbook-components:internal-id-lookup',
  get: ({ get }) => {
    return keyBy(get(runbookComponentsState), 'internal_id')
  }
})

export const runbookComponentByInternalIdState = selectorFamily<RunbookComponent, number>({
  key: 'runbook-components:internal_id',
  get:
    id =>
    ({ get }) => {
      return get(runbookComponentsInternalIdLookup)[id]
    }
})

export const runbookComponentsSnippets = selector<RunbookComponent[]>({
  key: 'runbook-components:snippets',
  get: ({ get }) => {
    return get(runbookComponentsState).filter(component => component.source_runbook_template_type === 'snippet')
  }
})
