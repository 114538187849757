import { keyBy, mapValues } from 'lodash'
import { selector, selectorFamily } from 'recoil'

import { runbookVersionMetaState } from './runbook-version'
import { RunbookVersionUser } from 'main/services/queries/use-runbook-versions'
import { filteredTasksState, taskListState } from '../tasks/task-list'
import { teamsState } from './teams'
import { RunbookTeam } from 'main/services/queries/types'

export const usersState = selector<RunbookVersionUser[]>({
  key: 'users:list',
  get: ({ get }) => {
    return get(runbookVersionMetaState).users
  }
})

export const usersLookupState = selector<Record<RunbookVersionUser['id'], RunbookVersionUser>>({
  key: 'users:lookup',
  get: ({ get }) => {
    return keyBy(get(usersState), 'id')
  }
})

export const userState = selectorFamily<RunbookVersionUser, { id: number }>({
  key: 'users:id',
  get:
    ({ id }) =>
    ({ get }) => {
      return get(usersLookupState)[id]
    }
})

export const userTasksCountState = selector<Record<number, number>>({
  key: 'users:tasks:count',
  get: ({ get }) => {
    const usersLookup = get(usersLookupState)
    const filteredTasks = get(filteredTasksState)

    return mapValues(usersLookup, user => {
      return filteredTasks.filter(task => task.user_ids.includes(user.id)).length
    })
  }
})

export const userTaskIdsRecordState = selector<Record<number, number[]>>({
  key: 'users:tasks:ids',
  get: ({ get }) => {
    const allTasks = get(taskListState)

    return allTasks.reduce<Record<number, number[]>>((acc, task) => {
      task.user_ids.forEach(userId => {
        if (!acc[userId]) {
          acc[userId] = []
        }

        acc[userId].push(task.id)
      })

      return acc
    }, {} as Record<number, number[]>)
  }
})

export const userToTeamsRecordState = selector<Record<number, RunbookTeam[]>>({
  key: 'users:teams',
  get: ({ get }) => {
    const allTeams = get(teamsState)

    return allTeams.reduce<Record<number, RunbookTeam[]>>((acc, team) => {
      team.user_ids.forEach(userId => {
        if (!acc[userId]) {
          acc[userId] = []
        }

        acc[userId].push(team)
      })

      return acc
    }, {} as Record<number, RunbookTeam[]>)
  }
})
