/* eslint-disable react-hooks/rules-of-hooks */

import { RunbookViewStateType } from 'main/recoil/runbook'
import {
  useActiveTimezoneValue,
  useCanCreateRootTask,
  useCanCreateRootTaskCallback,
  useCanInitiateBulkEditActions,
  useCanInitiateBulkEditActionsCallback,
  useCanInitiateBulkProgressionActions,
  useCanInitiateBulkProgressionActionsCallback,
  useCanShowPirLink,
  useCanShowPirLinkCallback,
  useCopyIdsAdd,
  useCopyIdsRemove,
  useCopyIdsValue,
  useCopyIdsValueCallback,
  useEditRunbookPermissions,
  useEditRunbookPermissionsCallback,
  useHighlightMode,
  useHighlightModeCallback,
  useIntegrationRequestAdd,
  useIntegrationRequestRemove,
  useIntegrationRequestValue,
  useIntegrationRequestValueCallback,
  useLoadingIdAdd,
  useLoadingIdAddBulk,
  useLoadingIdRemove,
  useLoadingIdRemoveBulk,
  useLoadingIdsValue,
  useLoadingIdsValueCallback,
  useMenuClear,
  useMenuClose,
  useMenuOpen,
  useMenuToggleTaskAction,
  useMenuToggleTaskDependencies,
  useMenuValue,
  useMenuValueCallback,
  useModalClose,
  useModalContinue,
  useModalOpen,
  useModalUpdate,
  useModalValue,
  useModalValueCallback,
  useNewCommentsCountReset,
  useNewCommentsCountValue,
  useSelectedEdges,
  useSelectedEdgesCallback,
  useSelectedEdgesSet,
  useSelectedIdAdd,
  useSelectedIdBulkRemove,
  useSelectedIdRemove,
  useSelectedIdsOverwrite,
  useSelectedIdsRemoveAll,
  useSelectedIdsSelectAll,
  useSelectedIdsToggleAll,
  useSelectedIdsValue,
  useSelectedIdsValueCallback,
  useSelectedIdToggle,
  useSelectedTimezoneValue,
  useSetHighlightMode,
  useSetSelectedTimezone,
  useTaskCreateCloseForm,
  useTaskCreateOpenForm,
  useTaskCreateSetName,
  useTaskCreateToggleForm,
  useTaskCreateValue,
  useTaskCreateValueCallback
} from '../hooks'
import { ViewModelInterface } from './types'
import { useEnsureStableArgs } from '../models/model-utils'

type RunbookViewModelPermissions =
  | 'initiate:bulkProgressionActions'
  | 'initiate:bulkEditActions'
  | 'create:tasks'
  | 'edit:runbook'
  | 'show:pir-link'
type RunbookViewModelActionHandlers = {
  'selectedIds:add': ReturnType<typeof useSelectedIdAdd>
  'selectedIds:remove': ReturnType<typeof useSelectedIdRemove>
  'selectedIds:bulkRemove': ReturnType<typeof useSelectedIdBulkRemove>
  'selectedIds:toggle': ReturnType<typeof useSelectedIdToggle>
  'selectedIds:overwrite': ReturnType<typeof useSelectedIdsOverwrite>
  'selectedIds:selectAll': ReturnType<typeof useSelectedIdsSelectAll>
  'selectedIds:removeAll': ReturnType<typeof useSelectedIdsRemoveAll>
  'selectedIds:toggleAll': ReturnType<typeof useSelectedIdsToggleAll>
  'selectedTimezone:set': ReturnType<typeof useSetSelectedTimezone>
  'selectedEdges:set': ReturnType<typeof useSelectedEdgesSet>
  'copyIds:add': ReturnType<typeof useCopyIdsAdd>
  'copyIds:remove': ReturnType<typeof useCopyIdsRemove>
  'loadingIds:add': ReturnType<typeof useLoadingIdAdd>
  'loadingIds:remove': ReturnType<typeof useLoadingIdRemove>
  'loadingIds:bulkAdd': ReturnType<typeof useLoadingIdAddBulk>
  'loadingIds:bulkRemove': ReturnType<typeof useLoadingIdRemoveBulk>
  'highlightMode:toggle': ReturnType<typeof useSetHighlightMode>
  'modal:open': ReturnType<typeof useModalOpen>
  'modal:close': ReturnType<typeof useModalClose>
  'modal:continue': ReturnType<typeof useModalContinue>
  'modal:update': ReturnType<typeof useModalUpdate>
  'newCommentsCount:reset': ReturnType<typeof useNewCommentsCountReset>
  'taskMenu:open': ReturnType<typeof useMenuOpen>
  'taskMenu:toggle:actions': ReturnType<typeof useMenuToggleTaskAction>
  'taskMenu:toggle:dependencies': ReturnType<typeof useMenuToggleTaskDependencies>
  'taskMenu:close': ReturnType<typeof useMenuClose>
  'taskMenu:clear': ReturnType<typeof useMenuClear>
  'integrationRequest:add': ReturnType<typeof useIntegrationRequestAdd>
  'integrationRequest:remove': ReturnType<typeof useIntegrationRequestRemove>
  'taskCreate:toggle': ReturnType<typeof useTaskCreateToggleForm>
  'taskCreate:open': ReturnType<typeof useTaskCreateOpenForm>
  'taskCreate:close': ReturnType<typeof useTaskCreateCloseForm>
  'taskCreate:setName': ReturnType<typeof useTaskCreateSetName>
}

function handleUnknownKey<T extends string>(type: T, fnName: string): never {
  throw new Error(`Unknown argument passed to ${fnName}: ${type}`)
}

export const RunbookViewModel: ViewModelInterface<
  RunbookViewStateType,
  RunbookViewModelActionHandlers,
  RunbookViewModelPermissions
> = {
  useGet: <TKey extends keyof RunbookViewStateType>(viewKey: TKey) => {
    const key = useEnsureStableArgs(viewKey)

    switch (key) {
      case 'loadingIds':
        return useLoadingIdsValue() as RunbookViewStateType[TKey]
      case 'selectedIds':
        return useSelectedIdsValue() as RunbookViewStateType[TKey]
      case 'selectedTimezone':
        return useSelectedTimezoneValue() as RunbookViewStateType[TKey]
      case 'activeTimezone':
        return useActiveTimezoneValue() as RunbookViewStateType[TKey]
      case 'copyIds':
        return useCopyIdsValue() as RunbookViewStateType[TKey]
      case 'highlightMode':
        return useHighlightMode() as RunbookViewStateType[TKey]
      case 'selectedEdges':
        return useSelectedEdges() as RunbookViewStateType[TKey]
      case 'modal':
        return useModalValue() as RunbookViewStateType[TKey]
      case 'menu':
        return useMenuValue() as RunbookViewStateType[TKey]
      case 'newCommentsCount':
        return useNewCommentsCountValue() as RunbookViewStateType[TKey]
      case 'integrationRequest':
        return useIntegrationRequestValue() as RunbookViewStateType[TKey]
      case 'taskCreate':
        return useTaskCreateValue() as RunbookViewStateType[TKey]
      default:
        return handleUnknownKey(viewKey, 'get')
    }
  },
  useGetCallback: <TKey extends keyof RunbookViewStateType>(viewKey: TKey) => {
    const key = useEnsureStableArgs(viewKey)

    switch (key) {
      case 'loadingIds':
        return useLoadingIdsValueCallback() as () => Promise<RunbookViewStateType[TKey]>
      case 'selectedIds':
        return useSelectedIdsValueCallback() as () => Promise<RunbookViewStateType[TKey]>
      case 'copyIds':
        return useCopyIdsValueCallback() as () => Promise<RunbookViewStateType[TKey]>
      case 'highlightMode':
        return useHighlightModeCallback() as () => Promise<RunbookViewStateType[TKey]>
      case 'selectedEdges':
        return useSelectedEdgesCallback() as () => Promise<RunbookViewStateType[TKey]>
      case 'modal':
        return useModalValueCallback() as () => Promise<RunbookViewStateType[TKey]>
      case 'menu':
        return useMenuValueCallback() as () => Promise<RunbookViewStateType[TKey]>
      case 'integrationRequest':
        return useIntegrationRequestValueCallback() as () => Promise<RunbookViewStateType[TKey]>
      case 'taskCreate':
        return useTaskCreateValueCallback() as () => Promise<RunbookViewStateType[TKey]>
      default:
        return handleUnknownKey(viewKey, 'getCallback')
    }
  },
  useAction: <TAction extends keyof RunbookViewModelActionHandlers>(viewAction: TAction) => {
    const action = useEnsureStableArgs(viewAction)

    switch (action) {
      case 'selectedIds:add':
        return useSelectedIdAdd() as RunbookViewModelActionHandlers[TAction]
      case 'selectedIds:remove':
        return useSelectedIdRemove() as RunbookViewModelActionHandlers[TAction]
      case 'selectedIds:bulkRemove':
        return useSelectedIdBulkRemove() as RunbookViewModelActionHandlers[TAction]
      case 'selectedIds:overwrite':
        return useSelectedIdsOverwrite() as RunbookViewModelActionHandlers[TAction]
      case 'selectedIds:toggle':
        return useSelectedIdToggle() as RunbookViewModelActionHandlers[TAction]
      case 'selectedIds:selectAll':
        return useSelectedIdsSelectAll() as RunbookViewModelActionHandlers[TAction]
      case 'selectedIds:removeAll':
        return useSelectedIdsRemoveAll() as RunbookViewModelActionHandlers[TAction]
      case 'selectedIds:toggleAll':
        return useSelectedIdsToggleAll() as RunbookViewModelActionHandlers[TAction]
      case 'selectedEdges:set':
        return useSelectedEdgesSet() as RunbookViewModelActionHandlers[TAction]
      case 'selectedTimezone:set':
        return useSetSelectedTimezone() as RunbookViewModelActionHandlers[TAction]

      case 'copyIds:add':
        return useCopyIdsAdd() as RunbookViewModelActionHandlers[TAction]
      case 'copyIds:remove':
        return useCopyIdsRemove() as RunbookViewModelActionHandlers[TAction]

      case 'loadingIds:add':
        return useLoadingIdAdd() as RunbookViewModelActionHandlers[TAction]
      case 'loadingIds:bulkAdd':
        return useLoadingIdAddBulk() as RunbookViewModelActionHandlers[TAction]
      case 'loadingIds:bulkRemove':
        return useLoadingIdRemoveBulk() as RunbookViewModelActionHandlers[TAction]
      case 'loadingIds:remove':
        return useLoadingIdRemove() as RunbookViewModelActionHandlers[TAction]

      case 'highlightMode:toggle':
        return useSetHighlightMode() as RunbookViewModelActionHandlers[TAction]

      case 'modal:open':
        return useModalOpen() as RunbookViewModelActionHandlers[TAction]
      case 'modal:close':
        return useModalClose() as RunbookViewModelActionHandlers[TAction]
      case 'modal:continue':
        return useModalContinue() as RunbookViewModelActionHandlers[TAction]
      case 'modal:update':
        return useModalUpdate() as RunbookViewModelActionHandlers[TAction]

      case 'newCommentsCount:reset':
        return useNewCommentsCountReset() as RunbookViewModelActionHandlers[TAction]

      case 'taskMenu:close':
        return useMenuClose() as RunbookViewModelActionHandlers[TAction]
      case 'taskMenu:open':
        return useMenuOpen() as RunbookViewModelActionHandlers[TAction]
      case 'taskMenu:clear':
        return useMenuClear() as RunbookViewModelActionHandlers[TAction]
      case 'taskMenu:toggle:actions':
        return useMenuToggleTaskAction() as RunbookViewModelActionHandlers[TAction]
      case 'taskMenu:toggle:dependencies':
        return useMenuToggleTaskDependencies() as RunbookViewModelActionHandlers[TAction]

      case 'integrationRequest:add':
        return useIntegrationRequestAdd() as RunbookViewModelActionHandlers[TAction]
      case 'integrationRequest:remove':
        return useIntegrationRequestRemove() as RunbookViewModelActionHandlers[TAction]

      case 'taskCreate:open':
        return useTaskCreateOpenForm() as RunbookViewModelActionHandlers[TAction]
      case 'taskCreate:toggle':
        return useTaskCreateToggleForm() as RunbookViewModelActionHandlers[TAction]
      case 'taskCreate:close':
        return useTaskCreateCloseForm() as RunbookViewModelActionHandlers[TAction]
      case 'taskCreate:setName':
        return useTaskCreateSetName() as RunbookViewModelActionHandlers[TAction]

      default:
        return handleUnknownKey(viewAction, 'onAction')
    }
  },
  usePermission: <TPermission extends RunbookViewModelPermissions>(permissionAction: TPermission) => {
    const action = useEnsureStableArgs(permissionAction)

    switch (action) {
      case 'initiate:bulkEditActions':
        return useCanInitiateBulkEditActions()
      case 'initiate:bulkProgressionActions':
        return useCanInitiateBulkProgressionActions()
      case 'create:tasks':
        return useCanCreateRootTask()
      case 'edit:runbook':
        return useEditRunbookPermissions()
      case 'show:pir-link':
        return useCanShowPirLink()
      default:
        return handleUnknownKey(permissionAction, 'permission')
    }
  },
  usePermissionCallback: <TPermission extends RunbookViewModelPermissions>(permissionAction: TPermission) => {
    const action = useEnsureStableArgs(permissionAction)

    switch (action) {
      case 'initiate:bulkEditActions':
        return useCanInitiateBulkEditActionsCallback()
      case 'initiate:bulkProgressionActions':
        return useCanInitiateBulkProgressionActionsCallback()
      case 'create:tasks':
        return useCanCreateRootTaskCallback()
      case 'edit:runbook':
        return useEditRunbookPermissionsCallback()
      case 'show:pir-link':
        return useCanShowPirLinkCallback()
      default:
        return handleUnknownKey(permissionAction, 'permissionCallback')
    }
  }
}
