import { FocusEventHandler, ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
import { useCombobox } from 'downshift'
import { debounce } from 'lodash'
import { matchSorter } from 'match-sorter'
import { ThemeContext } from 'grommet'

import { useShowMoreButton } from '../../internal/use-show-more'
import { CheckboxGroup } from '../../checkbox-group'
import { SelectBase } from './internal/select-base'
import { SelectInputEndItems, SelectOptionListItem } from './shared-components'
import { IconName } from '../../../icon'
import { Box } from '../../../layout'
import { SelectListItem } from '../select-list-item'
import { ItemCreateInput } from '../../../item-create-input/item-create-input'
import { Text } from '../../../typography'

const DEFAULT_SHOW_MORE_THRESHOLD = 10

export type RenderMultiSelectOptionProps<Option> = {
  selected?: boolean
  /** either via hover or keyboard -- doing one loses the other. */
  highlighted?: boolean
  /** where the option is being rendered, above select or in list */
  renderLocation?: 'list' | 'above'
  onDeselect?: (value: Option) => void
}

export type MultiSelectEnterKeyDownChanges<Option extends Record<any, any>> = {
  selectedItem?: Option
  inputValue?: string
  isOpen?: boolean
  options: Option[]
}

export type MultiSelectProps<Option extends Record<any, any>> = {
  options?: Option[]
  loadOptions?: (query: string) => Promise<Option[]>
  filterKeys?: (string | ((field: Option) => string))[]
  minChars?: number
  value?: Option[]
  /** Placeholder text to be displayed when no option is selected in the multiselect component */
  placeholderValue?: string
  defaultValue?: Option[]
  onChange?: (value: Option[] | undefined) => void
  onEnterKeyDown?: (args: MultiSelectEnterKeyDownChanges<Option>) => void
  /** What appears in the dropdown list and selected items  */
  renderOption: (option: Option, renderProps: RenderMultiSelectOptionProps<Option>) => ReactNode
  /** Getter for unique/comparable value from option -- short key or function */
  valueKey?: string | ((option: Option) => string | number)
  icon?: IconName
  id?: string
  'data-testid'?: string
  label?: string
  /** limit the select to an array of a single element. For when desiring multiselect style display of selected items */
  single?: boolean // TODO: consider how to branch so can use single select
  name?: string
  hasError?: boolean
  autoFocus?: boolean
  /** Manually set loading state */
  loading?: boolean
  /** Set a custom empty message, or disable by passing null. Defaults to 'No results found' */
  emptyMessage?: string | null
  /** Sets a debounce time on the empty message */
  debounceEmptyMessageTime?: number
  inlineError?: string
  helpText?: string
  required?: boolean
  initialInputText?: string
  onInputChange?: (value: string) => void
  /** @deprecated Only used for CF any/none filters. Any additional items to prepend to the base text input selected items */
  additionalControls?: ReactNode
  /** Adds an optional text string in the top right of the field, visible when the field is focussed or has content */
  labelSuffix?: string
  /** If present, adds an optional button next to the labelSuffix, visible when the field is focussed or has content */
  onClickLabelSuffixButton?: () => void
  /** Label for the top right labelSuffixButton */
  labelSuffixButtonText?: string
  readOnly?: boolean
  placeholder?: string
  a11yTitle?: string
  disabled?: boolean
  /** defaults to true in multiselect because selected items are displayed above input */
  hideSelectedInList?: boolean
  onBlur?: FocusEventHandler<HTMLInputElement>
  resetInputOnBlur?: boolean
  inputRef?: React.Ref<HTMLInputElement>
  clearable?: boolean
  truncate?: boolean
  optionToString?: ((option: Option) => string) | null
  menuRoot?: HTMLElement
  plain?: boolean
  onCreateOption?: (value: string) => void
  selectedCheckboxGroup?: boolean
  showMoreThreshold?: false | number
  hideSelectedCount?: boolean
}

export function MultiSelect<Option extends Record<any, any>>(props: MultiSelectProps<Option>) {
  if (props.hasOwnProperty('value')) {
    return <ControlledMultiSelect {...props} />
  } else {
    return <UncontrolledMultiSelect {...props} />
  }
}

type UncontrolledMultiSelectProps<Option extends Record<any, any>> = Omit<MultiSelectProps<Option>, 'value'>

function UncontrolledMultiSelect<Option extends Record<any, any>>({
  onChange,
  ...props
}: UncontrolledMultiSelectProps<Option>) {
  const [value, setValue] = useState<Option[] | undefined>(props.defaultValue ?? undefined)
  const handleChange = useCallback((val?: Option[]) => {
    setValue(val)
    onChange?.(val)
  }, [])

  return <ControlledMultiSelect<Option> {...props} value={value} onChange={handleChange} />
}

function ControlledMultiSelect<Option extends Record<any, any>>({
  options = [],
  valueKey = 'value',
  truncate = true,
  hideSelectedInList = true,
  value,
  icon,
  filterKeys,
  disabled,
  loading: loadingExternal,
  readOnly,
  required,
  autoFocus,
  placeholder = 'Start typing...',
  onBlur,
  resetInputOnBlur = false,
  name,
  renderOption,
  onInputChange,
  initialInputText,
  additionalControls,
  labelSuffix,
  onClickLabelSuffixButton,
  labelSuffixButtonText,
  single = false,
  label,
  hasError,
  inlineError,
  helpText,
  loadOptions,
  onEnterKeyDown,
  a11yTitle,
  onChange,
  onCreateOption,
  clearable = false,
  optionToString,
  menuRoot,
  inputRef,
  'data-testid': dataTestId,
  debounceEmptyMessageTime = 200,
  plain,
  selectedCheckboxGroup = false,
  placeholderValue,
  showMoreThreshold: showMore = false,
  ...props
}: MultiSelectProps<Option>) {
  const selectedItemCount = value?.length ?? 0
  const hideSelectedCount = props.hideSelectedCount || selectedItemCount < 2
  const showMoreThreshold = showMore ?? selectedItemCount

  const isAsync = !!loadOptions
  const minChars = props.minChars ?? (isAsync ? 1 : 0)
  const minCharsForEmptyMessage = props.minChars ?? 1
  const emptyMessage = props.emptyMessage === null ? null : props.emptyMessage ?? 'No results found'
  const [loading, setLoading] = useState(false)
  const [canShowEmptyMessage, setCanShowEmptyMessage] = useState(props.emptyMessage !== null)
  const isLoading = loading || loadingExternal

  // All possible options, including those that are selected
  const [inputItems, setInputItems] = useState(options)

  const [maxNumOptionsToShow, itemShowButton, setShowMoreThreshold] = useShowMoreButton(selectedItemCount, {
    step: showMoreThreshold || DEFAULT_SHOW_MORE_THRESHOLD,
    showAll: !showMoreThreshold
  })

  const getOptionValue = useCallback(
    (option: Option) => {
      return typeof valueKey === 'string' ? option?.[valueKey] : valueKey(option)
    },
    [valueKey]
  )
  const selectedOptionValues = useMemo(() => (value ?? []).map(getOptionValue), [value, getOptionValue])
  const optionItems = hideSelectedInList
    ? inputItems.filter(option => !selectedOptionValues.includes(getOptionValue(option)))
    : inputItems

  const getFiltered = (input: string) =>
    matchSorter(options, input || '', {
      keys: filterKeys,
      threshold: matchSorter.rankings.CONTAINS,
      // keeps the filterd results in the same order as the original options
      sorter: rankedItems => rankedItems
    })

  const asyncLoadOptions = (inputValue: string) => {
    setLoading(true)
    loadOptions?.(inputValue)
      .then((items: Option[]) => {
        setInputItems(items)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const {
    isOpen,
    getLabelProps,
    getMenuProps,
    getInputProps,
    reset,
    highlightedIndex,
    getItemProps,
    openMenu,
    setInputValue,
    inputValue
  } = useCombobox({
    selectedItem: null,
    items: hideSelectedInList
      ? inputItems.filter(option => !selectedOptionValues.includes(getOptionValue(option)))
      : inputItems,
    initialInputValue: initialInputText,
    selectedItemChanged: (selectedItem, prevSelectedItem) => {
      const selectedItemVal = selectedItem ? getOptionValue(selectedItem) : undefined
      const prevSelectedItemVal = prevSelectedItem ? getOptionValue(prevSelectedItem) : undefined
      return selectedItemVal !== prevSelectedItemVal
    },
    itemToString: () => {
      // always returning empty here because multiselects never have content for selected items in the input.
      // can still pass a function to get the item as a string which is used for accessibility labeling
      return ''
    },
    onSelectedItemChange: ({ selectedItem }) => {
      let nextSelectedOptions = [...(value ?? [])]

      if (!selectedItem) return

      const index = nextSelectedOptions.indexOf(selectedItem)

      // Item was found, so we deselecting it. This is only relevant if showing selected items in the list,
      // which wouldn't be common since they already appear above the input by default. Items are typically
      // deselected by clicking the 'x' on the selected item above the input, not clicking a list item
      // that is already selected in the dropdown.
      if (index !== -1) {
        nextSelectedOptions.splice(index, 1)
      }

      // Item wasn't found, so it's new and we need to inject it at the appropriate position. Typically that is the
      // end of the list. However, if the list is showing a 'show more' button, we need to place it at the end of the
      // visible items instead, plus expand the allotted visible items by one so it
      else if (!single) {
        const wasShowingAll = showMoreThreshold && selectedItemCount <= showMoreThreshold

        if (wasShowingAll) {
          nextSelectedOptions = [...nextSelectedOptions, selectedItem]

          if (nextSelectedOptions.length >= maxNumOptionsToShow) {
            setShowMoreThreshold?.(prev => prev + 1)
          }
        } else {
          nextSelectedOptions.splice(maxNumOptionsToShow, 0, selectedItem)
          setShowMoreThreshold?.(prev => prev + 1)
        }
      }

      onChange?.(single ? [selectedItem] : nextSelectedOptions)
    },
    onInputValueChange: ({ inputValue }) => {
      onInputChange?.(inputValue ?? '')

      if (!isAsync) {
        const filteredItems = !filterKeys ? options : getFiltered(inputValue ?? '')

        setInputItems(filteredItems)
      } else if (inputValue && inputValue.length >= minChars) {
        asyncLoadOptions(inputValue)
      } else {
        setInputItems([])
      }
    },
    onStateChange: changes => {
      const { type } = changes

      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
          onEnterKeyDown?.({
            ...changes,
            inputValue,
            selectedItem: changes.selectedItem ?? undefined,
            options: optionItems
          })
          break
        case useCombobox.stateChangeTypes.InputBlur:
          if (isAsync) {
            setInputValue('')
          }
          break
      }
    },
    // Reducers MUST STAY PURE: There may be nothing in this function except return of value.
    stateReducer: (prevState, actionAndChanges) => {
      const { changes: proposedChanges, type } = actionAndChanges
      const inputValueLength = (proposedChanges.inputValue ?? '').length

      // Force a default highlighted item. Otherwise you have to arrow down to select the first item.
      const nextHighlightedIndex =
        proposedChanges.highlightedIndex === -1
          ? prevState.highlightedIndex === -1
            ? 0
            : prevState.highlightedIndex
          : proposedChanges.highlightedIndex

      let result = {
        ...proposedChanges,
        highlightedIndex: nextHighlightedIndex
      }

      switch (type) {
        case useCombobox.stateChangeTypes.InputBlur:
          result = {
            ...result,
            selectedItem: null
          }
          break
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
          result = {
            ...result,
            inputValue: single ? proposedChanges.inputValue : '',
            highlightedIndex: 0
          }
          break
        case useCombobox.stateChangeTypes.ControlledPropUpdatedSelectedItem:
          result = {
            ...result,
            inputValue:
              proposedChanges.inputValue === '' && prevState.inputValue.length === 1
                ? prevState.inputValue
                : proposedChanges.inputValue
          }
          break
        default:
          result = {
            ...result,
            isOpen: proposedChanges.isOpen && inputValueLength >= minChars && !disabled && !readOnly
          }
      }

      return result
    }
  })

  useEffect(() => {
    if (!isAsync) {
      const filteredItems = !filterKeys ? options : getFiltered(inputValue)

      setInputItems(filteredItems)
    }
  }, [filterKeys, options])

  const handleRemoveCheckboxOption = useCallback(
    (option: string | number) => {
      onChange?.(value?.filter(obj => obj.value !== option))
    },
    [onChange, value]
  )

  const handleRemoveCustomOption = useCallback(
    (option: Option) => {
      const index = (value ?? []).indexOf(option)

      if (index >= 0 && value) {
        onChange?.([...value.slice(0, index), ...value.slice(index + 1)])
      }
    },
    [onChange, value]
  )

  const selectedItemsContent = useMemo(() => {
    return (
      <>
        {selectedCheckboxGroup && value && value.length > 0 ? (
          <CheckboxGroup
            a11yTitle={`${a11yTitle ?? label} selected options`}
            plain
            options={value as Option[]}
            defaultValue={selectedOptionValues}
            onChange={event => {
              handleRemoveCheckboxOption(event.option.value)
            }}
            disabled={disabled}
            readOnly={readOnly}
          />
        ) : value && value.length > 0 ? (
          <Box
            width="100%"
            tag="ul"
            a11yTitle={`${a11yTitle ?? label} selected options`}
            // clear user agent styles for list
            css={`
              margin: 4px 0 2px 0;
              padding: 0;
              text-indent: 0;
              list-style: none;
            `}
          >
            {value.map(item => {
              const optionValue = getOptionValue(item)
              const index = selectedOptionValues.indexOf(optionValue)
              return (
                <Box
                  width="100%"
                  tag="li"
                  key={optionValue}
                  a11yTitle={`${optionToString?.(item) ?? ''} selected option`.trim()}
                  css={`
                    display: ${index >= maxNumOptionsToShow && 'none'};
                  `}
                >
                  {renderOption(item, {
                    onDeselect: () => handleRemoveCustomOption(item),
                    selected: true,
                    renderLocation: 'above'
                  })}
                </Box>
              )
            })}
          </Box>
        ) : placeholderValue ? (
          <Box width="100%" css={'padding: 7px 0;'}>
            <Text>{placeholderValue}</Text>
          </Box>
        ) : null}
        {itemShowButton}
        {additionalControls}
      </>
    )
  }, [
    handleRemoveCheckboxOption,
    handleRemoveCustomOption,
    renderOption,
    value,
    selectedOptionValues,
    placeholderValue,
    maxNumOptionsToShow
  ])

  const updateCanShowEmptyMessage = debounce(
    () => {
      setCanShowEmptyMessage(inputValue.length >= minCharsForEmptyMessage)
    },
    debounceEmptyMessageTime,
    { leading: false }
  )

  // Don't like the performance implications of this code, should refactor.
  useEffect(() => {
    if (!isLoading && inputValue.length >= minCharsForEmptyMessage) {
      updateCanShowEmptyMessage()
    } else {
      setCanShowEmptyMessage(false)
    }
  }, [isLoading, inputValue.length])

  const handleOnFocus = () => {
    if (isOpen) {
      return () => {}
    }

    if (inputItems.length) {
      return openMenu
    }

    return () => {
      if (isAsync && minChars === 0 && !inputValue) {
        asyncLoadOptions(inputValue)
      }
    }
  }

  return (
    <ThemeContext.Extend value={{ global: { drop: { zIndex: 10001 } } }}>
      <SelectBase
        ref={inputRef}
        plain={plain}
        menuRoot={menuRoot}
        isOpen={!disabled && isOpen && !(isLoading && optionItems.length === 0)}
        inputValue={inputValue}
        value={value}
        data-testid={dataTestId}
        getMenuProps={ref => getMenuProps({ ref, multiple: true })}
        getInputProps={ref =>
          getInputProps({
            placeholder,
            readOnly,
            required,
            ref,
            disabled,
            autoFocus,
            name,
            onClick: isOpen || selectedCheckboxGroup ? () => {} : inputItems.length ? openMenu : () => {},
            onFocus: handleOnFocus(),
            onBlur: e => {
              onBlur?.(e)
              if (resetInputOnBlur) {
                reset()
              }
            }
          })
        }
        icon={icon}
        label={(label ?? '') + (!hideSelectedCount ? ` (${selectedItemCount})` : '')}
        labelSuffix={labelSuffix}
        onClickLabelSuffixButton={onClickLabelSuffixButton}
        labelSuffixButtonText={labelSuffixButtonText}
        hasError={hasError}
        inlineError={inlineError}
        tooltipText={helpText}
        shrinkLabel={!!selectedOptionValues.length || !!placeholderValue}
        labelProps={getLabelProps({
          'aria-label': a11yTitle ?? label
        })}
        a11yTitle={a11yTitle ?? label}
        selectedItems={selectedItemsContent}
        truncate={truncate}
        onCreateOption={onCreateOption}
        endComponent={
          <SelectInputEndItems
            clearable={clearable}
            isLoading={isLoading}
            readOnly={readOnly}
            disabled={disabled}
            hasInputValue={!!inputValue}
            async={isAsync}
          />
        }
      >
        {optionItems.length ? (
          optionItems.map((option, index) => {
            return (
              <SelectOptionListItem
                key={`${getOptionValue(option)}-${index}`}
                {...getItemProps({
                  item: option,
                  index,
                  isSelected: selectedOptionValues.includes(getOptionValue(option))
                })}
                aria-label={optionToString?.(option) ?? option.label ?? 'available-option'}
              >
                {renderOption(option, {
                  selected: selectedOptionValues.includes(getOptionValue(option)),
                  renderLocation: 'list',
                  highlighted: index === highlightedIndex
                })}
              </SelectOptionListItem>
            )
          })
        ) : props.emptyMessage !== null && canShowEmptyMessage ? (
          <SelectOptionListItem>
            <SelectListItem label={emptyMessage ?? ''} selected={false} highlighted={false} />
          </SelectOptionListItem>
        ) : null}
      </SelectBase>

      {!disabled && !readOnly && onCreateOption && (
        <Box margin={{ bottom: '13.5px' }} flex={false}>
          <ItemCreateInput onCreateItem={onCreateOption} />
        </Box>
      )}
    </ThemeContext.Extend>
  )
}
