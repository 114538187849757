import { memo, SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { Link, LinkProps } from 'react-router-dom'
import styled from 'styled-components'

import { Box, Checkbox, RunbookTypeIcon, themeColor } from '@cutover/react-ui'
import { RunbookItemHeader } from './runbook-item-header'
import { RunbookItemRightSideContent } from './runbook-item-right-content'
import { RunbookItemSubHeader } from './runbook-item-sub-header'
import * as ListItem from 'main/components/shared/runbook-list-item/runbook-list-item-layout'
import { useAccountRunbookTypes } from 'main/services/api/data-providers/account/account-data'
import { useLanguage } from 'main/services/hooks'
import { RunbookListRunbook } from 'main/services/queries/types'
import { ConfigModel } from 'main/data-access'

type RunbookListItemProps = {
  runbook: RunbookListRunbook
  onClickItem?: (item: RunbookListRunbook) => void
  onClickEdit?: (id: number) => void
  onClickArchive?: (id: number) => void
  onClickDuplicate?: (id: number) => void
  onSelect?: (id: number) => void
  createLinkTo?: (id: number) => string
  onClickRestore?: (id: number) => void
  editing?: boolean
  loading?: boolean
  selectedByBulkAction?: boolean
  isSelectMode?: boolean
  withActions?: boolean
  withSelect?: boolean
  rowIndex?: number
  role?: string
}

export const RunbookListItem = memo(
  ({
    runbook,
    onClickItem,
    onClickEdit,
    onClickDuplicate,
    onClickArchive,
    onSelect,
    createLinkTo,
    onClickRestore,
    editing,
    loading,
    selectedByBulkAction = false,
    isSelectMode = false,
    withActions,
    withSelect
  }: RunbookListItemProps) => {
    const { template_type: templateType, is_template: isTemplate, runbook_type_id: runbookTypeId, archived } = runbook
    const { runbookTypeLookup } = useAccountRunbookTypes()
    const runbookType = runbookTypeLookup?.[runbookTypeId]
    const isSnippet = templateType === 'snippet'
    const canRestore = runbook.archived && runbook.admin
    const readOnlyFFEnabled = ConfigModel.useIsFeatureEnabled('read_only_archived_runbooks')

    const { t } = useLanguage('runbooks')

    const [selected, setSelected] = useState(selectedByBulkAction)

    useEffect(() => {
      setSelected(selectedByBulkAction)
    }, [selectedByBulkAction])

    const handleOnSelect = useCallback(
      (e: SyntheticEvent) => {
        e.stopPropagation()
        if (!editing) {
          setSelected(selected => !selected)
        }
        onSelect?.(runbook.id)
      },
      [onSelect, runbook.id, editing]
    )

    const handleOnClickItem = useCallback(
      (e: SyntheticEvent) => {
        e.stopPropagation()

        if (isSelectMode) {
          e.preventDefault()
          setSelected(selected => !selected)
          onSelect?.(runbook.id)
        } else if (onClickItem) {
          if (runbook.archived && !readOnlyFFEnabled) e.preventDefault()
          onClickItem(runbook)
        }
      },
      [isSelectMode, onSelect, runbook]
    )

    const handleOnClickRestore = useCallback(
      (e: SyntheticEvent) => {
        e.stopPropagation()
        e.preventDefault()
        onClickRestore?.(runbook.id)
      },
      [onClickRestore, runbook.id]
    )

    const hideRunbookTypeIcon = withSelect && (selected || isSelectMode)
    const hideCheckbox = (withSelect && !isSelectMode && !selected) || loading || archived
    const hideActions = loading || isSelectMode || archived || selected || !runbook.admin
    const linkTo = createLinkTo?.(runbook.id)

    const leftSide = useMemo(() => {
      return (
        <>
          <ListItem.LeftItemIcon>
            {withSelect && !archived && (
              <CheckboxWrapper fill align="center" justify="center" hidden={hideCheckbox} tabIndex={-1}>
                <Checkbox
                  aria-label={t('list.listItem.rowAction.select')}
                  data-testid="check"
                  onChange={handleOnSelect}
                  checked={selected}
                  onClick={e => {
                    // React SyntheticEvent doesn't contain a key code,
                    // this is an alternative to determine keyboard interaction
                    if (e.clientX !== 0 && e.clientY !== 0) e.currentTarget.blur()
                    e.stopPropagation()
                  }}
                />
              </CheckboxWrapper>
            )}

            <RunbookTypeIconWrapper hidden={hideRunbookTypeIcon}>
              <RunbookTypeIcon
                icon={runbookType?.icon_name || 'getting-started'}
                color={runbookType?.icon_color || 'primary'}
                template={isTemplate || isSnippet}
              />
            </RunbookTypeIconWrapper>
          </ListItem.LeftItemIcon>
          <ListItem.LeftItemTextContainer>
            <RunbookItemHeader runbook={runbook} createLinkTo={createLinkTo} />
            <RunbookItemSubHeader runbook={runbook} />
          </ListItem.LeftItemTextContainer>
        </>
      )
    }, [
      createLinkTo,
      handleOnSelect,
      hideCheckbox,
      isSelectMode,
      isSnippet,
      isTemplate,
      runbook,
      runbookType?.icon_color,
      runbookType?.icon_name,
      selected,
      withSelect,
      archived
    ])

    const containerBackground = editing ?? selected ? 'bg-2' : loading ? 'bg-1' : 'bg'

    const innerContent = (
      <>
        <ListItem.LeftItems>{leftSide}</ListItem.LeftItems>
        <ListItem.RightItems data-testid="right-display-content">
          <RunbookItemRightSideContent
            runbook={runbook}
            runbookType={runbookType}
            loading={loading}
            onClickDuplicate={onClickDuplicate ? onClickDuplicate : undefined}
            onClickRestore={canRestore ? handleOnClickRestore : undefined}
            showActions={!isSelectMode && withActions && !hideActions}
            onClickEdit={onClickEdit}
            onClickArchive={onClickArchive}
          />
        </ListItem.RightItems>
      </>
    )

    // SelectableListItemContainer and (non selectable) ListItem.ItemContainer have to be different components
    // so that you can have both types on the screen at the same time and hover behavior works correctly.
    const item = (
      <StyledLink
        to={linkTo ?? ''}
        state={{ runbookIsArchived: archived, templateType: templateType }}
        as={isSelectMode ? Box : undefined}
      >
        {withSelect ? (
          <SelectableListItemContainer
            background={containerBackground}
            onClick={handleOnClickItem}
            selected={selected}
            tabIndex={-1}
            css={`
              opacity: ${runbook.stage === 'cancelled' && 0.5};
            `}
            data-testid="runbook-list-item"
            runbookIsArchived={archived}
          >
            {innerContent}
          </SelectableListItemContainer>
        ) : (
          <ListItem.ItemContainer
            background={containerBackground}
            onClick={handleOnClickItem}
            css={`
              opacity: ${runbook.stage === 'cancelled' && 0.5};
            `}
            data-testid="runbook-list-item"
          >
            {innerContent}
          </ListItem.ItemContainer>
        )}
      </StyledLink>
    )

    return withSelect ? <Box pad={{ bottom: '1px' }}>{item}</Box> : item
  }
)

const RunbookTypeIconWrapper = styled(Box)<{ hidden?: boolean }>`
  display: ${({ hidden }) => hidden && 'none'};
`

const SelectableListItemContainer = styled(props => <ListItem.ItemContainer {...props} />)<{
  runbookIsArchived?: boolean
}>`
  &:hover {
    ${RunbookTypeIconWrapper} {
      display: ${({ runbookIsArchived }) => !runbookIsArchived && 'none'};
    }
  }
`

const CheckboxWrapper = styled(Box)<{ hidden?: boolean }>`
  display: ${({ hidden }) => hidden && 'none'};
  label {
    margin-left: 4px;
  }

  ${SelectableListItemContainer}:hover & {
    display: flex;
  }
`

type StyledLinkState = {
  runbookIsArchived?: boolean
  templateType?: string
}

const StyledLink = styled(Link)<LinkProps & { state: StyledLinkState }>`
  text-decoration: none;
  &:focus-within:not(:focus),
  &:focus-visible {
    box-shadow: none !important;

    ${CheckboxWrapper} {
      display: flex;
    }
    ${RunbookTypeIconWrapper} {
      display: ${props =>
        (props.state.templateType && props.state.templateType !== 'off') || !props.state.runbookIsArchived
          ? 'none'
          : 'block'};
    }
    ${SelectableListItemContainer} {
      transition: box-shadow 0.1s ease-in-out !important;
      outline: none;
      box-shadow: inset 0px 0px 0px 2px ${themeColor('primary')} !important;
      opacity: 1 !important;
      z-index: 1 !important;
    }
    ${SelectableListItemContainer}:focus-within {
      box-shadow: none !important;
    }
    ${ListItem.ItemContainer} {
      background: ${themeColor('bg-1')};
    }
    ${ListItem.RightItemActionsContainer}, ${ListItem.RightItemButtonContainer} {
      display: flex !important;
    }
    ${ListItem.RightItemTextContainer} {
      display: ${props => (props.state.templateType !== 'off' || props.state.runbookIsArchived) && 'none !important'};
    }
  }
`
