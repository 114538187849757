import { useRecoilCallback, useRecoilValue } from 'recoil'

import { customFieldOptionsLookup, customFieldOptionsState } from 'main/recoil/runbook'
import { FieldOptionModelType } from 'main/data-access/models'

/* -------------------------------------------------------------------------- */
/*                                     Get                                    */
/* -------------------------------------------------------------------------- */

export const useGetFieldOption: FieldOptionModelType['useGet'] = (id: number) => {
  const lookup = useRecoilValue(customFieldOptionsLookup)
  return lookup?.[id]
}

export const useGetFieldOptionCallback: FieldOptionModelType['useGetCallback'] = () => {
  return useRecoilCallback(
    ({ snapshot }) =>
      async (id: number) => {
        const lookup = await snapshot.getPromise(customFieldOptionsLookup)
        return lookup?.[id]
      },
    []
  )
}

/* -------------------------------------------------------------------------- */
/*                                  Get All                                   */
/* -------------------------------------------------------------------------- */

export const useGetFieldOptions: FieldOptionModelType['useGetAll'] = () => {
  return useRecoilValue(customFieldOptionsState)
}

export const useGetFieldOptionsCallback: FieldOptionModelType['useGetAllCallback'] = () => {
  return useRecoilCallback(
    ({ snapshot }) =>
      () =>
        snapshot.getPromise(customFieldOptionsState),
    []
  )
}

/* -------------------------------------------------------------------------- */
/*                                  Lookup                                    */
/* -------------------------------------------------------------------------- */

export const useGetFieldOptionLookup: FieldOptionModelType['useGetLookup'] = () => {
  return useRecoilValue(customFieldOptionsLookup)
}

export const useGetFieldOptionLookupCallback: FieldOptionModelType['useGetLookupCallback'] = () =>
  useRecoilCallback(
    ({ snapshot }) =>
      () =>
        snapshot.getPromise(customFieldOptionsLookup),
    []
  )
