import { SyntheticEvent } from 'react'
import { Placement } from '@floating-ui/react'

import { Icon } from '../../icon'
import { Box } from '../../layout/box'
import { Tooltip } from '../../overlays/tooltip'
import { themeColor } from '../../theme'

type CheckboxToggleProps = {
  isChecked: boolean
  label: string
  checkedLabel?: string
  isIndeterminate?: boolean
  isDisabled?: boolean
  onClick?: (e: SyntheticEvent) => void
  hasTooltip?: boolean
  tipPlacement?: Placement
  'data-testid'?: string
  className?: string
}

// TODO: story
export const CheckboxToggle = ({
  label,
  checkedLabel,
  isChecked,
  isIndeterminate,
  hasTooltip = false,
  tipPlacement,
  ...props
}: CheckboxToggleProps) => {
  const labelText = (isChecked || isIndeterminate) && checkedLabel ? checkedLabel : label

  if (hasTooltip && !props.isDisabled) {
    return (
      <Tooltip content={labelText} placement={tipPlacement}>
        <CheckboxToggleInner isChecked={isChecked} isIndeterminate={isIndeterminate} label={labelText} {...props} />
      </Tooltip>
    )
  }

  return <CheckboxToggleInner isChecked={isChecked} isIndeterminate={isIndeterminate} label={labelText} {...props} />
}

const CheckboxToggleInner = ({
  label,
  isChecked,
  isIndeterminate,
  isDisabled,
  'data-testid': testId,
  className,
  onClick
}: CheckboxToggleProps) => {
  const ariaCheckedText = isChecked ? 'true' : isIndeterminate ? 'mixed' : 'false'
  return (
    <Box
      onClick={onClick}
      data-testid={testId}
      height="40px"
      width="40px"
      align="center"
      justify="center"
      role="checkbox"
      aria-checked={ariaCheckedText}
      aria-disabled={isDisabled}
      aria-label={label}
      tabIndex={0}
      className={className}
      css={`
        border-radius: 20px;
        cursor: ${isDisabled ? 'default' : 'pointer'};
        opacity: ${isDisabled ? 0.5 : 1};
        pointer-events: ${isDisabled ? 'none' : 'auto'};
        &:hover {
          svg {
            fill: ${!isChecked && !isIndeterminate && !isDisabled ? themeColor('text') : undefined};
          }
        }
      `}
    >
      <Icon
        icon={isIndeterminate ? 'checkbox-deselect' : isChecked ? 'checkbox-checked' : 'checkbox'}
        color={isIndeterminate || isChecked ? 'primary' : 'text-light'}
      />
    </Box>
  )
}
