import { useState } from 'react'

import { CreateSnippetFromRunbookModal } from 'main/components/runbook/modals/snippets/create-snippet/create-snippet-from-runbook-modal'
import { ReactAngularConnector } from 'main/connectors/react-angular-connector'

type CreateSnippetFromRunbookState = {
  runbookId: number | null
  taskInternalIds: number[] | null
}

export const CreateSnippetFromRunbookConnector = () => {
  const [state, setState] = useState<CreateSnippetFromRunbookState>({
    runbookId: null,
    taskInternalIds: null
  })

  const handleOnMount = ({ runbookId, taskInternalIds }: CreateSnippetFromRunbookState) => {
    setState({ runbookId, taskInternalIds })
  }

  const handleOnUnmount = () => {
    setState({ runbookId: null, taskInternalIds: null })
  }

  return (
    <ReactAngularConnector
      mountEvent="show-create-snippet-from-runbook-modals"
      unmountEvent="hide-create-snippet-from-runbook-modals"
      onMount={handleOnMount}
      onUnmount={handleOnUnmount}
    >
      {state.runbookId && state.taskInternalIds && (
        <CreateSnippetFromRunbookModal
          runbookId={state.runbookId}
          taskInternalIds={state.taskInternalIds}
          triggeredFromAngular
        />
      )}
    </ReactAngularConnector>
  )
}
