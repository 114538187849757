import { memo, ReactNode, useCallback } from 'react'
import { eventManager } from 'event-manager'
import { Main as GrommetMain } from 'grommet'
import { Outlet } from 'react-router-dom'
import styled, { css } from 'styled-components'

import { Box, IconButton, media, themeColor, themeEdgeSize } from '@cutover/react-ui'
import {
  ANGULAR_NAVIGATION_WIDTH,
  BANNER_HEIGHT,
  BANNER_LAYOUT_HEIGHT,
  FILTER_PANEL_WIDTH,
  HEADER_HEIGHT,
  IDS,
  LEFT_SIDEBAR_DEFAULT_WIDTH,
  LEFT_SIDEBAR_MIN_WIDTH,
  PAGE_NAV_DEFAULT_WIDTH,
  PAGE_NAV_MOBILE_HEIGHT,
  SUB_HEADER_HEIGHT
} from './layout-constants'
import { ResizableRightPanel as RightPanel } from './resizable-right-panel'
import { ConfigModel } from 'main/data-access'

type LayoutProps = {
  header: ReactNode
  subHeader?: ReactNode
  filter?: ReactNode
  rightNavigation?: ReactNode
  rightPanels?: ReactNode
  banner?: ReactNode
  // by default this is the Outlet where the inner page context is injected. This can be customized so that loading content can be injected
  children?: ReactNode
  'data-testid'?: string
}

export const Layout = memo(
  ({
    header,
    subHeader,
    banner,
    filter,
    rightNavigation,
    rightPanels,
    children = <Outlet />,
    'data-testid': dataTestId
  }: LayoutProps) => {
    const isAngularNavigation = !ConfigModel.useIsFeatureEnabled('react_workspace')

    const openLeftSidebarNavPanel = useCallback(() => {
      const root = document.getElementById('page-root')
      if (root) root.classList.add('left-nav-panel-open')

      eventManager.emit('react-toggle-main-nav', { open: true })
      // FIXME: temp hack -- consider costs/benefits of re-renders caused without and when we can update properly
      eventManager.emit('angular-toggle-main-nav', { open: true })
    }, [])

    return (
      <PageLayout isAngularNavigation={isAngularNavigation} data-testid={dataTestId}>
        <PageWrapper isAngularNavigation={isAngularNavigation}>
          <Header>
            <SidebarToggleButtonContainer>
              <IconButton tertiary onClick={openLeftSidebarNavPanel} label="Open Navigation" icon="menu" />
            </SidebarToggleButtonContainer>
            {header}
          </Header>
          <InnerPageWrapper>
            <InnerPage>
              {subHeader ? <SubHeader>{subHeader}</SubHeader> : <Box pad={{ top: '18px' }} />}
              <InnerContent>
                <FilterPanel>{filter}</FilterPanel>
                <MainWrapper>
                  <Main>{children}</Main>
                </MainWrapper>
              </InnerContent>
            </InnerPage>
            <RightPanel hasRightNav={!!rightNavigation}>{rightPanels}</RightPanel>
            <RightNav>{rightNavigation}</RightNav>
          </InnerPageWrapper>
          <Banner>{banner}</Banner>
        </PageWrapper>
      </PageLayout>
    )
  }
)

const PageLayout = styled(Box).attrs((props: { isAngularNavigation: boolean; 'data-testid'?: string }) => ({
  id: IDS.PAGE_LAYOUT,
  'data-testid': props['data-testid'] ?? IDS.PAGE_LAYOUT
}))<{ isAngularNavigation: boolean; 'data-testid'?: string }>`
  position: absolute;
  overflow: hidden;
  inset: 0;
  height: 100%;
  width: 100vw;

  .left-nav-panel-open & {
    left: ${LEFT_SIDEBAR_DEFAULT_WIDTH}px;
    width: calc(100vw - ${LEFT_SIDEBAR_DEFAULT_WIDTH}px);
  }

  ${({ isAngularNavigation }) =>
    isAngularNavigation
      ? css`
          left: ${ANGULAR_NAVIGATION_WIDTH}px;
          width: calc(100vw - ${ANGULAR_NAVIGATION_WIDTH}px);
        `
      : css`
          #page-root:not(.left-nav-panel-open) & {
            ${media.gtSm(css`
              left: ${LEFT_SIDEBAR_MIN_WIDTH}px;
              width: calc(100vw - ${LEFT_SIDEBAR_MIN_WIDTH}px);
            `)}
          }
        `}
`

const PageWrapper = styled(Box).attrs(() => ({
  id: IDS.PAGE_WRAPPER,
  'data-testid': IDS.PAGE_WRAPPER,
  height: { height: '100vh', max: '100vh' },
  background: 'bg-1'
}))<{ isAngularNavigation: boolean }>`
  z-index: 1;
  left: 0;
  position: relative !important;

  ${({ isAngularNavigation }) =>
    // Adjust positioning when using react layout and angular navigation.
    // TODO: remove conditional when react workspace goes live.
    isAngularNavigation
      ? css`
          left: 0;
          max-width: calc(100vw - ${LEFT_SIDEBAR_DEFAULT_WIDTH}px);
          width: calc(100vw - ${LEFT_SIDEBAR_DEFAULT_WIDTH}px);
        `
      : css`
          .left-nav-panel-open & {
            max-width: calc(100vw - ${LEFT_SIDEBAR_DEFAULT_WIDTH}px);
            width: calc(100vw - ${LEFT_SIDEBAR_DEFAULT_WIDTH}px);
          }
        `}

  ${media.sm(css`
    width: 100vw;
    height: 100vh;
    overflow: hidden;
  `)}
`

const Header = styled(Box).attrs(() => ({
  id: IDS.HEADER,
  'data-testid': IDS.HEADER,
  tag: 'header',
  flex: false,
  className: 'react-header',
  height: { min: `${HEADER_HEIGHT}px`, max: `${HEADER_HEIGHT}px`, height: `${HEADER_HEIGHT}px` },
  fill: 'horizontal',
  background: 'bg',
  gap: 'xxsmall',
  direction: 'row',
  align: 'center',
  border: { side: 'bottom', color: 'bg-2' },
  pad: { horizontal: 'small' }
}))``

const InnerPageWrapper = styled(Box).attrs(() => ({
  id: IDS.INNER_PAGE_WRAPPER,
  'data-testid': IDS.INNER_PAGE_WRAPPER,
  width: { width: '100%', max: '100%' },
  direction: 'row'
}))`
  position: relative;
`

const SubHeader = styled(Box).attrs(() => ({
  id: IDS.SUB_HEADER,
  'data-testid': IDS.SUB_HEADER,
  height: { height: `${SUB_HEADER_HEIGHT}px`, min: `${SUB_HEADER_HEIGHT}px` },
  fill: 'horizontal',
  background: 'bg-1',
  pad: { horizontal: 'medium' },
  flex: false,
  overflow: 'hidden'
}))`
  #page-root:not(.right-nav-disabled):not(.right-panel-open) & {
    padding-right: 0;
  }
`

const InnerContent = styled(Box).attrs(() => ({
  id: IDS.INNER_CONTENT,
  'data-testid': IDS.INNER_CONTENT,
  direction: 'row',
  background: 'bg-1'
}))`
  min-height: calc(100vh - ${HEADER_HEIGHT}px - ${SUB_HEADER_HEIGHT}px);
  height: calc(100vh - ${HEADER_HEIGHT}px - ${SUB_HEADER_HEIGHT}px);

  .show-banner & {
    min-height: calc(100vh - ${HEADER_HEIGHT}px - ${SUB_HEADER_HEIGHT}px - ${BANNER_LAYOUT_HEIGHT}px);
    height: calc(100vh - ${HEADER_HEIGHT}px - ${SUB_HEADER_HEIGHT}px - ${BANNER_LAYOUT_HEIGHT}px);
  }

  .sub-header-disabled & {
    height: calc(100vh - ${HEADER_HEIGHT}px);
    min-height: calc(100vh - ${HEADER_HEIGHT}px);
  }
`

const Main = styled(GrommetMain).attrs(() => ({
  id: IDS.MAIN,
  'data-testid': IDS.MAIN,
  className: 'react-main',
  round: { corner: 'top', size: 'medium' },
  background: 'bg',
  // FIXME: for now, the page content itself needs to add padding to the content to avoid overlapping scrollbar.
  // Will fix when we revisit the layout/page structure.
  pad: { left: 'medium' },
  margin: { right: 'medium' },
  overflow: 'hidden'
}))`
  position: relative;

  #page-root:not(.filter-panel-open) & {
    margin-left: 16px;
  }

  #page-root:not(.right-nav-disabled):not(.right-panel-open) & {
    margin-right: 0;

    ${media.sm(css`
      margin-right: ${themeEdgeSize('medium')};
      max-height: calc(100vh - ${HEADER_HEIGHT}px - ${SUB_HEADER_HEIGHT}px - ${PAGE_NAV_MOBILE_HEIGHT}px);
    `)}
  }
  #page-root:not(.right-nav-disabled):not(.right-panel-open).show-banner & {
    ${media.sm(css`
      max-height: calc(
        100vh - ${HEADER_HEIGHT}px - ${SUB_HEADER_HEIGHT}px - ${BANNER_LAYOUT_HEIGHT}px - ${PAGE_NAV_MOBILE_HEIGHT}px
      );
    `)}
  }
`
const FilterPanel = styled(Box).attrs(() => ({
  id: IDS.FILTER_PANEL,
  'data-testid': IDS.FILTER_PANEL
}))`
  width: 0;
  min-width: 0;
  overflow-y: scroll;
  margin-left: 16px;
  visibility: hidden;
  display: none;
  padding-top: 4px;

  .filter-panel-open & {
    display: initial;
    visibility: visible;
    width: ${FILTER_PANEL_WIDTH}px;
    min-width: ${FILTER_PANEL_WIDTH}px;
    padding-left: 8px;
    padding-right: 12px;
    margin-right: 8px;
    margin-left: 8px;
  }

  .filter-panel-disabled & {
    display: none !important;
    width: 0 !important;
    min-width: 0 !important;
  }

  ${media.sm(css`
    .filter-panel-open & {
      width: 100%;
      min-width: 100%;
    }
  `)}

  ${media.md(css`
    .filter-panel-open.left-nav-panel-open & {
      width: 100%;
      min-width: 100%;
    }
  `)}
`

const InnerPage = styled(Box).attrs(() => ({
  id: IDS.INNER_PAGE,
  'data-testid': IDS.INNER_PAGE
}))`
  width: 100%;
  min-height: calc(100vh - ${HEADER_HEIGHT}px);
  height: calc(100vh - ${HEADER_HEIGHT}px);

  #page-root:not(.right-panel-open) & {
    width: 100%;
  }
`
const MainWrapper = styled(Box).attrs(() => ({
  id: IDS.MAIN_WRAPPER,
  'data-testid': IDS.MAIN_WRAPPER
}))`
  width: 100%;
`

// Needs more work -- main content scrolls underneath and the actual nav needs to switch from rol to rows.
const RightNav = styled(Box).attrs(() => ({
  id: IDS.RIGHT_NAV,
  'data-testid': IDS.RIGHT_NAV,
  background: 'bg-1'
}))`
  width: 0;

  #page-root:not(.right-nav-disabled) & {
    width: ${PAGE_NAV_DEFAULT_WIDTH}px;
    min-width: ${PAGE_NAV_DEFAULT_WIDTH}px;

    ${media.sm(css`
      width: 100%;
      // TODO: put on bottom
      height: ${PAGE_NAV_MOBILE_HEIGHT}px;
      min-height: ${PAGE_NAV_MOBILE_HEIGHT}px;
      position: absolute;
      bottom: 0;
    `)}
  }
`

const SidebarToggleButtonContainer = styled(Box)`
  display: none;

  #page-root:not(.left-nav-panel-open) & {
    ${media.sm(css`
      display: initial;
    `)}
  }
`

const Banner = styled(Box)`
  background-color: ${themeColor('bg')};
  .show-banner & {
    min-height: ${BANNER_HEIGHT}px;
    height: ${BANNER_HEIGHT}px;
  }
  ${media.sm(css`
    width: 100vw;
    position: absolute;
    bottom: ${PAGE_NAV_MOBILE_HEIGHT}px;
  `)}
`
