import { Controller, FieldValues, useFormContext } from 'react-hook-form'

import { Box, FormFieldDisplay, MultiSelect, SelectProps, Text } from '@cutover/react-ui'
import { FieldProps, getInputProps } from './form-fields'
import { StreamListStream, TaskListTask, TaskType } from 'main/services/queries/types'
import { TaskLineItem } from '../task-line-item'
import { useSetActiveRightPanelState } from 'main/components/layout/right-panel'

type TaskSelectProps = Omit<SelectProps<TaskListTask, number>, 'renderOption' | 'labelKey' | 'filterKeys' | 'options'>

export type TaskFieldProps<TFieldValues extends FieldValues> = FieldProps<TaskSelectProps, TFieldValues> & {
  tasks: TaskListTask[]
  taskTypeLookup: Record<string, TaskType>
  streamLookup: Record<string, StreamListStream>
  label: string
  disableTaskClick?: boolean
}

export const TaskSelectField = <TFieldValues extends FieldValues>({
  tasks,
  taskTypeLookup,
  streamLookup,
  disableTaskClick = false,
  ...props
}: TaskFieldProps<TFieldValues>) => {
  const { openRightPanel } = useSetActiveRightPanelState()
  const formContext = useFormContext<TFieldValues>()
  const inputProps = getInputProps<TFieldValues>({ ...props, formContext })
  const readOnly = inputProps.readOnly

  return (
    <>
      {readOnly ? (
        <FormFieldDisplay
          label={props.label}
          disabled={props.disabled}
          selectedItems={
            <Box
              width="100%"
              tag="ul"
              a11yTitle={`${props.a11yTitle ?? props.label} selected options`}
              css={`
                margin: 0;
                padding: 0;
                text-indent: 0;
                list-style: none;
              `}
            >
              {tasks.length === 0 ? (
                <Box pad={{ top: 'xsmall', bottom: 'xsmall' }}>
                  <Text>{props.placeholderValue}</Text>
                </Box>
              ) : (
                tasks
                  .sort((a, b) => a.internal_id - b.internal_id)
                  .map(
                    task =>
                      task?.id && (
                        <TaskLineItem
                          key={task.id}
                          task={task}
                          disabled={inputProps.disabled}
                          stream={streamLookup[task.stream_id]}
                          taskType={taskTypeLookup[task.task_type_id]}
                          onClick={() => !disableTaskClick && openRightPanel({ type: 'task-edit', taskId: task.id })}
                          role="option"
                        />
                      )
                  )
              )}
            </Box>
          }
        />
      ) : (
        <Controller
          name={props.name}
          control={formContext.control}
          render={({ field: { onChange, value, onBlur, ref } }) => {
            const tasksValue =
              tasks?.filter(task => {
                if (!props.single) {
                  if (props.valueKey) {
                    return value?.includes(task[props.valueKey as keyof TaskListTask])
                  } else {
                    return value?.includes(task.id)
                  }
                } else {
                  const parsedValue = Array.isArray(value) ? parseInt(value[0]) : parseInt(value)
                  if (props.valueKey) {
                    return parsedValue === task[props.valueKey as keyof TaskListTask]
                  } else {
                    return parsedValue === task.id
                  }
                }
              }) ?? []

            return (
              // @ts-ignore
              <MultiSelect<TaskListTask>
                {...props}
                {...inputProps}
                hideSelectedCount
                valueKey={props.valueKey ?? 'id'}
                filterKeys={['name', 'internal_id', 'label']}
                renderOption={(opt, { onDeselect, highlighted, selected }) => {
                  return (
                    <TaskLineItem
                      key={opt.id}
                      task={opt}
                      stream={streamLookup[opt.stream_id]}
                      taskType={taskTypeLookup[opt.task_type_id]}
                      active={highlighted}
                      onClick={
                        selected
                          ? () => !disableTaskClick && openRightPanel({ type: 'task-edit', taskId: opt.id })
                          : undefined
                      }
                      // @ts-ignore
                      onClickRemove={!readOnly && onDeselect ? () => onDeselect(opt.id) : undefined}
                      role="option"
                      disabled={props.disabled}
                    />
                  )
                }}
                icon="add"
                inputRef={ref}
                onBlur={onBlur}
                onChange={val => {
                  const ids = val?.map(v => (props.valueKey ? v[props.valueKey as keyof TaskListTask] : v.id))
                  onChange(!props.single ? ids : ids?.[0])
                }}
                // required for searching by "#internal_id"
                options={tasks.map(task => ({ ...task, label: `#${task.internal_id}` }))}
                value={tasksValue}
              />
            )
          }}
        />
      )}
    </>
  )
}
