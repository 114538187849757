import { memo } from 'react'
import { fromUnixTime } from 'date-fns'
import { Controller, useFormContext } from 'react-hook-form'

import { AccordionPanel, Box, DateTimePicker, RadioboxGroup, Text, TextInput, UserListItem } from '@cutover/react-ui'
import { useLanguage } from 'main/services/hooks'
import { RunbookEditRunbook, User } from 'main/services/queries/types'
import { RunbookUpdatePayload } from 'main/services/queries/use-update-runbook'

type RunbookEditTemplateSettingsPanelProps = {
  runbook: RunbookEditRunbook
  disabled?: boolean
  readOnly?: boolean
  hasTasks?: boolean
}

export const RunbookEditTemplateSettingsPanel = memo(
  ({ runbook, disabled, readOnly, hasTasks }: RunbookEditTemplateSettingsPanelProps) => {
    const { t } = useLanguage('runbooks', { keyPrefix: 'fields' })
    const isTemplate = runbook.template_type === 'default'
    const isSnippet = runbook.template_type === 'snippet'

    return (
      <AccordionPanel
        label={t('approvalSettings.label')}
        icon={isTemplate ? 'template' : 'settings'}
        iconColor={runbook.project?.color}
      >
        {isTemplate && <TemplateApprovalSettingsPanel runbook={runbook} />}
        {isSnippet && (
          <SnippetApprovalSettingsPanel runbook={runbook} disabled={disabled || !hasTasks} readOnly={readOnly} />
        )}
      </AccordionPanel>
    )
  }
)

const TemplateApprovalSettingsPanel = ({ runbook }: RunbookEditTemplateSettingsPanelProps) => {
  const { t } = useLanguage('runbooks', { keyPrefix: 'fields.approvalSettings' })
  const { reviews = [] } = runbook.current_version
  const { control } = useFormContext<RunbookUpdatePayload>()

  // are there supposed to be potentially multiple reviewers in each state?
  const approvedReview = reviews.find(review => review.outcome === 'approved')
  const rejectedReview = reviews.find(review => review.outcome === 'rejected')
  const pendingReview = reviews.find(review => review.outcome === null)

  return (
    <>
      {runbook.current_version.template_version && (
        <TextInput disabled label={t('latestVersion')} value={`v${runbook.current_version.template_version}`} />
      )}
      <Controller
        name="runbook.template_status"
        control={control}
        defaultValue="template_draft"
        render={({ field: { name, value, onChange, onBlur, ref } }) => (
          <RadioboxGroup
            name={name}
            disabled
            label={t('status.label')}
            direction="row"
            onChange={onChange}
            value={String(value)}
            onBlur={onBlur}
            ref={ref}
            options={[
              { value: 'template_draft', label: t('status.draft') },
              { value: 'template_approved', label: t('status.approved') },
              { value: 'template_rejected', label: t('status.rejected') }
            ]}
          />
        )}
      />
      {/* these are always disabled. They are shown as "disabled" right now because it they aren't even actual form fields ATM */}
      {approvedReview && <UserField user={approvedReview.reviewer} label={t('status.approvedBy')} />}
      {rejectedReview && <UserField user={rejectedReview.reviewer} label={t('status.rejectedBy')} />}
      {pendingReview && <UserField user={pendingReview.reviewer} label={t('status.awaitingReviewBy')} />}
      {runbook.template_status === 'template_approved' && runbook.template_next_review && (
        <DateTimePicker
          onChange={() => {}}
          label={t('nextReviewDate')}
          disabled
          value={fromUnixTime(runbook.template_next_review)}
        />
      )}

      {/* TODO: add reviews, see https://cutover.atlassian.net/browse/CFE-380 */}
      {/* {runbook.current_version.reviews && (
      <UserField user={} />

    )} */}
    </>
  )
}

const SnippetApprovalSettingsPanel = ({ runbook, disabled, readOnly }: RunbookEditTemplateSettingsPanelProps) => {
  const { t } = useLanguage('runbooks', { keyPrefix: 'fields.approvalSettings' })
  const { control } = useFormContext<RunbookUpdatePayload>()

  const isDisabled = disabled || !!runbook.current_version?.run // also disabled if there is a run
  return (
    <Controller
      name="runbook.template_status"
      control={control}
      render={({ field: { name, value, onChange, onBlur, ref } }) => (
        <RadioboxGroup
          name={name}
          onChange={onChange}
          value={String(value)}
          disabled={isDisabled}
          readOnly={readOnly}
          label={t('status.label')}
          direction="row"
          onBlur={onBlur}
          ref={ref}
          options={[
            { value: 'template_draft', label: t('status.draft') },
            { value: 'template_approved', label: t('status.approved') },
            { value: 'template_rejected', label: t('status.rejected') }
          ]}
        />
      )}
    />
  )
}

const UserField = ({ user, label }: { user: User; label: string }) => {
  return (
    <Box
      margin={{ bottom: '16px' }}
      border={{
        side: 'bottom',
        color: 'text-disabled',
        style: 'dashed'
      }}
      css={'opacity: 0.5;'} // To make it look disabled like other fields
    >
      <Text size="small" color="text-light">
        {label}
      </Text>
      <UserListItem
        size="small"
        id={user.id}
        key={user.id}
        name={user.name}
        online={user.online}
        color={user.color}
        draggable={false}
      />
    </Box>
  )
}
