import { memo } from 'react'

import { useRightPanelTypeState } from 'main/components/layout/right-panel'
import { IntegrationsPanel } from '../../runbook-edit/integrations/integrations-panel'

export const RunbookIntegrationsPanel = memo(() => {
  const [{ runbook, runbookVersionId }, { closeRightPanel }] = useRightPanelTypeState('runbook-integrations')

  return (
    <>
      {runbook && runbookVersionId && (
        <IntegrationsPanel runbook={runbook} runbookVersionId={runbookVersionId} onClose={closeRightPanel} />
      )}
    </>
  )
})
