import { RunbookComponent } from 'main/services/queries/types'
import {
  CollectionModelInterfaceBase,
  ModelMultiAccessBy,
  ModelSingleAccessBy,
  CollectionModelInterfaceWithCollectionAccessBy as WithCollectionAccessBy,
  CollectionModelInterfaceWithSingleAccessBy as WithSingleAccessBy
} from './types'
import {
  useRunbookComponent,
  useRunbookComponentBy,
  useRunbookComponentByCallback,
  useRunbookComponentCallback,
  useRunbookComponentLookup,
  useRunbookComponentLookupCallback,
  useRunbookComponents,
  useRunbookComponentsBy,
  useRunbookComponentsByCallback,
  useRunbookComponentsCallback
} from '../hooks'

type RunbookComponentMultiAccessBy = ModelMultiAccessBy<{ source_runbook_template_type: 'snippet' }>
type RunbookComponentSingleAccessBy = ModelSingleAccessBy<{ internal_id: number }>
export type RunbookComponentModelType = CollectionModelInterfaceBase<RunbookComponent> &
  WithSingleAccessBy<RunbookComponent, RunbookComponentSingleAccessBy> &
  WithCollectionAccessBy<RunbookComponent, RunbookComponentMultiAccessBy>

export const RunbookComponentModel: RunbookComponentModelType = {
  useGet: useRunbookComponent,
  useGetCallback: useRunbookComponentCallback,

  useGetAll: useRunbookComponents,
  useGetAllCallback: useRunbookComponentsCallback,

  useGetLookup: useRunbookComponentLookup,
  useGetLookupCallback: useRunbookComponentLookupCallback,

  useGetBy: useRunbookComponentBy,
  useGetByCallback: useRunbookComponentByCallback,

  useGetAllBy: useRunbookComponentsBy,
  useGetAllByCallback: useRunbookComponentsByCallback
}
