import { useEffect, useMemo, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useForm, useFormState } from 'react-hook-form'
import { MergeExclusive } from 'type-fest'
import * as yup from 'yup'

import { Box, Checkbox, colors, Icon, Link, Message, Modal, SearchBar, SpinnerIcon, Text } from '@cutover/react-ui'
import { LinkTemplateIntroduction } from './link-template-introduction'
import { LinkTemplateMultiSelect } from './link-template-multi-select'
import { LinkTemplateSelect } from './link-template-select'
import { LinkTemplateFilter } from './link-template-select-filter'
import { SelectedFilters } from 'main/components/shared/filter/filter-types'
import { useAccountCustomFields, useAccountRunbookTypes } from 'main/services/api/data-providers/account/account-data'
import { abbreviateNumber } from 'main/services/formatter'
import { useLanguage } from 'main/services/hooks'
import { RunbookTemplateRunbook as Template } from 'main/services/queries/types'
import { RunbooksResponse, RunbooksResponseMetaFull, useRunbooks } from 'main/services/queries/use-runbooks'
import { useSavedViewGroups } from 'main/services/queries/use-saved-view-groups'
import { ConfigModel, CurrentUserModel } from 'main/data-access'

export type LinkTemplateProps = {
  runbookId: string
  accountId: string
  onClose: () => void
} & MergeExclusive<
  {
    multiSelect: true
    onSubmit: (template: Template[]) => void
    selectedTemplateData?: number[]
  },
  {
    multiSelect?: false
    onSubmit: (template: Template) => void
    selectedTemplateId?: number
  }
>

type LinkTemplateForm = {
  templateData: number[] | number | null
}

export const LinkTemplateSelectModal = ({
  runbookId,
  accountId,
  selectedTemplateData,
  onSubmit,
  onClose,
  multiSelect
}: LinkTemplateProps) => {
  const { customFields } = useAccountCustomFields()
  const { templateStatuses } = ConfigModel.useGet()
  const { runbookTypes } = useAccountRunbookTypes()
  const [allTemplateData, setAllTemplateData] = useState<
    RunbooksResponse<Template, RunbooksResponseMetaFull> | undefined
  >()
  const [showOnlyApprovedTemplates, setShowOnlyApprovedTemplatesFlag] = useState(true)
  const [search, setSearch] = useState<string>('')
  const currentUser = CurrentUserModel.useGet()
  const [showMarketing, setShowMarketing] = useState<boolean>(
    !currentUser.frontend_user_setting?.data?.linked_template_marketing_modal_hidden
  )
  const [selectedFilters, setSelectedFilters] = useState<SelectedFilters>({ ts: ['template_approved'] })
  const { t } = useLanguage('runbook')
  const [noTemplatesError, setNoTemplatesError] = useState(false)
  const incidentTypeIds: number[] = runbookTypes?.filter(rt => rt.incident).map(rt => rt.id) || []

  const fields = ['id', 'meta', 'name', 'runbook_type_id', 'tasks_count', 'template_status']
  const baseParams = {
    accountId,
    t: true,
    sort_by: 'name',
    sort_dir: 'asc',
    // was previously `meta: initialMount`, but for some reason two requests are made
    // and both need to return meta for Folder list to populate.
    meta: true,
    limit: Number.MAX_SAFE_INTEGER,
    fields
  }

  // Search results
  const {
    data: runbookDataResponse,
    isLoading: isLoadingRunbooks,
    isError: isErrorRunbooks
  } = useRunbooks<Template, RunbooksResponseMetaFull>({
    ...baseParams,
    q: search,
    ...selectedFilters
  })

  // To decouple ALL runbooks from filters we have 2 separate calls. 1 for multi select + filters and 1 for the results
  const {
    data: allRunbookDataResponse,
    isLoading: isLoadingAllRunbooks,
    isError: isErrorAllRunbooks
  } = useRunbooks<Template, RunbooksResponseMetaFull>(baseParams)

  useEffect(() => {
    //show only approved templates, clear template statuses filter when main checkbox is off
    showOnlyApprovedTemplates
      ? setSelectedFilters({ ...selectedFilters, ts: ['template_approved'] })
      : setSelectedFilters({ ...selectedFilters, ts: [] })
  }, [showOnlyApprovedTemplates])

  const filteredRunbookData = useMemo(
    //filter parent template from the list template list to avoid recursive link task creation
    () => filterTemplatesById(runbookDataResponse?.runbooks || [], runbookId),
    [runbookDataResponse]
  )

  const { control, handleSubmit, watch, setValue, trigger } = useForm<LinkTemplateForm>({
    resolver: yupResolver(
      yup.object({
        templateData: yup
          .mixed()
          .when('$multiSelect', ([multiSelect]) =>
            multiSelect ? yup.array().min(1).required() : yup.number().required()
          )
      })
    ),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      templateData: selectedTemplateData || multiSelect ? selectedTemplateData || [] : null
    },
    context: {
      multiSelect
    }
  })

  const templateData = watch('templateData')
  const { isValid } = useFormState({ control })
  const { data: savedViewGroupsData, isLoading: isLoadingSavedViewGroups } = useSavedViewGroups(accountId)

  const handleFilterChange = (selected: SelectedFilters) => {
    //set only approved templates if main checkbox is on
    //otherwise allow to select all filters
    showOnlyApprovedTemplates
      ? setSelectedFilters({ ...selected, ts: ['template_approved'] })
      : setSelectedFilters(selected)
  }

  const modalTitle = showMarketing ? t('linkTemplateIntroductionDetailsModal.heading') : t('linkTemplateModal.heading')

  useEffect(() => {
    // sets the intial template response for all runbooks in state to be used in the multiSelect + filters
    // filter parent template from the list template list to avoid recursive link task creation
    if (!allTemplateData && !isErrorAllRunbooks && allRunbookDataResponse && allRunbookDataResponse.runbooks) {
      setAllTemplateData({
        meta: allRunbookDataResponse?.meta,
        runbooks: filterTemplatesById(allRunbookDataResponse.runbooks, runbookId)
      })
    }
  }, [allRunbookDataResponse, isErrorAllRunbooks])

  const onSelectAll = () => {
    if (!multiSelect || !runbookDataResponse) return

    const unselectedTemplates = runbookDataResponse.runbooks.filter(
      r =>
        //@ts-ignore we know templateData is an array due to form context
        // TODO: Please keep inline with link-template-select.tsx. This is scheduled for a refactor so we should centralise
        !templateData.includes(r.id) &&
        !r.meta?.linked_status?.is_parent &&
        r.tasks_count !== 0 &&
        !incidentTypeIds.includes(r.runbook_type_id)
    )
    //filter parent template from the unselected templates list to avoid recursive link task creation
    const unselectedTemplatesFiltered = filterTemplatesById(unselectedTemplates, runbookId)
    setValue('templateData', [...(templateData as number[]), ...unselectedTemplatesFiltered.map(({ id }) => id)])
    trigger()
  }

  const resultLength = useMemo(() => abbreviateNumber(filteredRunbookData.length), [runbookDataResponse, templateData])

  // TODO: this whole clearing mechanism is a workaround and mimics the clearing functionality elsewhere. To fix this,
  // we need a controlled and uncontrolled version of the component, or one that can be cleared by the user via a ref
  const [isSearchCleared, setIsSearchCleared] = useState<boolean>(false)

  useEffect(() => {
    setIsSearchCleared(false)
  }, [isSearchCleared])

  const handleSearchTerm = (term: string) => {
    if (term) {
      const idsSearchRegExp = /#[0-9]+(?=,|$)/g
      const isRunbookIdSearch = term.match(idsSearchRegExp)
      if (isRunbookIdSearch) {
        const runbookIds = term.replace(/#|\s/g, '').split(',')
        setSelectedFilters({ ...selectedFilters, runbook_id: runbookIds, q: '' })
      } else {
        setSelectedFilters({ ...selectedFilters, runbook_id: undefined, q: term })
      }
    } else {
      showOnlyApprovedTemplates
        ? setSelectedFilters({ ...selectedFilters, runbook_id: undefined, ts: ['template_approved'], q: '' })
        : setSelectedFilters({ ...selectedFilters, runbook_id: undefined, q: '' })
    }
  }

  const clearSearch = () => {
    setSelectedFilters({})
    setIsSearchCleared(true)
    setSearch('')
    setShowOnlyApprovedTemplatesFlag(false)
  }

  return (
    <Modal
      width={!showMarketing ? 850 : undefined}
      title={modalTitle}
      open
      onClose={onClose}
      confirmText={multiSelect ? t('linkTemplateModal.next') : t('linkTemplateModal.submit')}
      onClickConfirm={
        isLoadingRunbooks || isErrorRunbooks || !isValid
          ? undefined
          : handleSubmit(form => {
              if (multiSelect) {
                const orderedTemplates = orderSelectedTemplatesList(allTemplateData, form.templateData)
                !orderedTemplates.length ? setNoTemplatesError(true) : onSubmit(orderedTemplates)
              } else {
                const template = filteredRunbookData.find(t => form.templateData === t.id)
                // @ts-ignore - we know that templateData is not an array bc of form context
                if (template) onSubmit(template)
              }
            })
      }
      confirmIcon="add"
      loading={isLoadingRunbooks}
      hideFooter={showMarketing}
    >
      {showMarketing ? (
        <LinkTemplateIntroduction onClose={() => setShowMarketing(false)} />
      ) : (
        <Box direction="row" width="100%" justify="between">
          <Box width="35%" height="100%" css="overflow-y: auto; overflow-x: hidden">
            {allTemplateData && savedViewGroupsData?.saved_view_groups ? (
              <LinkTemplateFilter
                centralTeams={allTemplateData.meta.teams}
                customFieldIds={allTemplateData.meta.custom_field_ids}
                customFieldOptionIds={allTemplateData.meta.field_option_ids}
                customFields={customFields}
                folders={allTemplateData.meta.projects}
                onChange={handleFilterChange}
                runbookTypes={runbookTypes}
                selected={selectedFilters}
                templateStatuses={templateStatuses}
                savedViewGroups={savedViewGroupsData.saved_view_groups}
              />
            ) : (
              <Box justify="center" align="center" height="90%">
                {isLoadingAllRunbooks || isLoadingSavedViewGroups ? <SpinnerIcon /> : <Icon icon="alert" />}
              </Box>
            )}
          </Box>
          <Box width="60%" gap="xsmall">
            {isErrorRunbooks && <Message type="error" message={t('linkTemplateModal.serverError')} />}
            {noTemplatesError && <Message type="error" message={t('linkTemplateModal.noMatches')} />}
            <Box justify="between" direction="row" align="center">
              <Box width="65%">
                <SearchBar
                  isSearchCleared={isSearchCleared}
                  width="100%"
                  open
                  onSearch={(term: string) => handleSearchTerm(term)}
                  placeholder={t('linkTemplateModal.searchPlaceholder')}
                />
                <div style={{ paddingTop: '10px' }}>
                  <Checkbox
                    onChange={() => setShowOnlyApprovedTemplatesFlag(!showOnlyApprovedTemplates)}
                    checked={showOnlyApprovedTemplates}
                    label={t('linkTemplateModal.onlyShowApprovedTemplates')}
                  />
                </div>
              </Box>
              <Box
                direction="row"
                justify="center"
                align="end"
                height="100%"
                gap="xsmall"
                width="35%"
                pad={{ bottom: '7px' }}
              >
                {isLoadingRunbooks ? (
                  <SpinnerIcon />
                ) : (
                  <Text size="14px" color={colors.text}>
                    {t('linkTemplateModal.results', { amount: resultLength })}
                  </Text>
                )}
                {multiSelect && (
                  <Link
                    data-testid="link-template-modal-select-all"
                    css="margin-bottom: -2px"
                    color={colors.primary}
                    colorHover={colors.primaryLight}
                    onClick={onSelectAll}
                  >
                    <Text size="14px">{t('linkTemplateModal.selectAll')}</Text>
                  </Link>
                )}
              </Box>
            </Box>

            <Controller
              control={control}
              name="templateData"
              render={({ field: { onChange } }) =>
                multiSelect ? (
                  <LinkTemplateMultiSelect
                    isLoading={isLoadingRunbooks}
                    templates={allTemplateData?.runbooks}
                    runbookTypes={runbookTypes}
                    onChange={ids => onChange(ids)}
                    selected={templateData as number[]}
                    filteredTemplates={filteredRunbookData}
                    onFilterClear={clearSearch}
                    isFiltered={!!search || Object.keys(selectedFilters).length > 0}
                  />
                ) : (
                  <LinkTemplateSelect
                    selected={templateData}
                    isLoading={isLoadingRunbooks}
                    templates={filteredRunbookData}
                    runbookTypes={runbookTypes}
                    onClick={(_, { id }) => onChange(id)}
                    isEmpty={allTemplateData?.runbooks.length === 0}
                    onFilterClear={clearSearch}
                    noMatches={
                      filteredRunbookData.length === 0 && (!!search || Object.keys(selectedFilters).length > 0)
                    }
                    height={500}
                  />
                )
              }
            />
          </Box>
        </Box>
      )}
    </Modal>
  )
}

const filterTemplatesById = (templates: Template[], id: string) =>
  templates.filter(template => template.id.toString() !== id)

const orderSelectedTemplatesList = (
  allData: RunbooksResponse<Template, RunbooksResponseMetaFull> | undefined,
  selectedIds: number[] | number | null
) => {
  // @ts-ignore - we know that selectedIds (form.templateData) is an array bc of form context
  const listOfSelectedIds = selectedIds.map(t => t.toString())
  const selectedTemplatesList = allData?.runbooks.filter(t => listOfSelectedIds.includes(t.id.toString()))
  const selectedTemplatesListOrdered = selectedTemplatesList?.sort(
    (a, b) => listOfSelectedIds.indexOf(a.id.toString()) - listOfSelectedIds.indexOf(b.id.toString())
  )
  return selectedTemplatesListOrdered ? selectedTemplatesListOrdered : []
}
