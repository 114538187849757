import { useRecoilCallback, useRecoilValue } from 'recoil'

import { accountCustomFieldsState, customFieldLookup } from 'main/recoil/runbook'
import { CustomFieldModelType } from 'main/data-access/models'

/* -------------------------------------------------------------------------- */
/*                                     Get                                    */
/* -------------------------------------------------------------------------- */

export const useGetCustomField: CustomFieldModelType['useGet'] = (identifier: number) => {
  const lookup = useRecoilValue(customFieldLookup)
  return lookup[identifier]
}

export const useGetCustomFieldCallback: CustomFieldModelType['useGetCallback'] = () => {
  return useRecoilCallback(
    ({ snapshot }) =>
      async (identifier: number) => {
        const lookup = await snapshot.getPromise(customFieldLookup)
        return lookup[identifier]
      },
    []
  )
}

/* -------------------------------------------------------------------------- */
/*                                  Get All                                   */
/* -------------------------------------------------------------------------- */

export const useGetCustomFields: CustomFieldModelType['useGetAll'] = () => {
  return useRecoilValue(accountCustomFieldsState)
}

export const useGetCustomFieldsCallback: CustomFieldModelType['useGetAllCallback'] = () => {
  return useRecoilCallback(
    ({ snapshot }) =>
      () =>
        snapshot.getPromise(accountCustomFieldsState),
    []
  )
}

/* -------------------------------------------------------------------------- */
/*                                  Lookup                                    */
/* -------------------------------------------------------------------------- */

export const useGetCustomFieldLookup: CustomFieldModelType['useGetLookup'] = () => {
  return useRecoilValue(customFieldLookup)
}

export const useGetCustomFieldsLookupCallback: CustomFieldModelType['useGetLookupCallback'] = () =>
  useRecoilCallback(
    ({ snapshot }) =>
      () =>
        snapshot.getPromise(customFieldLookup),
    []
  )
