import { useState } from 'react'
import styled from 'styled-components'
import parse from 'html-react-parser'

import { Box, Text, TextLink, themeColor } from '@cutover/react-ui'
import { formatActivityDetailsValue, renderMessageMarkup } from './activity-helper'
import { ActivityVM } from 'main/services/queries/use-activities'
import { useAppsTimezoneValue } from 'main/recoil/data-access'

type ActivityDetailsProps = {
  activity: ActivityVM
  index: number
  scrollToActivity: (index: number) => void
  grouped: boolean
}

const DEFAULT_CHANGES_LINES: number = 1
const MIN_CHANGES_LINES: number = 2
export const DEFAULT_MESSAGE_CHARACTERS: number = 800

export const ActivityDetails = ({ activity, index, scrollToActivity, grouped }: ActivityDetailsProps) => {
  const { changes, display } = activity
  const timezone = useAppsTimezoneValue()
  const [showMore, setShowMore] = useState(true)
  const isChangesMinLines = changes && changes?.length > MIN_CHANGES_LINES
  const commentIsDeleted = !!activity.display?.reason
  const changesList = showMore && isChangesMinLines ? changes?.slice(0, DEFAULT_CHANGES_LINES) : changes
  const message = showMore ? display?.message?.substring(0, DEFAULT_MESSAGE_CHARACTERS) : display?.message
  const showToggle = isChangesMinLines || (display?.message && display?.message?.length > DEFAULT_MESSAGE_CHARACTERS)
  const attachment = display?.attachments?.[0] // can only be 1 currently

  const toggleShowMore = () => {
    setShowMore(!showMore)
    scrollToActivity(index)
  }

  return (
    <ActivityDetailsWrapper flex={false} align="start" justify="start" featured={activity.featured}>
      {message && (
        <CommentWrapper grouped={grouped}>
          <Text
            color={commentIsDeleted ? 'text-disabled' : 'text-light'}
            css={`
              line-height: 20px;
              font-style: ${commentIsDeleted ? 'italic' : ''};
            `}
          >
            {commentIsDeleted
              ? `Comment deleted (Reason: ${activity.display?.reason})`
              : parse(renderMessageMarkup(message) as string)}
          </Text>
        </CommentWrapper>
      )}
      {changesList?.map((param, key) => (
        <ChangesListWrapper
          alignContent="start"
          key={key}
          direction="row"
          pad={{ vertical: '2px' }}
          data-testid={`changes-list-activity-${activity.id}`}
          gap="small"
        >
          {param.field && (
            <Box alignSelf="start" width="150px" css="flex: 0 0 auto;">
              <Text truncate="tip" color="text-light" weight={600}>
                {param.field}
              </Text>
            </Box>
          )}
          {param.value && (
            <Box alignSelf="start" css="flex: 1;">
              <Text color="text-light">{parse(formatActivityDetailsValue(param.value, timezone))}</Text>
            </Box>
          )}
        </ChangesListWrapper>
      ))}
      <DetailsFooterWrapper message={message}>
        {showToggle && <TextLink label={showMore ? '+ Show more' : '- Show less'} onClick={toggleShowMore} small />}
        {attachment && showToggle && (
          <Text color="text-light" size="13px;" css="flex-shrink: 0;">
            &nbsp;•&nbsp;
          </Text>
        )}
        {attachment && (
          <TextLink
            data-testid={`attachment-${activity.id}-${attachment.name}`}
            href={attachment.url}
            label={attachment.name}
            icon={attachment?.type?.includes('image') ? 'image' : 'page-text'}
            openInNew
            small
          />
        )}
      </DetailsFooterWrapper>
    </ActivityDetailsWrapper>
  )
}

const ActivityDetailsWrapper = styled(Box)<{ featured: boolean }>`
  border-left: solid 2px ${props => (props.featured ? themeColor('star') : themeColor('bg-2-alpha'))};
  margin-left: 59px;
  padding: 0 23px;

  table {
    width: 100%;
    margin-top: 16px;
    margin-bottom: 16px;
    border-collapse: collapse;
  }

  table th {
    font-weight: 700;
    padding: 8px;
    text-align: left;
    line-height: 22px;
    border-collapse: collapse;
    border: 1px solid ${themeColor('bg-4')};
    background-color: ${themeColor('bg-1')};
    min-width: 40px;
  }

  table tr td {
    display: table-cell;
    padding: 8px;
    line-height: 22px;
    border-collapse: collapse;
    border: 1px solid ${themeColor('bg-4')};
    min-width: 40px;
  }

  h4 {
    margin: 16px 0 !important;
    line-height: 22px !important;
    font-size: 17px !important;
    font-weight: 700 !important;
  }

  li {
    margin: 0 !important;
    padding: 0 !important;
    list-style-type: inherit;
  }

  li p {
    margin: 0;
  }

  pre {
    display: block;
    font-family: monospace;
    white-space: pre;
    margin: 16px 0px;
    padding: 16px;
    border-radius: 8px;
    line-height: 20px;
    color: ${themeColor('text-light')};
    background-color: ${themeColor('bg-1')};
  }

  code {
    padding: 0 4px;
    border-radius: 4px;
    line-height: 22px;
    display: inline-block;
    color: ${themeColor('error')};
    background-color: ${themeColor('bg-1')};
  }

  blockquote {
    padding-left: 16px;
    font-style: italic;
    margin-left: 0;
    margin-right: 0;
    line-height: 22px;
    border-left: 4px solid ${themeColor('bg-2')};
    color: ${themeColor('text-light')};
  }

  p {
    line-height: 22px;
    margin: 16px 0;
  }

  p:first-child {
    margin-top: 0;
  }

  a {
    color: ${themeColor('primary')};
    text-decoration: none;
  }

  font-size: 15px;
  line-height: 22px;
  color: ${themeColor('text-light')};
`
const CommentWrapper = styled(Box)<{ grouped: boolean }>`
  width: 100%;
  padding: ${props => (props.grouped ? '6px' : '0')} 0 6px 0;
`

const ChangesListWrapper = styled(Box)`
  p {
    margin: 0;
  }
`

const DetailsFooterWrapper = styled(Box).attrs(({ message }: { message?: string }) => ({
  direction: 'row',
  height: '100%',
  width: '100%',
  margin: { top: `${message ? '-16px' : '0'}` },
  pad: { vertical: 'xxsmall' },
  gap: 'xxsmall',
  align: 'center'
}))<{ message?: string }>`
  overflow: hidden;
`
