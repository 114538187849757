import { ThemeType as GrommetThemeType } from 'grommet/themes'
import { Merge, SetRequired } from 'type-fest'

import { button } from './button'
import { checkBox } from './check-box'
import { dataTable } from './data-table'
import { global, ThemeGlobal } from './global'
import { heading } from './heading'
import { icon, ThemeIcon } from './icon'
import { menu } from './menu'
import { radioButtonGroup } from './radio-button-group'
import { spinner } from './spinner'
import { table } from './table'
import { text, ThemeText } from './text'
import { textInput } from './text-input'
import { tip } from './tip'

/*
  Exports the type and value for the full grommet theme

  Individual theme keys can have dedicated files when long and as the theme grows.
*/

type GrommetTheme = SetRequired<
  GrommetThemeType,
  | 'global'
  | 'icon'
  | 'text'
  | 'heading'
  | 'menu'
  | 'radioButtonGroup'
  | 'dataTable'
  | 'table'
  | 'spinner'
  | 'tip'
  | 'textInput'
  | 'checkBox'
>

export const theme: Merge<GrommetTheme, { global: ThemeGlobal; icon: ThemeIcon; text: ThemeText }> = {
  global,
  icon,
  heading,
  text,
  button,
  menu,
  radioButtonGroup,
  dataTable,
  table,
  spinner,
  tip,
  textInput,
  checkBox
}

export type ThemeType = typeof theme & { dark: boolean }

// Creating this separately so we don't have new objects created during code execution when fetching theme
export const darkTheme: ThemeType = {
  ...theme,
  dark: true
}
