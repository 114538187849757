import enGB from 'date-fns/locale/en-GB'
import { format, getTimezoneOffset, utcToZonedTime } from 'date-fns-tz'
// @ts-ignore - no @types available - TODO: look for replacement
import tz from 'timezone/loaded'

import { getDuration } from '@cutover/react-ui'

export type Timezone = {
  id: string
  name: string
  offsetString: string
  formattedTimezoneString: string
  tzAbbreviation: string
}

export const getClientIANATimezone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone
}

export const getTimezones = (targetDate: Date | null = null): Timezone[] => {
  const allTimezones = getAllTimezones()
  const commonTimezonesWithOffset = commonTimezones.map(commonTimezone => timezoneToObject(commonTimezone, targetDate))

  if (!allTimezones.length) {
    return commonTimezonesWithOffset
  }

  const timezonesWithOffset = getAllTimezones()
    .filter(timezone => !commonTimezones.includes(timezone))
    .map(timezone => timezoneToObject(timezone, targetDate))

  return [...commonTimezonesWithOffset, ...timezonesWithOffset]
}

export const getUtcToZonedTime = (isoDate: string, timezone: string) => utcToZonedTime(isoDate, timezone)

export const timezoneToObject = (
  timezone: string = getClientIANATimezone(),
  targetDate: Date | null = null
): Timezone => {
  const offsetString = offsetToString(timezone, targetDate)
  const tzAbbreviation = getTimezoneAbbreviation(timezone, targetDate)
  const formattedTimezoneString = `${tzAbbreviation} - ${formatTimezoneString(timezone)}`

  return {
    id: timezone,
    name: `${offsetString} ${formattedTimezoneString}`,
    offsetString: offsetString,
    formattedTimezoneString: formattedTimezoneString,
    tzAbbreviation
  }
}

export const offsetToString = (timezone: string, targetDate?: Date | null) => {
  const offset = targetDate ? getTimezoneOffset(timezone, targetDate) : getTimezoneOffset(timezone)
  const negative = offset < 0
  const mod = negative ? '-' : '+'
  const offsetNumber = negative ? -1 * offset : offset

  const { hours, minutes } = getDuration(offset / 1000)
  const formatted = offsetNumber === 0 ? '00:00' : `${formatTime(hours ?? 0)}:${formatTime(minutes ?? 0)}`

  return `GMT${mod}${formatted}`
}

const formatTimezoneString = (timezone: string) => {
  const tzArr = timezone.split('/')
  return tzArr[tzArr.length - 1].replace('_', ' ')
}

const getTimezoneAbbreviation = (timezone: string, targetDate: Date | null): string => {
  // to be refactored
  // date-fns-tz does not generate all timezone abbreviations that we would want (like moment-timezone did)
  // current work around is using this timezone (very small 4kb) lib to generate a date string with
  // timezone abbreviation at the end (and slice the timezone abbreviation for our use)

  try {
    // %c (in strftime) -- formats to a preferred date and time representation for the current locale
    const dateStringSplit = targetDate
      ? tz(targetDate, '%c', 'en_GB', timezone).split(' ')
      : tz(new Date(), '%c', 'en_GB', timezone).split(' ')
    return dateStringSplit[dateStringSplit.length - 1].replace('%s', 'S')
  } catch {
    // if error (for handful of timezones), produce it using the date-fns-tz format method
    return targetDate
      ? format(targetDate, 'zzz', { timeZone: timezone, locale: enGB })
      : format(new Date(), 'zzz', { timeZone: timezone, locale: enGB })
  }
}

const formatTime = (number: number) => (number < 10 ? '0' + number : number)

const commonTimezones = ['America/New_York', 'Europe/London', 'Europe/Paris', 'Asia/Calcutta', 'Asia/Singapore']

// Intl api way of getting all the timezones
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/supportedValuesOf
// date-fns-tz we're using: timezone support for date-fns using the Intl API - no timezone data needs to be included in code bundle
const getAllTimezones = (): string[] => {
  // @ts-ignore - unfortunately latest TS version does not support this method
  return Intl?.hasOwnProperty('supportedValuesOf') ? Intl.supportedValuesOf('timeZone') : []
}
