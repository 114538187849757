import { useRef } from 'react'

type OptionConfig = {
  /** Used in collection model hooks employing {@link ModelCollectionAccessScope} */
  scope?: string
  /** Used in collection model hooks employing {@link ModelMultiAccessBy} */
  keyBy?: string
  /** Used in collection model hooks employing {@link ModelSingleAccessBy} */
  [x: string]: any
}

/**
 * An hook for Model functions that violate the react {@link https://react.dev/reference/rules/rules-of-hooks Rules of hooks} by internally
 * calling other hooks conditionally. While the exact value of the input may change in some cases, the string that it resolves to must remain
 * constant with subsequent input.
 * @param args of the Model function responsible for determining which hook is called
 * @returns string The input resolved to a string representing a value that must hold constant across calls.
 * @throws error {@link StableArgsViolation} if the key generated by the input changes between calls

 * @see {@link [CollectionModelInterface*](./types/collection-model-interfaces.ts)} types for hooks with parameters
 */
export function useEnsureStableArgs(args?: OptionConfig | string) {
  const initialArgsKeyRef = useRef(resolveArgsKey(args))

  if (initialArgsKeyRef.current !== resolveArgsKey(args)) {
    throw new StableArgsViolation({ initialArgsKey: initialArgsKeyRef.current, newArgsKey: resolveArgsKey(args) })
  }

  return initialArgsKeyRef.current
}

class StableArgsViolation extends Error {
  public initialArgsKey?: string
  public newArgsKey?: string

  constructor({
    initialArgsKey: initialArgsKey,
    newArgsKey: newArgsKey
  }: {
    initialArgsKey?: string
    newArgsKey?: string
  }) {
    super('Rules of hooks violation: useEnsureStableArgs')
    this.name = 'StableKeyViolation'
    this.initialArgsKey = initialArgsKey
    this.newArgsKey = newArgsKey
  }
}

function resolveArgsKey(input?: OptionConfig | string) {
  if (typeof input === 'string') return input
  if (!input) return input

  const { scope, keyBy, ...restConfig } = input

  return [
    ...(keyBy ? [`keyBy:${keyBy}`] : []),
    ...(scope ? [`scope:${scope}`] : []),
    ...Object.keys(restConfig).sort()
  ].join(',')
}
