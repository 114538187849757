import { useRecoilCallback, useRecoilValue } from 'recoil'

import { RunbookComponentModelType } from 'main/data-access/models/runbook-components-model'
import {
  runbookComponentByInternalIdState,
  runbookComponentsSnippets,
  runbookComponentsState,
  runbookComponentsStateLookup,
  runbookComponentState
} from 'main/recoil/runbook/models/runbook-version/runbook-components'
import { useEnsureStableArgs } from 'main/data-access/models/model-utils'

export const useRunbookComponent: RunbookComponentModelType['useGet'] = id => {
  return useRecoilValue(runbookComponentState(id))
}

export const useRunbookComponentCallback: RunbookComponentModelType['useGetCallback'] = () =>
  useRecoilCallback(
    ({ snapshot }) =>
      async id => {
        return await snapshot.getPromise(runbookComponentState(id))
      },
    []
  )

export const useRunbookComponents: RunbookComponentModelType['useGetAll'] = () => {
  return useRecoilValue(runbookComponentsState)
}

export const useRunbookComponentsCallback: RunbookComponentModelType['useGetAllCallback'] = () =>
  useRecoilCallback(
    ({ snapshot }) =>
      async () => {
        return await snapshot.getPromise(runbookComponentsState)
      },
    []
  )

export const useRunbookComponentLookup: RunbookComponentModelType['useGetLookup'] = () => {
  return useRecoilValue(runbookComponentsStateLookup)
}

export const useRunbookComponentLookupCallback: RunbookComponentModelType['useGetLookupCallback'] = () =>
  useRecoilCallback(
    ({ snapshot }) =>
      async () => {
        return await snapshot.getPromise(runbookComponentsStateLookup)
      },
    []
  )

export const useRunbookComponentBy: RunbookComponentModelType['useGetBy'] = getBy => {
  useEnsureStableArgs(getBy)

  return useRecoilValue(runbookComponentByInternalIdState(getBy?.internal_id))
}

export const useRunbookComponentByCallback: RunbookComponentModelType['useGetByCallback'] = () =>
  useRecoilCallback(
    ({ snapshot }) =>
      async getBy => {
        return await snapshot.getPromise(runbookComponentByInternalIdState(getBy?.internal_id))
      },
    []
  )

export const useRunbookComponentsBy: RunbookComponentModelType['useGetAllBy'] = getBy => {
  useEnsureStableArgs(getBy)

  return useRecoilValue(runbookComponentsSnippets)
}

export const useRunbookComponentsByCallback: RunbookComponentModelType['useGetAllByCallback'] = () =>
  useRecoilCallback(
    ({ snapshot }) =>
      async () => {
        return await snapshot.getPromise(runbookComponentsSnippets)
      },
    []
  )
