import { memo } from 'react'
import { getUnixTime } from 'date-fns'

import { DateTimePicker, FilterAccordionPanel } from '@cutover/react-ui'
import { useDateFromFilter, useDateToFilter } from 'main/recoil/data-access'

export const DateGroup = memo(() => {
  const [dateFrom, setDateFrom] = useDateFromFilter()
  const [dateTo, setDateTo] = useDateToFilter()

  const dateFromValue = dateFrom ? new Date(dateFrom * 1000) : null
  const dateToValue = dateTo ? new Date(dateTo * 1000) : null

  return (
    <FilterAccordionPanel label="Date" applied={!!dateFrom || !!dateTo}>
      <DateTimePicker
        value={dateFromValue}
        onChange={date => setDateFrom(date ? getUnixTime(date) : undefined)}
        label="From"
      />
      <DateTimePicker
        value={dateToValue}
        onChange={date => setDateTo(date ? getUnixTime(date) : undefined)}
        label="To"
      />
    </FilterAccordionPanel>
  )
})
