import { useDefaultLayout } from '@cutover/react-ui'
import { SamlConfigEditPanel } from './right-panels/saml-configurations-edit-panel'
import { SamlConfigurationsHeader } from './saml-configurations-header/saml-configurations-header'
import { SamlConfigurationsSubHeader } from './saml-configurations-sub-header/saml-configurations-sub-header'
import { Layout } from 'main/components/layout'
import { useHasPermissionsOrNavigateHome } from 'main/services/hooks'

export const SamlConfigurationsLayout = () => {
  const userCanViewPage = useHasPermissionsOrNavigateHome('saml_configurations')

  useDefaultLayout({
    filterPanel: false,
    subHeader: true,
    rightNav: false
  })

  return userCanViewPage ? (
    <Layout
      header={<SamlConfigurationsHeader />}
      subHeader={<SamlConfigurationsSubHeader />}
      filter={null}
      rightPanels={<SamlConfigEditPanel />}
    />
  ) : null
}
