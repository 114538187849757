import { Ref, useCallback, useEffect, useRef } from 'react'

type UseShowOpenedPanelProps = {
  scrollEl?: Ref<HTMLElement> | string | HTMLElement | null
  accordionEl?: Ref<HTMLElement> | string | HTMLElement | null
  transitionMs?: number
}

export const useScrollOpenPanel = ({ scrollEl, accordionEl, transitionMs = 200 }: UseShowOpenedPanelProps) => {
  const scrollContainer = resolveElement(scrollEl)
  const accordionContainer = resolveElement(accordionEl)

  const panelHeaders = accordionContainer?.querySelectorAll('[aria-expanded]')
  const panelCount = panelHeaders?.length

  const mutationCallback: MutationCallback = useCallback(
    mutationList => {
      for (const mutation of mutationList) {
        if (!(mutation.target instanceof HTMLElement)) return
        const headingButton = mutation.target
        // if the change was to the accordion panel header's `aria-expanded` and its now expanded
        if (mutation.attributeName === 'aria-expanded' && headingButton.getAttribute('aria-expanded') === 'true') {
          const contentRegion = headingButton.nextElementSibling as HTMLElement // content region

          // we have to set a timeout to account for the panel expand animation
          setTimeout(() => {
            if (
              headingButton &&
              scrollContainer &&
              contentRegion &&
              !isElementWithin({ el: contentRegion, container: scrollContainer })
            ) {
              const { top: containerTop } = scrollContainer?.getBoundingClientRect()
              const { top: headerTop } = headingButton.getBoundingClientRect()

              scrollContainer.scrollBy({
                top: headerTop - containerTop,
                behavior: 'smooth'
              })
            }
          }, transitionMs)
        }
      }
    },
    [scrollContainer, accordionContainer]
  )

  const observerRef = useRef<MutationObserver | null>(null)

  useEffect(() => {
    // always disconnect and start fresh observations when this runs to prevent memory leaks
    observerRef.current?.disconnect()

    if (!scrollEl || !accordionEl || !panelHeaders) return
    if (!observerRef.current) {
      observerRef.current = new MutationObserver(mutationCallback)
    }

    panelHeaders?.forEach(header => observerRef.current?.observe(header, { attributes: true }))

    return () => observerRef.current?.disconnect()
  }, [panelCount, scrollContainer, accordionContainer])
}

function isElementWithin({ el, container }: { el: HTMLElement; container: HTMLElement }) {
  const rect = el.getBoundingClientRect()
  const containerRect = container.getBoundingClientRect()

  return (
    rect.top >= containerRect.top &&
    rect.left >= containerRect.left &&
    rect.bottom <= containerRect.bottom &&
    rect.right <= containerRect.right
  )
}

function resolveElement(el?: Ref<HTMLElement> | string | HTMLElement | null) {
  if (typeof el === 'string') {
    return document.getElementById(el)
  } else if (el instanceof HTMLElement) {
    return el
  } else if (typeof el === 'object') {
    return el?.current
  }
  return null
}
