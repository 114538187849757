import { useCallback, useMemo } from 'react'
import { getTime } from 'date-fns'
import { useParams } from 'react-router-dom'

import { Box, Message, RightPanel } from '@cutover/react-ui'
import { RunbookListItem } from 'main/components/shared/runbook-list-item'
import { useRightPanelTypeValue, useSetActiveRightPanelState } from 'main/components/layout/right-panel'
import { useLanguage } from 'main/services/hooks'
import { RunbookEditRunbook } from 'main/services/queries/types'
import { useRouting } from 'main/services/routing/hooks'

export const RunbookLinkedPanel = () => {
  const { runbook } = useRightPanelTypeValue('runbook-linked')

  return runbook ? <RunbookLinked runbook={runbook} /> : null
}

const RunbookLinked = ({ runbook }: { runbook: RunbookEditRunbook }) => {
  const { t } = useLanguage('runbooks', { keyPrefix: 'linkedRunbooksPanel' })

  const { openRightPanel, closeRightPanel } = useSetActiveRightPanelState()
  const { toRunbook } = useRouting()

  const linkedRunbook = useMemo(() => {
    const linked = runbook.linked_runbook_details?.linked_resource
    if (linked) {
      return {
        ...linked,
        // Note: never seen a 'linked runbook panel' before so can't test. Andy why does it use the start for the end
        start_display: linked.start_display ? getTime(new Date(linked.start_display)) : null,
        end_display: linked.start_display ? getTime(new Date(linked.start_display)) : null
      }
    }
    return null
  }, [runbook])

  const { accountId: accountSlug } = useParams<{ accountId: string }>()
  const readPermission = !!runbook.linked_runbook_details?.linked_resource_permissions?.read

  const createLinkTo = useCallback(
    (id: number) => toRunbook({ accountSlug: accountSlug as string, runbookId: id }),
    [accountSlug]
  )

  return (
    <RightPanel
      title={t('title')}
      onClose={closeRightPanel}
      onBack={() => openRightPanel({ type: 'runbook-edit', runbookId: runbook.id })}
    >
      <>
        {!readPermission && (
          <Box margin={{ bottom: 'small' }}>
            <Message type="info" message={t('alert')} />
          </Box>
        )}
        {linkedRunbook && readPermission && (
          <RunbookListItem key={linkedRunbook.id} runbook={linkedRunbook} createLinkTo={createLinkTo} />
        )}
      </>
    </RightPanel>
  )
}
