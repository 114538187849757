import { useNavigate, useParams } from 'react-router-dom'

import { FormModal } from 'main/components/shared/form'
import { useLanguage } from 'main/services/hooks'
import { deleteRunbookVersion } from 'main/services/queries/use-runbook-versions'
import { useRouting } from 'main/services/routing/hooks'
import { RunbookVersionDeleteResponse } from 'main/services/api/data-providers/runbook-types/runbook-version-types'

export const DeleteTemplateDraftVersionModal = ({
  runbookId,
  runbookVersionId,
  onClose
}: {
  runbookId: number
  runbookVersionId: number
  onClose: () => void
}) => {
  const { t } = useLanguage('runbook', { keyPrefix: 'deleteTemplateDraftVersionModal' })
  const navigate = useNavigate()
  const { accountId } = useParams()
  const { toRunbook } = useRouting()

  const handleSubmit = async () => {
    return await deleteRunbookVersion(runbookId, runbookVersionId)
  }

  const onSuccess = (data: RunbookVersionDeleteResponse) => {
    const runbookVersion = data.runbook_version
    const previousRunbookVersionId = runbookVersion.id
    navigate(toRunbook({ runbookId, versionId: previousRunbookVersionId, accountSlug: accountId as string }))
  }

  return (
    <FormModal
      formElementWrapper={false}
      confirmText={t('confirm')}
      open
      onClose={onClose}
      onSubmit={handleSubmit}
      onSuccess={onSuccess}
      title={t('title')}
      description={t('description')}
    />
  )
}
