// @ts-nocheck
import { useState, useEffect } from 'react'
import styled from 'styled-components'
import { container } from 'app/Config/IOC'
import { observer } from 'mobx-react-lite'
import { Types } from 'Gateways/Service/Types'
import { px2rem, colors } from 'app/Styles/Variables'
import { CustomFieldFormPresenter } from './CustomFieldFormPresenter'
import { CustomFieldFormFieldTypeSelect } from './CustomFieldFormFieldTypeSelect'
import { AccountVisibility } from 'Shared/Components/Organisms'
import { CheckboxCollection, Select, TextInput, ErrorAlert, Checkbox } from 'Shared/Components/Molecules'
import { RadioButtons } from 'Shared/Components/Atoms'
import { checkboxFieldType, selectFieldType, radioboxFieldType } from 'app/Settings/CustomFields/CustomFieldFieldTypes'
import { eventAddEditApplyTo, eventEditApplyTo } from 'app/Settings/CustomFields/CustomFieldApplyTo'
import {
  endpointFieldType,
  searchableFieldType,
  multiSearchableFieldType
} from 'app/Settings/CustomFields/CustomFieldFieldTypes'

const CheckboxSelectionWrapper = styled.div`
  border-bottom: dashed ${px2rem(1)} ${colors.primaryGreyHoverBackgroundColor};
`

const Spacer = styled.div`
  margin-bottom: 16px;
`

interface ICustomFieldFormSection {
  presenter: CustomFieldFormPresenter
  formType?: 'add' | 'edit'
}

const CheckboxesHeader = styled.label`
  font-size: 13px;
  line-height: 12px;
  color: ${colors.labelColor};
  font-weight: 400;
`

export const CustomFieldFormSection = observer<ICustomFieldFormSection>(({ presenter, formType }) => {
  const {
    errorAlertPresenter,
    submitted,
    formIsValid,
    namePresenter,
    displayNamePresenter,
    visibilityPresenter,
    displayTypePresenter,
    constraintMiddleware,
    integrationActionItemsMiddleware,
    dataSourceMappingsMiddleware,
    checkboxAdditionalSettings,
    restrictionHeader,
    integrationActionItemsHeader,
    integrationActionItemsChecked,
    dataSourceMappingsChecked,
    disabledIntegrationActionItems,
    restrictions,
    restrictionsChecked,
    disabledRestrictions,
    readOnly,
    indicateConflictsPresenter,
    fieldTypePresenter,
    dataSourcePresenter,
    groupPresenter,
    canDisplayGroups,
    autoGenerated,
    lockNameCheckboxPresenter,
    allowFieldCreationCheckboxPresenter,
    listCheckboxPresenter,
    filterCheckboxPresenter,
    tableCheckboxPresenter,
    requiredCheckboxPresenter,
    transientCheckboxPresenter,
    readOnlyCheckboxPresenter,
    hiddenCheckboxPresenter,
    featureFlagService
  } = presenter
  const language = container.get(Types.ILanguageService)

  const [displayConflicts, setDisplayConflicts] = useState(false)
  const [isIntegrationLevel, setIsIntegrationLevel] = useState(false)
  const [showDisplayNameField, setShowDisplayNameField] = useState(false)
  const [integrationActionItems, setIntegrationActionItems] = useState(presenter.integrationActionItems)
  const [dataSourceMappings, setDataSourceMappings] = useState<string[]>([])
  const [totalJsonMappings, setTotalJsonMappings] = useState<number>(0)
  const isSearchableFieldsAdminEnabled = featureFlagService.isEnabled('searchable_fields_admin')
  const fieldTypeOptions = presenter.fieldTypes

  const checkboxAdditionalSettingsLabels = checkboxAdditionalSettings.map(checkbox => checkbox.label)

  const onsContainIntegrationHookType = (ons, integrationHookType) => {
    return !!ons.find(on => on.contains(integrationHookType))
  }

  useEffect(() => {
    if (formType === 'edit' && dataSourcePresenter.value) {
      const { editRecordVM } = presenter.viewPresenter
      editRecordVM.dataSourceMappings?.forEach(mapping => {
        dataSourceMappingsChecked.push(mapping)
      })
    }
  })

  useEffect(() => {
    // Remove Endpoint field type from field Type Select on new custom field form or if existing value is not endpoint.
    if (
      (formType === 'add' ||
        (formType === 'edit' && (!fieldTypePresenter.value || fieldTypePresenter.value !== endpointFieldType))) &&
      fieldTypeOptions
    ) {
      const endpointIndex = fieldTypeOptions.findIndex(el => el.value === endpointFieldType)
      if (endpointIndex > -1) {
        fieldTypeOptions.splice(endpointIndex, 1)
      }
    }

    const dataSources = presenter.dataSources
    const loadedDataSources = dataSources
    const dataSourceIndex = loadedDataSources.findIndex(el => el.value === dataSourcePresenter.value)
    const searchableField = { label: 'Searchable (single-select)', value: searchableFieldType }
    const multiSearchableField = { label: 'Searchable (multi-select)', value: multiSearchableFieldType }

    if (fieldTypeOptions && isSearchableFieldsAdminEnabled) {
      // Remove searchable field type for legacy SCF.datasource setup via Connect
      if (dataSourcePresenter.value && dataSourceIndex < 0) {
        const searchableFieldTypeIndex = fieldTypeOptions.findIndex(el => el.value === searchableFieldType)
        const multiSearchableFieldTypeIndex = fieldTypeOptions.findIndex(el => el.value === multiSearchableFieldType)
        if (searchableFieldTypeIndex > -1) {
          fieldTypeOptions.splice(searchableFieldTypeIndex, 1)
        }
        if (multiSearchableFieldTypeIndex > -1) {
          fieldTypeOptions.splice(multiSearchableFieldTypeIndex, 1)
        }
      } else if (dataSourceIndex > -1) {
        // Add searchable field type for legacy SCF.datasource setup via HTTP
        const searchableFieldTypeIndex = fieldTypeOptions.findIndex(el => el.value === searchableFieldType)
        const multiSearchableFieldTypeIndex = fieldTypeOptions.findIndex(el => el.value === multiSearchableFieldType)
        if (searchableFieldTypeIndex < 0) {
          fieldTypeOptions.push(searchableField)
        }
        if (multiSearchableFieldTypeIndex < 0) {
          fieldTypeOptions.push(multiSearchableField)
        }
      } else {
        const searchableFieldTypeIndex = fieldTypeOptions.findIndex(el => el.value === searchableFieldType)
        const multiSearchableFieldTypeIndex = fieldTypeOptions.findIndex(el => el.value === multiSearchableFieldType)
        if (searchableFieldTypeIndex < 0) {
          fieldTypeOptions.push(searchableField)
        }
        if (multiSearchableFieldTypeIndex < 0) {
          fieldTypeOptions.push(multiSearchableField)
        }
      }
    } else if (fieldTypeOptions && !isSearchableFieldsAdminEnabled) {
      // Remove searchable field type if feature flag data source is not enabled
      const searchableFieldTypeIndex = fieldTypeOptions.findIndex(el => el.value === searchableFieldType)
      const multiSearchableFieldTypeIndex = fieldTypeOptions.findIndex(el => el.value === multiSearchableFieldType)
      if (searchableFieldTypeIndex > -1) {
        fieldTypeOptions.splice(searchableFieldTypeIndex, 1)
      }
      if (multiSearchableFieldTypeIndex > -1) {
        fieldTypeOptions.splice(multiSearchableFieldTypeIndex, 1)
      }
    }

    fieldTypePresenter.withOptions(fieldTypeOptions)
  })

  useEffect(() => {
    setShowDisplayNameField(displayNamePresenter.required)
  }, [displayNamePresenter.required])

  useEffect(() => {
    const { integrationActionItems, integrationHookType, addRecordVM } = presenter.viewPresenter
    const filteredIntegrationActionItems = integrationActionItems
      ? integrationActionItems.filter(iai =>
          onsContainIntegrationHookType(iai.on, integrationHookType(displayTypePresenter.value))
        )
      : []
    const integrationActionItemLabels = filteredIntegrationActionItems.flatMap(iai => (iai.name ? [iai.name] : []))

    setIntegrationActionItems(integrationActionItemLabels)

    if (displayTypePresenter.value) {
      const mappings = [presenter.dataSourceMappingsChecked, presenter.viewPresenter.addRecordVM.dataSourceMappings]
      presenter.dataSourceMappingsChecked = [...new Set(mappings.flat())]
    }
  }, [displayTypePresenter.value])

  useEffect(() => {
    dataSourcePresenter.addCustomRule(
      () =>
        fieldTypePresenter.value &&
        (!fieldTypePresenter.value.endsWith(searchableFieldType) ||
          (fieldTypePresenter.value.endsWith(searchableFieldType) && dataSourcePresenter.value))
    )

    if (fieldTypePresenter.value && fieldTypePresenter.value.endsWith(searchableFieldType)) {
      dataSourcePresenter.options = presenter.dataSources
      dataSourcePresenter.required = true
      dataSourcePresenter.isDirty = true
    } else {
      dataSourcePresenter.required = false
      presenter.dataSourceMappingsChecked = []
      presenter.viewPresenter.addRecordVM.dataSourceMappings = []
      presenter.viewPresenter.addRecordVM.dataSourceId = undefined
    }

    if (
      dataSourcePresenter.value &&
      fieldTypePresenter.value &&
      fieldTypePresenter.value.endsWith(searchableFieldType)
    ) {
      const { dataSourcesVM, addRecordVM } = presenter.viewPresenter
      const [jsonMappingSize, dataSourceMappingKeys] = presenter.getJsonMappingLabels(dataSourcePresenter.value)

      setTotalJsonMappings(jsonMappingSize)
      setDataSourceMappings(dataSourceMappingKeys)
      presenter.dataSourceMappingsChecked = [dataSourceMappingKeys[0]]
      presenter.viewPresenter.addRecordVM.dataSourceMappings = [dataSourceMappingKeys[0]]
    }
  }, [fieldTypePresenter.value, dataSourcePresenter.value])

  useEffect(() => {
    displayNamePresenter.required = lockNameCheckboxPresenter.value
    if (formType === 'edit') {
      namePresenter.disabled = lockNameCheckboxPresenter.value || readOnly
      displayNamePresenter.disabled = readOnly
    }
  }, [lockNameCheckboxPresenter.value])

  useEffect(() => {
    const conflictFieldTypes = [checkboxFieldType, selectFieldType, radioboxFieldType]
    const conflictDisplaysOn = [eventAddEditApplyTo, eventEditApplyTo]
    const currentType = fieldTypePresenter.value as string
    const currentDisplaysOn = displayTypePresenter.value as string

    if (currentDisplaysOn && (currentDisplaysOn.startsWith('task') || currentDisplaysOn.startsWith('runbook'))) {
      setIsIntegrationLevel(true)
    } else {
      setIsIntegrationLevel(false)
    }

    if (conflictFieldTypes.includes(currentType) && conflictDisplaysOn.includes(currentDisplaysOn)) {
      setDisplayConflicts(true)
    } else {
      setDisplayConflicts(false)
    }
  }, [fieldTypePresenter.value, displayTypePresenter.value])

  return (
    <form>
      {submitted && !formIsValid && <ErrorAlert presenter={errorAlertPresenter} formAlert />}
      <TextInput presenter={namePresenter} parentIsFocused={formType === 'add'} formTopInputElement />
      {showDisplayNameField && (
        <>
          <Spacer />
          <TextInput presenter={displayNamePresenter} />
        </>
      )}
      {canDisplayGroups && (
        <>
          <Spacer />
          <Select presenter={groupPresenter} maxMenuHeight={120} />
        </>
      )}

      <Spacer />
      <AccountVisibility presenter={visibilityPresenter} />
      <Spacer />
      <CustomFieldFormFieldTypeSelect presenter={presenter} formType={formType} />
      <Spacer />
      {fieldTypePresenter.value &&
        fieldTypePresenter.value.endsWith(searchableFieldType) &&
        dataSourcePresenter.value &&
        dataSourceMappings.length > 0 && (
          <>
            <CheckboxSelectionWrapper>
              <CheckboxCollection
                header={language.get('customFields:fields:dataSource:attributes:label')}
                checkboxes={dataSourceMappings}
                middleware={dataSourceMappingsMiddleware}
                checked={dataSourceMappingsChecked}
                allDisabled={readOnly}
                disabled={[dataSourceMappings[0]]}
              />
            </CheckboxSelectionWrapper>
            <Spacer />
          </>
        )}
      <Spacer />
      <Select presenter={displayTypePresenter} maxMenuHeight={160} />
      <Spacer />
      {displayConflicts && (
        <>
          <RadioButtons presenter={indicateConflictsPresenter} />
          <Spacer />
        </>
      )}
      {displayTypePresenter.value && restrictions.length > 0 && (
        <>
          <CheckboxSelectionWrapper>
            <CheckboxCollection
              header={restrictionHeader}
              checkboxes={restrictions}
              middleware={constraintMiddleware}
              checked={restrictionsChecked}
              allDisabled={readOnly}
              disabled={disabledRestrictions}
            />
          </CheckboxSelectionWrapper>
          <Spacer />
        </>
      )}
      {displayTypePresenter.value && integrationActionItems.length > 0 && isIntegrationLevel && (
        <>
          <CheckboxSelectionWrapper>
            <CheckboxCollection
              header={integrationActionItemsHeader}
              checkboxes={integrationActionItems}
              middleware={integrationActionItemsMiddleware}
              checked={integrationActionItemsChecked}
              allDisabled={readOnly}
              disabled={disabledIntegrationActionItems}
            />
          </CheckboxSelectionWrapper>
          <Spacer />
        </>
      )}
      <CheckboxSelectionWrapper>
        <CheckboxesHeader>
          {language.get('customFields:fields:additionalSettings:labels:additionalSettings')}
        </CheckboxesHeader>
        {checkboxAdditionalSettingsLabels.includes(lockNameCheckboxPresenter.label) && (
          <Checkbox presenter={lockNameCheckboxPresenter} />
        )}
        {checkboxAdditionalSettingsLabels.includes(allowFieldCreationCheckboxPresenter.label) && (
          <Checkbox presenter={allowFieldCreationCheckboxPresenter} />
        )}
        {checkboxAdditionalSettingsLabels.includes(listCheckboxPresenter.label) && (
          <Checkbox presenter={listCheckboxPresenter} />
        )}
        {checkboxAdditionalSettingsLabels.includes(filterCheckboxPresenter.label) && (
          <Checkbox presenter={filterCheckboxPresenter} />
        )}
        {checkboxAdditionalSettingsLabels.includes(tableCheckboxPresenter.label) && (
          <Checkbox presenter={tableCheckboxPresenter} />
        )}
        {checkboxAdditionalSettingsLabels.includes(requiredCheckboxPresenter.label) && (
          <Checkbox presenter={requiredCheckboxPresenter} />
        )}
        {checkboxAdditionalSettingsLabels.includes(transientCheckboxPresenter.label) && (
          <Checkbox presenter={transientCheckboxPresenter} />
        )}
        {checkboxAdditionalSettingsLabels.includes(readOnlyCheckboxPresenter.label) && (
          <Checkbox presenter={readOnlyCheckboxPresenter} />
        )}
        {checkboxAdditionalSettingsLabels.includes(hiddenCheckboxPresenter.label) && (
          <Checkbox presenter={hiddenCheckboxPresenter} />
        )}
      </CheckboxSelectionWrapper>
    </form>
  )
})
