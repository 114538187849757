import { ChangeEvent, FocusEventHandler, MouseEvent as ReactMouseEvent, useState } from 'react'
import { CheckBoxExtendedProps, CheckBoxType } from 'grommet'
import styled from 'styled-components/macro'

import { Button } from '../../button'
import { Icon, IconName } from '../../icon'
import { ItemCreateInput, ItemCreateInputProps } from '../../item-create-input'
import { Box } from '../../layout'
import { focusRingOutlineCss, themeColor } from '../../theme'
import { Checkbox, CheckboxProps } from '../checkbox'

export type CheckboxChild = Omit<CheckBoxType, 'children' | 'ref'> &
  Partial<CheckboxProps> & { initiallyOpen?: boolean; itemCreateInputProps?: ItemCreateInputProps }
export type RecursiveCheckboxChild = CheckboxChild & { children?: RecursiveCheckboxChild[] }
export type RecursiveCheckboxChildren = RecursiveCheckboxChild[]
export type CheckboxHierarchyGroupProps = {
  hasError?: boolean
  readOnly?: boolean
  required?: boolean
  disabled?: boolean
  label?: string
  tooltipText?: string
  icon?: IconName
  inlineError?: string
  name?: string
  /** The key in the option object that determines the checkbox value. Defaults to 'value'. */
  valueKey?: string
  /** The key in the option object that determines the label of the checkbox. Defaults to 'label'. */
  labelKey?: string
  withTooltipLabel?: boolean
  options: RecursiveCheckboxChildren
  onFocus?: FocusEventHandler<HTMLDivElement>
  onBlur?: FocusEventHandler<HTMLDivElement>
  itemCreateInputProps?: ItemCreateInputProps
  onEditClick?: (e: ReactMouseEvent, option: CheckBoxExtendedProps) => void
  value: (string | number)[]
  onChange: (
    e: ChangeEvent<HTMLInputElement>,
    option: RecursiveCheckboxChild,
    parents: RecursiveCheckboxChildren
  ) => void
}

export const CheckboxHierarchyGroup = ({
  disabled,
  readOnly,
  options = [],
  onEditClick,
  itemCreateInputProps,
  ...props
}: CheckboxHierarchyGroupProps) => {
  return (
    <Box flex={false}>
      {options.map(o => displayChildOptions({ option: o, readOnly, disabled, onEditClick, ...props }))}
      {itemCreateInputProps && <ItemCreateInput level={1} {...itemCreateInputProps} />}
    </Box>
  )
}

type CheckboxHierarchyOptionProps = {
  option: RecursiveCheckboxChild
  parents?: RecursiveCheckboxChildren
  isParentSelected?: boolean
  level?: number
} & Pick<
  CheckboxHierarchyGroupProps,
  'valueKey' | 'labelKey' | 'onChange' | 'readOnly' | 'disabled' | 'value' | 'withTooltipLabel' | 'onEditClick'
>

const CheckboxHierarchyOption = ({
  option,
  value = [],
  valueKey = 'value',
  labelKey = 'label',
  onChange,
  parents = [],
  readOnly,
  disabled,
  isParentSelected,
  onEditClick,
  level = 1,
  ...props
}: CheckboxHierarchyOptionProps) => {
  const [expanded, setExpanded] = useState(option.initiallyOpen || false)
  const isSelected = isParentSelected || value.includes(option[valueKey])
  const { children, ...optionWithoutChildren } = option

  return (
    <CheckboxHierarchyOptionWrapper key={option[valueKey]}>
      {/* @ts-ignore */}
      <Checkbox
        level={level}
        checked={isSelected}
        disabled={disabled || option.disabled}
        onChange={readOnly ? undefined : e => onChange?.(e, option, parents)}
        prefix={
          option.itemCreateInputProps?.onCreateItem || (option.children && option.children.length > 0) ? (
            <ShowChildToggle data-testid={`${option.label}-expand-button`} onClick={() => setExpanded(!expanded)}>
              <ShowChildBracketIcon
                color={expanded || (option.children && option.children.length > 0) ? 'text-light' : 'text-disabled'}
              />
              <ShowChildCancelIcon
                color={expanded || (option.children && option.children.length > 0) ? 'text-light' : 'text-disabled'}
                $rotated={expanded}
              />
            </ShowChildToggle>
          ) : undefined
        }
        label={option[labelKey]}
        value={option[valueKey]}
        editIconProps={
          option.editIconProps || onEditClick
            ? {
                onClick: onEditClick,
                ...(option.editIconProps || {})
              }
            : undefined
        }
        {...optionWithoutChildren}
      />

      {expanded && (
        <Box direction="column" width="100%">
          {(children || []).map(child =>
            displayChildOptions({
              option: child,
              valueKey,
              labelKey,
              onChange,
              value,
              parents: [...parents, option],
              isParentSelected: isSelected,
              readOnly,
              disabled,
              onEditClick,
              level: level + 1,
              ...props
            })
          )}
          {option.itemCreateInputProps?.onCreateItem && (
            <ItemCreateInput {...option.itemCreateInputProps} level={level + 1} />
          )}
        </Box>
      )}
    </CheckboxHierarchyOptionWrapper>
  )
}

const displayChildOptions = ({ valueKey = 'value', ...props }: CheckboxHierarchyOptionProps) => (
  <CheckboxHierarchyOption key={props.option[valueKey]} valueKey={valueKey} {...props} />
)

const ShowChildToggle = styled(Button).attrs({ plain: true })`
  &:focus-visible {
    ${focusRingOutlineCss}
    border-radius: 6px;
    outline-offset: -1px !important;
    box-shadow: none !important;
  }

  align-items: center;
  display: flex;
  height: 24px;
  justify-content: center;
  width: 24px;
  position: relative;
`
const ShowChildBracketIcon = styled(Icon).attrs({ icon: 'bracket' })``
const ShowChildCancelIcon = styled(Icon).attrs({ size: '14px', icon: 'close' })<{
  $rotated: boolean
}>`
  position: absolute;
  transform: ${({ $rotated }) => ($rotated ? 'rotate(0deg)' : 'rotate(-45deg)')};
  transition: transform 0.3s;
`

const CheckboxHierarchyOptionWrapper = styled(Box)`
  flex-shrink: 0;
  &:hover,
  &:focus-within {
    ${ShowChildBracketIcon} {
      fill: ${themeColor('text-light')};
    }

    ${ShowChildCancelIcon} {
      fill: ${themeColor('text-light')};
    }
  }
`
