import { memo } from 'react'

import { Accordion, Box } from '@cutover/react-ui'
import { RunbookTypeVisibilityGroup } from './groups/visibility-group'

export const RunbookTypesFilter = memo(() => {
  return (
    <Box overflow={{ vertical: 'auto' }} pad={{ top: 'xxsmall', left: 'small', right: 'xxsmall' }}>
      <Accordion>
        <RunbookTypeVisibilityGroup />
      </Accordion>
    </Box>
  )
})
