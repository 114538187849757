import { ForwardedRef, forwardRef, Ref, RefObject, useEffect, useRef, useState } from 'react'
import { FileInputProps } from 'grommet'
import { get } from 'lodash'
import { Controller, FieldValues, Path, useFormContext, UseFormReturn } from 'react-hook-form'
import { SetOptional, SetRequired } from 'type-fest'
import * as yup from 'yup'
import { useMergeRefs } from 'use-callback-ref'
import { usePrevious, useUpdateEffect } from 'react-use'

import {
  Checkbox,
  CheckboxGroup,
  CheckboxGroupProps,
  CheckboxProps,
  ColorPicker,
  ColorPickerProps,
  DateTimePicker,
  DateTimePickerProps,
  DurationPicker,
  DurationPickerProps,
  FileInput,
  IconSelect,
  RadioboxGroup,
  RadioboxGroupProps,
  RunbookTypeIconName,
  Select,
  SelectProps,
  SettableFieldType,
  Text,
  TextArea,
  TextAreaProps,
  TextEditor,
  TextEditorProps,
  TextInput,
  TextInputProps,
  WeekdayPicker,
  WeekdayPickerProps
} from '@cutover/react-ui'
import { TimezoneSelect, TimezoneSelectProps } from './timezone-select'
import { UserSelect, UserSelectProps } from './user-select'
import { FormType } from './form'

type UnknownFieldType = {
  name: string
  formRef?: RefObject<FormType<FieldValues>>
}

/* These fields are our react-ui form inputs wrapped for use in react-hook-form form "smart forms". These components inject
registration and rely on form-speciic states applied to the wrapping component so that our fields can be written
more concisely.

@example

  <TextInput
    {...register('name')}
    disabled={!canEdit || isSubmitting}
    readOnly={!canEdit}
    required
    hasError={!!errors.name}
    label={t('nameLabel')}
    defaultValue={data?.project.name}
    autoFocus
  />

  could be written:

  <TextInputField<FolderEditFormType> name="name" label={t('nameLabel')} autoFocus />

@see https://www.react-hook-form.com/advanced-usage/#SmartFormComponent
*/

/*
  Types
*/

// all react-ui inputs have at least these props
export type InputProps = { inlineError?: null | string; name?: string }

export type FieldProps<TInputProps extends InputProps, TFieldValues extends FieldValues> = SetRequired<
  Omit<TInputProps, 'inlineError' | 'name'> & {
    /** Defaults to `true` (schema generated error message) except for required violations. Explicitly pass
     * `true` to enable inline error for required, or pass a custom message for any error */
    inlineError?: boolean | string
    name?: Path<TFieldValues>
  },
  'name'
>

export type FieldContextType<TFieldValues extends FieldValues> = {
  disabled?: boolean
  readOnly?: boolean
  schema: yup.ObjectSchema<TFieldValues>
}

/*
  TextInput smart form component
*/

export type TextInputFieldProps<TFieldValues extends FieldValues> = FieldProps<TextInputProps, TFieldValues>

export const TextInputField = <TFieldValues extends FieldValues>(props: TextInputFieldProps<TFieldValues>) => {
  const formContext = useFormContext<TFieldValues>()
  const inputProps = getInputProps<TFieldValues>({ ...props, formContext })

  return (
    <TextInput
      {...props}
      {...inputProps}
      {...formContext.register(props.name)}
      defaultValue={get(formContext.formState.defaultValues, props.name)}
    />
  )
}

/*
  Select smart form component
*/

export type SelectFieldProps<
  TFieldValues extends FieldValues,
  Option extends Record<any, any> = { value: any; label: string },
  Value = any
> = FieldProps<SelectProps<Option, Value>, TFieldValues>

export const SelectField = <
  TFieldValues extends FieldValues,
  Option extends Record<any, any> = { value: any; label: string } | { header: string },
  Value = any
>(
  props: SelectFieldProps<TFieldValues, Option, Value>
) => {
  const formContext = useFormContext<TFieldValues>()
  const inputProps = getInputProps<TFieldValues>({ ...props, formContext })

  return (
    <Controller
      name={props.name}
      control={formContext.control}
      render={({ field: { value, onChange, ref } }) => {
        return <Select<Option, Value> {...props} {...inputProps} value={value} inputRef={ref} onChange={onChange} />
      }}
    />
  )
}

/*
  UserSelect smart form component
*/

export type UserSelectFieldProps<TFieldValues extends FieldValues> = FieldProps<UserSelectProps, TFieldValues>

export const UserSelectField = <TFieldValues extends FieldValues>(props: UserSelectFieldProps<TFieldValues>) => {
  const formContext = useFormContext<TFieldValues>()
  const inputProps = getInputProps<TFieldValues>({ ...props, formContext })

  return (
    <Controller
      name={props.name}
      control={formContext.control}
      render={({ field: { value, onChange, ref, onBlur } }) => {
        return (
          <UserSelect
            {...props}
            {...inputProps}
            inlineError={inputProps.inlineError ?? undefined}
            value={value || []}
            inputRef={ref}
            onBlur={onBlur}
            onChange={onChange}
            disabled={inputProps.disabled}
          />
        )
      }}
    />
  )
}

/* TimezoneSelect smart form component */

export type TimezoneSelectFieldProps<TFieldValues extends FieldValues> = FieldProps<TimezoneSelectProps, TFieldValues>

export const TimezoneSelectField = <TFieldValues extends FieldValues>(
  props: TimezoneSelectFieldProps<TFieldValues>
) => {
  const formContext = useFormContext<TFieldValues>()
  const inputProps = getInputProps<TFieldValues>({ ...props, formContext })

  return (
    <Controller
      name={props.name}
      control={formContext.control}
      render={({ field: { onChange, value, ref, onBlur } }) => (
        <TimezoneSelect
          {...props}
          {...inputProps}
          inputRef={ref}
          onBlur={onBlur}
          onChange={onChange}
          value={value}
          hasError={!!formContext.formState.errors.timezone}
          inlineError={formContext.formState.errors.timezone?.message?.toString()}
        />
      )}
    />
  )
}

/*
  RadioboxGroup smart form component
*/

export type RadioboxGroupFieldProps<TFieldValues extends FieldValues> = FieldProps<RadioboxGroupProps, TFieldValues>

export const RadioboxGroupField = <TFieldValues extends FieldValues>(props: RadioboxGroupFieldProps<TFieldValues>) => {
  const formContext = useFormContext<TFieldValues>()
  const inputProps = getInputProps<TFieldValues>({ ...props, formContext })

  return (
    <Controller
      name={props.name}
      control={formContext.control}
      render={({ field: { value, onChange, ref, onBlur } }) => {
        return <RadioboxGroup {...props} {...inputProps} value={value} ref={ref} onBlur={onBlur} onChange={onChange} />
      }}
    />
  )
}

/*
  Checkbox smart form component (uncontrolled)
*/

export type CheckboxFieldProps<TFieldValues extends FieldValues> = FieldProps<CheckboxProps, TFieldValues>

export const CheckboxField = <TFieldValues extends FieldValues>(props: CheckboxFieldProps<TFieldValues>) => {
  const formContext = useFormContext<TFieldValues>()
  const inputProps = getInputProps<TFieldValues>({ ...props, formContext })

  return <Checkbox {...props} {...inputProps} {...formContext.register(props.name)} />
}

/*
  Checkbox smart form component (controlled)
*/

export type CheckboxFieldControlledProps<TFieldValues extends FieldValues> = FieldProps<CheckboxProps, TFieldValues>

export const CheckboxFieldControlled = <TFieldValues extends FieldValues>(
  props: CheckboxFieldControlledProps<TFieldValues>
) => {
  const formContext = useFormContext<TFieldValues>()
  const inputProps = getInputProps<TFieldValues>({ ...props, formContext })

  return (
    <Controller
      name={props.name}
      control={formContext.control}
      render={({ field: { value, onChange, ref } }) => (
        <Checkbox {...props} {...inputProps} ref={ref} checked={value} onChange={onChange} />
      )}
    />
  )
}

/*
  CheckboxGroup smart form component
*/

export type CheckboxGroupFieldProps<TFieldValues extends FieldValues> = FieldProps<CheckboxGroupProps, TFieldValues> & {
  /** set to `true` if `value` should *not* be passed to `CheckboxGroup`. */
  uncontrolled?: boolean
}

export const CheckboxGroupField = <TFieldValues extends FieldValues>({
  uncontrolled,
  ...props
}: CheckboxGroupFieldProps<TFieldValues>) => {
  const formContext = useFormContext<TFieldValues>()
  const inputProps = getInputProps<TFieldValues>({ ...props, formContext })

  return (
    <Controller
      name={props.name}
      control={formContext.control}
      render={({ field: { value, onChange }, formState: { defaultValues } }) => {
        const defaultVal = get(defaultValues, props.name)
        const coercedVal = typeof value === 'boolean' ? undefined : value

        const fieldProps = uncontrolled
          ? { onChange, defaultValue: defaultVal }
          : { value: coercedVal, onChange, defaultValue: defaultVal }

        // @ts-ignore uncontrolled checkbox group fields must not have `value` as own property,
        // so passing it with undefined is not sufficient.
        return <CheckboxGroup {...fieldProps} {...props} {...inputProps} />
      }}
    />
  )
}

/*
   WeekdayPicker smart form component
*/

export type WeekdayPickerFieldProps<TFieldValues extends FieldValues> = FieldProps<WeekdayPickerProps, TFieldValues>

export const WeekdayPickerField = <TFieldValues extends FieldValues>(props: WeekdayPickerFieldProps<TFieldValues>) => {
  const formContext = useFormContext<TFieldValues>()
  const inputProps = getInputProps<TFieldValues>({ ...props, formContext })

  return (
    <Controller
      name={props.name}
      control={formContext.control}
      render={({ field: { value, onChange } }) => {
        return (
          <WeekdayPicker
            {...props}
            {...inputProps}
            value={value ?? []}
            onChange={(event: any) => event && onChange(event.value)}
          />
        )
      }}
    />
  )
}

/*
  DateTimePicker smart form component
*/

export type DateTimePickerFieldProps<TFieldValues extends FieldValues> = SetOptional<
  FieldProps<DateTimePickerProps, TFieldValues>,
  'onChange' | 'value'
>

export const DateTimePickerField = <TFieldValues extends FieldValues>(
  props: DateTimePickerFieldProps<TFieldValues>
) => {
  const formContext = useFormContext<TFieldValues>()
  const inputProps = getInputProps<TFieldValues>({ ...props, formContext })

  return (
    <Controller
      name={props.name}
      control={formContext.control}
      render={({ field: { onChange, value, ref, onBlur } }) => {
        return (
          <DateTimePicker
            {...props}
            {...inputProps}
            inputRef={ref}
            onBlur={onBlur}
            value={!!value ? new Date(value) : null}
            onChange={date => (!!date ? onChange(date.toISOString()) : onChange(null))}
          />
        )
      }}
    />
  )
}

/*
  DurationPicker smart form component
*/

export type DurationPickerFieldProps<TFieldValues extends FieldValues> = SetOptional<
  FieldProps<DurationPickerProps, TFieldValues>,
  'onChange' | 'value'
>

export const DurationPickerField = <TFieldValues extends FieldValues>(
  props: DurationPickerFieldProps<TFieldValues>
) => {
  const formContext = useFormContext<TFieldValues>()
  const inputProps = getInputProps<TFieldValues>({ ...props, formContext })

  return (
    <Controller
      name={props.name}
      control={formContext.control}
      render={({ field: { onChange, value } }) => {
        return (
          <DurationPicker
            {...props}
            {...inputProps}
            readOnly={props.readOnly}
            value={!!value ? value : 0}
            onChange={onChange}
          />
        )
      }}
    />
  )
}

/*
  TextEditor smart form component
*/

export type TextEditorFieldProps<TFieldValues extends FieldValues> = SetOptional<
  FieldProps<TextEditorProps, TFieldValues>,
  'onChange' | 'value'
> &
  UnknownFieldType

export const TextEditorField = forwardRef<SettableFieldType, { name: string }>(
  ({ formRef, ...props }: UnknownFieldType, ref: ForwardedRef<SettableFieldType>) => {
    const formContext = useFormContext()
    const inputProps = getInputProps({ ...props, formContext })
    const editorFieldRef = useRef<SettableFieldType>(null)
    const fieldRef = useMergeRefs<SettableFieldType>(ref ? [ref, editorFieldRef] : [editorFieldRef])
    const dirtyFields = formContext.formState.dirtyFields
    const defaultValues = formContext.formState.defaultValues
    const isDirty = get(dirtyFields, props.name) !== undefined
    const wasDirty = usePrevious(isDirty)
    const wasReset = wasDirty && !isDirty
    const [triggerRender, setTriggerRender] = useState(1)
    const initialValue = get(defaultValues, props.name)
    const [isSameVal, setIsSameVal] = useState(true)

    useEffect(() => {
      if (formRef && editorFieldRef.current) {
        formRef?.current?.addSettableField(props.name, editorFieldRef.current)
      }
    }, [formRef])

    useUpdateEffect(() => {
      if (wasReset && !isSameVal) {
        setTriggerRender(prev => prev + 1)
      }
    }, [wasReset])

    return (
      <Controller
        name={props.name}
        key={triggerRender}
        control={formContext.control}
        render={({ field: { onChange, value } }) => {
          return (
            <TextEditor
              ref={fieldRef}
              {...props}
              {...inputProps}
              value={value}
              onChange={val => {
                if (val === initialValue) {
                  setIsSameVal(true)
                } else {
                  setIsSameVal(false)
                }

                onChange(val)
              }}
            />
          )
        }}
      />
    )
  }
) as unknown as <TFieldValues extends FieldValues>(
  p: TextEditorFieldProps<TFieldValues> & { ref?: Ref<SettableFieldType> }
) => JSX.Element

/*
  TextArea smart form component
*/

export type TextAreaFieldProps<TFieldValues extends FieldValues> = FieldProps<TextAreaProps, TFieldValues>

export const TextAreaField = <TFieldValues extends FieldValues>(props: TextAreaFieldProps<TFieldValues>) => {
  const formContext = useFormContext<TFieldValues>()
  const inputProps = getInputProps<TFieldValues>({ ...props, formContext })

  return (
    <TextArea
      {...props}
      {...inputProps}
      {...formContext.register(props.name)}
      defaultValue={get(formContext.formState.defaultValues, props.name)}
    />
  )
}

/*
 * ColorPicker smart form component
 */
export type ColorPickerFieldProps<TFieldValues extends FieldValues> = FieldProps<ColorPickerProps, TFieldValues>

export const ColorPickerField = <TFieldValues extends FieldValues>(props: ColorPickerFieldProps<TFieldValues>) => {
  const formContext = useFormContext<TFieldValues>()
  const inputProps = getInputProps<TFieldValues>({ ...props, formContext })

  // NOTE: This field does currently not work when initially empty, likely need dependencies on the useMemos.
  // However it isn't used anywhere currently where it could be empty, so can postpone fixing.
  // Hence the below condition added to render null when no initial value

  return (
    <Controller
      name={props.name}
      control={formContext.control}
      // @ts-ignore
      render={({ field: { onChange, value } }) => {
        // @ts-ignore
        return !!value ? <ColorPicker {...props} {...inputProps} value={value} onChange={onChange} /> : null
      }}
    />
  )
}

/*
 * IconSelect smart form component
 */
export const IconSelectField = <TFieldValues extends FieldValues>(
  props: TextInputFieldProps<TFieldValues> & { selectedColor: string; options: RunbookTypeIconName[] }
) => {
  // Todo: if/when needed, make this comp generic so can choose any array of icon options. For now just RBTs
  const formContext = useFormContext<TFieldValues>()
  const inputProps = getInputProps<TFieldValues>({ ...props, formContext })

  return (
    <Controller
      name={props.name}
      control={formContext.control}
      render={({ field: { onChange, value, onBlur } }) => {
        return (
          <IconSelect
            {...props}
            {...inputProps}
            iconNames={props.options ?? []}
            value={value}
            onSelect={value => onChange(value)}
            onBlur={onBlur}
            selectedColor={props.selectedColor}
          />
        )
      }}
    />
  )
}

/*
 * FileInput smart form component
 */
export type FileInputFieldProps<TFieldValues extends FieldValues> = FieldProps<FileInputProps, TFieldValues> & {
  accept: string
}

export const FileInputField = <TFieldValues extends FieldValues>(props: FileInputFieldProps<TFieldValues>) => {
  const formContext = useFormContext<TFieldValues>()
  const inputProps = getInputProps<TFieldValues>({ ...props, formContext })

  return (
    <Controller
      name={props.name}
      control={formContext.control}
      render={({ field: { onChange } }) => {
        return (
          <FileInput
            {...props}
            {...inputProps}
            aria-label="Browse for a file"
            onChange={event => {
              const fileList = event?.target.files ?? []
              // Current functionality allows only one file at a time
              onChange(fileList[0])
            }}
            renderFile={file => <Text margin={{ left: 'small' }}>{file.name}</Text>}
          />
        )
      }}
    />
  )
}

/*
  Internal helpers
 */

type FieldPropsType<TFieldValues extends FieldValues> = {
  disabled?: boolean
  readOnly?: boolean
  required?: boolean
  inlineError?: boolean | string
  name: Path<TFieldValues>
  formContext: UseFormReturn<TFieldValues, FieldContextType<TFieldValues>>
}

export const getInputProps = <TFieldValues extends FieldValues>(props: FieldPropsType<TFieldValues>) => {
  const {
    getValues,
    formState: { isSubmitting, errors },
    control: {
      _options: { context }
    }
  } = props.formContext

  const isDisabled = props.disabled || isSubmitting || !!context?.disabled
  const isReadOnly = props.readOnly ?? context?.readOnly
  const isRequired = props.required ?? !!(context && isFieldRequired(context.schema, props.name, getValues))
  const error = get(errors, props.name)
  // @ts-ignore  TODO: type correctly
  const errorMessage = inlineErrorMessage(error, props.inlineError)

  return {
    disabled: isDisabled,
    readOnly: isReadOnly,
    required: isRequired,
    hasError: !!error,
    inlineError: errorMessage
  }
}

const isFieldRequired = (schema: yup.AnyObjectSchema, name: string, getValues: any, nestedFields?: any): boolean => {
  let fields = nestedFields

  if (!fields) {
    const vals = getValues()
    const described = schema?.describe(vals)
    fields = described?.fields ?? {}
  }
  const [first, ...rest] = name.split('.')
  const firstField = get(fields, first)

  if (firstField?.type === 'object') {
    const nestedSchema = firstField?.fields
    return isFieldRequired(schema, rest.join('.'), getValues, nestedSchema)
  }

  if (firstField?.type === 'array') {
    const nestedSchema = firstField?.innerType?.fields
    const [_index, ...restNested] = rest
    return isFieldRequired(schema, restNested.join('.'), getValues, nestedSchema)
  }

  const field = get(fields, name) as yup.SchemaFieldDescription & {
    optional?: boolean
  }

  const isOptional = field && field?.optional

  return !isOptional
}

const inlineErrorMessage = (error?: { type?: any; message?: string }, inlineError?: boolean | string) => {
  const isRequiredError = error?.type === 'required' || error?.type === 'optionality'
  // inlineError prop should default to true except if the error is for a required violation.
  const inlineErrorProp = inlineError === undefined && isRequiredError ? false : inlineError ?? true

  // return undefined if there is no error or explicitly excluded by the inlineErrorProp being false
  if (!error || inlineErrorProp === false) return undefined

  // return a custom error message or the schema generated message
  return inlineErrorProp === true ? error?.message?.toString() : inlineErrorProp
}
