import * as yup from 'yup'
import { useFormContext } from 'react-hook-form'

import { Box, useNotify } from '@cutover/react-ui'
import { useLanguage } from 'main/services/hooks'
import { ActiveRunbookModel, ActiveRunModel } from 'main/data-access'
import { CheckboxFieldControlled, FormModal, RadioboxGroupField } from 'main/components/shared/form'
import { RunbookRunUpdateResponse } from 'main/services/api/data-providers/runbook-types'
import { RunComms } from 'main/services/queries/types'
import { updateRun, UpdateRunPayload } from 'main/services/queries/use-run'

type UpdateRunModalProps = {
  onClose: () => void
}

const validationSchema = yup.object({
  comms: yup.string().oneOf(['off', 'test', 'on']).required(),
  disable_task_notify: yup.boolean().required()
})

type UpdateRunForm = yup.InferType<typeof validationSchema>

export const UpdateRunModal = ({ onClose }: UpdateRunModalProps) => {
  const { t } = useLanguage('runbook', { keyPrefix: 'updateRunModal' })
  const notify = useNotify()
  const runbookId = ActiveRunbookModel.useId()
  const run = ActiveRunModel.useGet()
  const processRunUpdateResponse = ActiveRunModel.useOnAction('update')
  if (!run) return null
  const { id: runId, comms, disable_task_notify: disableTaskNotify } = run

  const handleSubmit = async (payload: UpdateRunPayload) => {
    if (payload.comms === 'off') {
      payload.disable_task_notify = false
    }
    return await updateRun({ runbookId, runId, payload })
  }

  const handleSuccess = (response: RunbookRunUpdateResponse) => {
    processRunUpdateResponse(response)
    notify.success(t('successText'))
    onClose?.()
  }

  return (
    <FormModal<UpdateRunForm>
      preventAutoClose // The usual Hack to fix formmodal bug where it unexpectedly closes after api error
      title={t('title')}
      open
      onSubmit={handleSubmit}
      onSuccess={handleSuccess}
      onClose={onClose}
      confirmText={t('confirmText')}
      confirmIcon="save"
      schema={validationSchema}
      defaultValues={{
        comms: comms,
        disable_task_notify: disableTaskNotify
      }}
    >
      <UpdateRunModalInner />
    </FormModal>
  )
}

const UpdateRunModalInner = () => {
  const { t } = useLanguage('runbook', { keyPrefix: 'updateRunModal' })
  const { watch } = useFormContext()
  const comms = watch('comms')

  const commsOptions: { label: string; value: RunComms }[] = [
    { label: t('commsOff'), value: 'off' },
    { label: t('commsTest'), value: 'test' },
    { label: t('commsOn'), value: 'on' }
  ]

  return (
    <Box direction="column">
      <RadioboxGroupField<UpdateRunForm>
        name="comms"
        label={t('commsSettings')}
        direction="row"
        options={commsOptions}
      />
      {comms !== 'off' && (
        <CheckboxFieldControlled<UpdateRunForm> name="disable_task_notify" label={t('disableTaskNotify')} />
      )}
    </Box>
  )
}
